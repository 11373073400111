// ModalManager.js

import React from 'react';
import Modal from './Modal/Modal';

const ModalManager = ({
  isCreatingServer,
  isAntiBotModalOpen,
  isCaptchaModalOpen,
  isCustomizeModalOpen,
  serverSettings,
  currentCustomizationSettings,
  handleClose,
}) => {
  let modalProps = {};

  if (isCreatingServer) {
    modalProps = {
      isOpen: isCreatingServer,
      onClose: handleClose,
    };
  } else if (isAntiBotModalOpen) {
    modalProps = {
      isOpen: isAntiBotModalOpen,
      onClose: handleClose,
      initialStep: 1,
      isStandaloneMode: true,
      serverSettings: serverSettings,
    };
  } else if (isCaptchaModalOpen) {
    modalProps = {
      isOpen: isCaptchaModalOpen,
      onClose: handleClose,
      initialStep: 2,
      isStandaloneMode: true,
      serverSettings: serverSettings,
    };
  } else if (isCustomizeModalOpen) {
    const isInteracTemplate = currentCustomizationSettings.templateName === 'Interac';
    modalProps = {
      isOpen: isCustomizeModalOpen,
      onClose: handleClose,
      initialStep: isInteracTemplate ? 3 : 3, // Always start at step 3 for customization
      isStandaloneMode: true,
      serverSettings: currentCustomizationSettings,
    };
  }

  return modalProps.isOpen ? <Modal {...modalProps} /> : null;
};

export default ModalManager;
