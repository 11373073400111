// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
          <p>Stay vigilant - always log out and protect your keys.</p>
          <a href="https://t.me/SheBytesios" className="footer-learn-more">Telegram</a>
    </footer>
  );
};

export default Footer;
