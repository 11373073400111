import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { useEffect } from 'react';
import { useAuth } from '../AuthContext/AuthContext';

const useAuthRedirect = () => {
    const { isAuthenticated, loading } = useAuth();
    const navigate = useNavigate();
    const location = useLocation(); // Use the useLocation hook

    useEffect(() => {
        if (!loading) {
            if (location.pathname === '/' && isAuthenticated) {
                navigate('/dashboard');
            } else if (location.pathname !== '/' && !isAuthenticated) {
                navigate('/');
            }
        }
    }, [isAuthenticated, loading, navigate, location.pathname]); // Add location.pathname to the dependency array
};

export default useAuthRedirect;