// frontend/src/components/PageBuilder/modals/SaveTemplateModal.js

import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import styles from './SaveTemplateModal.module.css';

const SaveTemplateModal = ({
  isOpen,
  onClose,
  defaultTemplateName,
  onSaveTemplate,
}) => {
  const [templateName, setTemplateName] = useState(defaultTemplateName || '');

  useEffect(() => {
    if (isOpen) {
      setTemplateName(defaultTemplateName || '');
    }
  }, [isOpen, defaultTemplateName]);

  const handleSave = () => {
    onSaveTemplate(templateName);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className={styles.modalContent}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: '#1E242A',
          color: '#ffffff',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2 style={{ color: '#ffffff' }}>Save Template</h2>
        <TextField
          label="Template Name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{ style: { color: '#ffffff' } }}
        />
        <div className={styles.modalActions}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button onClick={onClose} color="error">
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default SaveTemplateModal;
