// src/components/Header.js
import React from 'react';
import logo from "./shebyte.png" // Adjust the path as necessary

const Header = () => {
    return (
        <div className="header">
            <img src={logo} alt="SheByte.io logo" className="logo" />
        </div>
    );
};

export default Header;
