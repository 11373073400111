// frontend/src/components/PageBuilder/PageList.js

import React, { useState } from 'react';
import {
  IconButton,
  Button,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './PageList.module.css';

const PageList = ({
  pages,
  selectPage,
  selectedPageId,
  updatePages,
  openCreatePageModal,
}) => {
  // State for Rename Dialog
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [pageToRename, setPageToRename] = useState(null);
  const [newPageName, setNewPageName] = useState('');

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedPages = Array.from(pages);
    const [removed] = reorderedPages.splice(result.source.index, 1);
    reorderedPages.splice(result.destination.index, 0, removed);

    updatePages(reorderedPages);
    toast.info('Page order updated.');
  };

  const openRenameDialog = (page) => {
    setPageToRename(page);
    setNewPageName(page.name);
    setRenameDialogOpen(true);
  };

  const closeRenameDialog = () => {
    setRenameDialogOpen(false);
    setPageToRename(null);
    setNewPageName('');
  };

  const handleRenamePage = () => {
    if (newPageName && newPageName.trim() !== '') {
      updatePages(
        pages.map((p) =>
          p.id === pageToRename.id ? { ...p, name: newPageName.trim() } : p
        )
      );
      toast.success(`Page renamed to "${newPageName.trim()}" successfully.`);
    } else {
      toast.error('Page name cannot be empty.');
    }
    closeRenameDialog();
  };

  const toggleHiddenStatus = (id) => {
    updatePages((prevPages) =>
      prevPages.map((page) =>
        page.id === id ? { ...page, isHidden: !page.isHidden } : page
      )
    );
    const page = pages.find((p) => p.id === id);
    toast.success(
      `Page "${page.name}" is now ${page.isHidden ? 'hidden' : 'visible'}.`
    );
  };

  const deletePage = (id) => {
    const pageToDelete = pages.find((page) => page.id === id);
    updatePages(pages.filter((page) => page.id !== id));
    toast.success(`Page "${pageToDelete?.name || 'deleted'}" deleted successfully.`);
  };

  return (
    <div className={styles.pagesList}>
      <div className={styles.pagesHeader}>
        <Typography variant="h6">Pages</Typography>
        <Tooltip title="Add New Page">
          <IconButton
            onClick={openCreatePageModal}
            className={styles.addButton}
            sx={{ color: '#ffffff' }} // Ensures the Add icon is white
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
      <div className={styles.pagesContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="pages">
            {(provided) => (
              <List
                className={styles.pageList}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {pages.map((page, index) => (
                  <Draggable key={page.id} draggableId={page.id} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        button
                        selected={selectedPageId === page.id}
                        onClick={() => selectPage(page.id)}
                        className={styles.pageItem}
                      >
                        <div
                          className={styles.dragHandle}
                          {...provided.dragHandleProps}
                        >
                          <DragIndicatorIcon sx={{ color: '#ffffff' }} />
                        </div>
                        <ListItemText
                          primary={page.name}
                          secondary={page.isHidden ? 'Hidden' : null}
                          classes={{
                            primary: styles.listItemTextPrimary,
                            secondary: styles.listItemTextSecondary,
                          }}
                        />
                        <ListItemSecondaryAction>
                          <Tooltip title="Rename">
                            <IconButton
                              edge="end"
                              onClick={() => openRenameDialog(page)}
                              sx={{ color: '#ffffff' }} // Ensures the Edit icon is white
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={page.isHidden ? 'Unhide Page' : 'Hide Page'}
                          >
                            <IconButton
                              edge="end"
                              onClick={() => toggleHiddenStatus(page.id)}
                              sx={{ color: '#ffffff' }} // Ensures the Visibility icons are white
                            >
                              {page.isHidden ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              edge="end"
                              onClick={() => deletePage(page.id)}
                              sx={{ color: '#ffffff' }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* Rename Dialog */}
      <Dialog
        open={renameDialogOpen}
        onClose={closeRenameDialog}
        PaperProps={{ className: styles.renameDialogPaper }}
      >
        <DialogTitle className={styles.dialogTitle}>Rename Page</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Page Name"
            type="text"
            fullWidth
            variant="standard" // Consider changing to 'filled' or 'outlined' if needed
            value={newPageName}
            onChange={(e) => setNewPageName(e.target.value)}
            InputLabelProps={{ className: styles.textFieldLabel }}
            InputProps={{ className: styles.textFieldInput }}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={closeRenameDialog} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleRenamePage} className={styles.renameButton}>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PageList;
