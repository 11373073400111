import React, { useState } from 'react';
import { FaCog, FaShieldAlt, FaKey, FaTrash, FaLock } from 'react-icons/fa';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import axios from 'axios';
import styles from './ServerCard.module.css';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#131a22', // Dark background color
    color: '#eceff1cf', // Light text color
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)', // Add some shadow for depth
  },
  '& .MuiTextField-root': {
    backgroundColor: '#24303F', // Darker background for input fields
    borderRadius: '4px',
    color: '#eceff1cf',
  },
  '& .MuiInputLabel-root': {
    color: '#AAB0C6', // Light gray label color
  },
  '& .MuiInputBase-root': {
    color: '#eceff1cf', // Light text color inside inputs
  },
  '& .MuiDialogTitle-root': {
    color: '#eceff1cf', // Light color for the dialog title
  },
  '& .MuiDialogContentText-root': {
    color: '#eceff1cf', // Light color for dialog content text
  },
  '& .MuiFormHelperText-root': {
    color: '#8b92a6', // Bright yellow color for better visibility
    fontSize: '0.9rem', // Slightly larger font size
  },
  '& .cancelButton': {
    backgroundColor: '#2e4053', // Custom color for Cancel button
    color: '#fff', // White text color
    borderRadius: '4px',
    textTransform: 'none',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#263845', // Darker shade on hover
    },
    '&:focus': {
      backgroundColor: '#2e4053', // Keep the original color on focus
    },
    '&:active': {
      backgroundColor: '#2e4053', // Keep the original color on active (press)
    },
  },
  '& .installButton': {
    backgroundColor: '#239adb', // Blue background color for Install SSL button
    color: '#1F2A37', // Dark text color
    borderRadius: '4px',
    textTransform: 'none',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#1F8BBE', // Darker blue on hover
    },
  },
  '& .MuiCircularProgress-root': {
    color: '#2AABEE',
  },
  '& .MuiIconButton-root': {
    color: '#2AABEE', // Light blue color for the Add Domain button
    marginTop: '8px',
  },
  '& .MuiDialogActions-root': {
    justifyContent: 'space-between', // Align Cancel to the left and Install SSL to the right
  },
}));

const ServerCard = ({ server, onOpenAntiBotSettings, onOpenCaptchaSettings, onOpenCustomizeSettings, onServerDeleted }) => {
  const { _id, domainName, ipAddress, status, uniqueVisits } = server;
  const [open, setOpen] = useState(false); // State to manage the delete modal visibility
  const [sslOpen, setSslOpen] = useState(false); // State to manage the SSL modal visibility
  const [domains, setDomains] = useState(['']);
  const [vpsCredentials, setVpsCredentials] = useState({ ip: '', username: '', password: '' });
  const [isInstalling, setIsInstalling] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSslOpen = () => setSslOpen(true);
  const handleSslClose = () => setSslOpen(false);

  const handleAddDomain = () => setDomains([...domains, '']);
  const handleDomainChange = (index, value) => {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
  };

  const handleVpsCredentialChange = (field, value) => {
    setVpsCredentials({ ...vpsCredentials, [field]: value });
  };

  const isValidDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return domainRegex.test(domain) && !domain.includes('/') && !domain.startsWith('http') && !domain.startsWith('www');
  };

  const isValidIp = (ip) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  };

  const handleInstallSsl = async () => {
    for (const domain of domains) {
        if (!isValidDomain(domain)) {
            toast.error(`Invalid domain name format: ${domain}`);
            return;
        }
    }

    if (!isValidIp(vpsCredentials.ip)) {
        toast.error('Invalid VPS IP address format.');
        return;
    }

    setIsInstalling(true);
    try {
        // Retrieve the auth token from localStorage
        const authToken = localStorage.getItem('token');
        if (!authToken) {
            toast.error('Authorization token not found');
            return;
        }

        // Prepare the payload
        const payload = {
            ipAddress: ipAddress, // You already have the IP address from the server data
            sshUser: vpsCredentials.username,
            sshPassword: vpsCredentials.password,
            domains: domains
        };

        // Make the POST request to install SSL with the Authorization header
        const response = await axios.post('/api/install-ssl', payload, {
            headers: { Authorization: `Bearer ${authToken}` },
        });

        if (response.status === 200) {
            toast.success('SSL installed successfully');
        } else {
            toast.error(`Error: ${response.data.message}`);
        }
    } catch (error) {
        console.error('Failed to install SSL:', error);
        toast.error('Failed to install SSL');
    } finally {
        setIsInstalling(false);
        handleSslClose();
    }
  };

  const handleDeleteConfirm = async () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error('No auth token found');
      return;
    }
    setIsDeleting(true);
    try {
      const response = await axios.post(`/api/servers/${ipAddress}/delete`, {}, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      if (response.status === 200 || response.status === 500) {
        toast.success('Server deleted successfully');
        onServerDeleted(_id);
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.success('Server deleted successfully');
        onServerDeleted(_id);
      } else {
        console.error('Failed to delete server:', error);
        toast.error('Failed to delete server');
      }
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <h2 className={styles.domainName}>{domainName}</h2>
        <span className={`${styles.status} ${styles[status.toLowerCase()]}`}>{status}</span>
      </div>
      <div className={styles.content}>
        <div className={styles.details}>
          <p className={styles.detail}>
            <span className={styles.label}>IP:</span> {ipAddress}
          </p>
          <p className={styles.detail}>
            <span className={styles.label}>Visitors:</span> {uniqueVisits}
          </p>
        </div>
        <div className={styles.actions}>
          <button onClick={handleSslOpen} className={`${styles.actionButton} ${styles.ssl}`}>
            <FaLock className={styles.icon} /> Install SSL
          </button>
          <button onClick={() => onOpenCustomizeSettings(_id)} className={`${styles.actionButton} ${styles.customize}`}>
            <FaCog className={styles.icon} /> Customize
          </button>
          <button onClick={() => onOpenAntiBotSettings(_id)} className={`${styles.actionButton} ${styles.antiBot}`}>
            <FaShieldAlt className={styles.icon} /> Anti-Bot
          </button>
          <button onClick={() => onOpenCaptchaSettings(_id)} className={`${styles.actionButton} ${styles.captcha}`}>
            <FaKey className={styles.icon} /> Captcha
          </button>
          <button onClick={handleOpen} className={`${styles.actionButton} ${styles.delete}`}>
            <FaTrash className={styles.icon} /> Delete
          </button>
        </div>
      </div>

      <CustomDialog open={sslOpen} onClose={handleSslClose}>
        <DialogTitle>Install SSL</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter the domain names and VPS credentials.</DialogContentText>
          {domains.map((domain, index) => (
            <TextField
              key={index}
              margin="dense"
              label={`Domain ${index + 1}`}
              fullWidth
              value={domain}
              onChange={(e) => handleDomainChange(index, e.target.value)}
              variant="outlined"
              helperText="Format: example.com (no https://, no www, no trailing slash)"
            />
          ))}
          <IconButton onClick={handleAddDomain}>
            Add Domain
          </IconButton>
          <TextField
            margin="dense"
            label="VPS IP"
            fullWidth
            value={vpsCredentials.ip}
            onChange={(e) => handleVpsCredentialChange('ip', e.target.value)}
            variant="outlined"
            helperText="Format: 192.168.1.1"
          />
          <TextField
            margin="dense"
            label="Username"
            fullWidth
            value={vpsCredentials.username}
            onChange={(e) => handleVpsCredentialChange('username', e.target.value)}
            variant="outlined"
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={vpsCredentials.password}
            onChange={(e) => handleVpsCredentialChange('password', e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSslClose} className="cancelButton">Cancel</Button>
          <Button onClick={handleInstallSsl} className="installButton" disabled={isInstalling}>
            {isInstalling ? <CircularProgress size={24} /> : 'Install SSL'}
          </Button>
        </DialogActions>
      </CustomDialog>

      <CustomDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-confirmation-title"
      >
        <DialogTitle id="delete-confirmation-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-confirmation-description">
            Are you sure you want to delete this server?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isDeleting} className="cancelButton">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" disabled={isDeleting}>
            {isDeleting ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </CustomDialog>
    </div>
  );
};

export default ServerCard;
