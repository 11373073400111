// src/components/ServersV2/ServersV2.js

import React, { useState, useEffect, useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import Sidebar from '../Sidebar/Sidebar';
import ServerCardV2 from './ServerCardV2/ServerCardV2';
import styles from './ServersV2.module.css';
import StatisticsCard from '../Dashboard/StatisticCard/StatisticCard';
import { FaServer } from 'react-icons/fa';
import { toast } from 'react-toastify';

const ServersV2 = () => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isSidebarOpen } = useContext(SidebarContext);

  useEffect(() => {
    const worker = new Worker('serverFetcherWorkerV2.js');
    const token = localStorage.getItem('token');

    worker.onmessage = (event) => {
      if (event.data.error) {
        setError(event.data.error);
        setLoading(false);
        toast.error(`Error fetching servers: ${event.data.error}`);
        return;
      }
      setServers(event.data);
      setLoading(false);
    };

    worker.postMessage({ action: 'FETCH_SERVERS_V2', token });

    // Polling: Fetch servers every 10 seconds
    const intervalId = setInterval(() => {
      worker.postMessage({ action: 'FETCH_SERVERS_V2', token });
    }, 10000); // 10,000 ms = 10 seconds

    // Cleanup on unmount
    return () => {
      clearInterval(intervalId);
      worker.terminate();
    };
  }, []);

  const handleServerDeleted = (deletedServerId) => {
    setServers(prevServers => prevServers.filter(server => server._id !== deletedServerId));
  };

  if (loading) {
    return (
      <div className={styles.serversPage}>
        <Sidebar />
        <main className={`${styles.mainContent} ${isSidebarOpen ? styles.mainContentExpanded : ''}`}>
          <p>Loading servers...</p>
        </main>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.serversPage}>
        <Sidebar />
        <main className={`${styles.mainContent} ${isSidebarOpen ? styles.mainContentExpanded : ''}`}>
          <p>Error loading servers: {error}</p>
        </main>
      </div>
    );
  }

  const activeStatuses = ['running', 'completed'];
  const serversOnline = servers.reduce((acc, server) => {
    return activeStatuses.includes(server.status) ? acc + 1 : acc;
  }, 0);
  return (
    <div className={styles.serversPage}>
      <Sidebar />
      <main className={`${styles.mainContent} ${isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <StatisticsCard
          title="Servers Online"
          icon={<FaServer />}
          value={serversOnline}
          showPercentage={false}
        />
        <div className={styles.serversListContainer}>
          {servers.length > 0 ? (
            <section className={styles.serversList}>
              {servers.map((server) => (
                <ServerCardV2 
                  key={server._id} 
                  server={server} 
                  onServerDeleted={handleServerDeleted} 
                />
              ))}
            </section>
          ) : (
            <div className={styles.noServers}>
              <p>No servers to display.</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ServersV2;
