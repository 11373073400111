export const defaultQuestionsList = {
    atb: [
      { question: "What was your first pet's name?", translation: "Quel était le nom de votre premier animal de compagnie?" },
      { question: "What was your favourite subject in school?", translation: "Quel était votre sujet préféré à l'école?" },
      { question: "In what city did you meet your spouse or significant other?", translation: "Dans quelle ville avez-vous rencontré votre conjoint ou partenaire?" },
      { question: "What is the name of your best childhood friend?", translation: "Quel est le nom de votre meilleur ami d'enfance?" },
      { question: "What was the last name of your favourite teacher?", translation: "Quel était le nom de famille de votre enseignant préféré?" },
      { question: "What is your favourite time of the day?", translation: "Quel est votre moment préféré de la journée?" },
      { question: "Who is your favourite musician?", translation: "Qui est votre musicien préféré?" },
      { question: "What was your first job?", translation: "Quel a été votre premier emploi?" },
      { question: "What was the name of your elementary or primary school?", translation: "Quel était le nom de votre école primaire?" },
      { question: "What was the name of your first stuffed animal?", translation: "Quel était le nom de votre premier animal en peluche?" },
      { question: "What is your favourite board game?", translation: "Quel est votre jeu de société préféré?" },
      { question: "Where do you want to retire?", translation: "Où voulez-vous prendre votre retraite?" },
      { question: "What is your favourite wild animal?", translation: "Quel est votre animal sauvage préféré?" },
      { question: "Who is your favourite author?", translation: "Qui est votre auteur préféré?" },
      { question: "What was your grandfather's occupation?", translation: "Quelle était la profession de votre grand-père?" },
      { question: "Where was your favourite family holiday vacation?", translation: "Où se trouvait vos vacances familiales préférées?" },
      { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
      { question: "What was your childhood nickname?", translation: "Quel était votre surnom d'enfance?" },
      { question: "What town did you live in as a child?", translation: "Dans quelle ville viviez-vous enfant?" },
      { question: "What was the last name of your first grade teacher?", translation: "Quel était le nom de famille de votre enseignant de première année?" },
      { question: "What is your favourite dessert?", translation: "Quel est votre dessert préféré?" },
      { question: "What is your favourite actor or actress or celebrity?", translation: "Qui est votre acteur, actrice ou célébrité préféré(e)?" },
      { question: "In what town was your first job?", translation: "Dans quelle ville était votre premier emploi?" },
      { question: "What was the color of your first car?", translation: "Quelle était la couleur de votre première voiture?" },
      { question: "How many TVs are in your home?", translation: "Combien de téléviseurs y a-t-il chez vous?" },
    ],
    bmo: [
        { question: "What was the name of your favourite superhero as a child?", translation: "Quel était le nom de votre super-héros préféré quand vous étiez enfant?" },
        { question: "What is the name of the city where your mother was born?", translation: "Quel est le nom de la ville où votre mère est née?" },
        { question: "What was the last name of your favourite teacher in high school?", translation: "Quel était le nom de famille de votre professeur préféré au lycée?" },
        { question: "What is the first name of your oldest nephew?", translation: "Quel est le prénom de votre neveu le plus âgé?" },
        { question: "What is the first name of your father's oldest sibling?", translation: "Quel est le prénom du frère ou de la sœur aîné(e) de votre père?" },
        { question: "What is the first name of your oldest cousin?", translation: "Quel est le prénom de votre cousin le plus âgé?" },
        { question: "What was the first name of your first roommate?", translation: "Quel était le prénom de votre premier colocataire?" },
        { question: "What is the first name of your spouse's/partner's father?", translation: "Quel est le prénom du père de votre conjoint(e)?" },
        { question: "What is the first name of your first friend?", translation: "Quel est le prénom de votre premier ami?" },
        { question: "What is the street name where you lived when you were 10 years old?", translation: "Quel est le nom de la rue où vous viviez lorsque vous aviez 10 ans?" },
        { question: "What is your youngest child's middle name?", translation: "Quel est le deuxième prénom de votre plus jeune enfant?" },
        { question: "What is your mother's middle name?", translation: "Quel est le deuxième prénom de votre mère?" },
        { question: "What is the first name of the maid of honour at your wedding?", translation: "Quel est le prénom de la demoiselle d'honneur à votre mariage?" },
        { question: "What is your favourite cartoon?", translation: "Quel est votre dessin animé préféré?" },
        { question: "What is your father's middle name?", translation: "Quel est le deuxième prénom de votre père?" },
        { question: "What is the first name of the best man at your wedding?", translation: "Quel est le prénom du garçon d'honneur à votre mariage?" },
        { question: "What is the middle name of your oldest sibling?", translation: "Quel est le deuxième prénom de votre frère ou sœur aîné(e)?" },
        { question: "What was the first name of your first manager?", translation: "Quel était le prénom de votre premier manager?" },
        { question: "What is your favourite musical instrument?", translation: "Quel est votre instrument de musique préféré?" },
        { question: "What is your spouse's/partner's middle name?", translation: "Quel est le deuxième prénom de votre conjoint(e)?" },
        { question: "What was the name of your first pet?", translation: "Quel était le nom de votre premier animal de compagnie?" },
        { question: "What was the last name of your favourite teacher in elementary school?", translation: "Quel était le nom de famille de votre professeur préféré à l'école primaire?" },
        { question: "What is the first name of your mother's oldest sibling?", translation: "Quel est le prénom du frère ou de la sœur aîné(e) de votre mère?" },
        { question: "Who was your favourite athlete as a child?", translation: "Quel était votre athlète préféré quand vous étiez enfant?" },
        { question: "What colour was your first car?", translation: "Quelle était la couleur de votre première voiture?" },
        { question: "What is the name of the city where your father was born?", translation: "Quel est le nom de la ville où votre père est né?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
      ],
      desjardins: [
        { question: "What was my mother's occupation when I was in elementary school?", translation: "Quel métier exerçait ma mère lorsque j'allais à l'école primaire?" },
        { question: "What is the name of my first pet?", translation: "Quel est le nom de mon premier animal de compagnie?" },
        { question: "What was my father's occupation when I was in high school?", translation: "Quel métier exerçait mon père lorsque j'allais à l'école secondaire?" },
        { question: "In which year (yyyy) did I move out of my parents' house to my first apartment?", translation: "En quelle année (aaaa) ai-je déménagé de chez mes parents pour m'installer dans mon premier appartement?" },
        { question: "What is my father's birth year (yyyy)?", translation: "Quelle est l'année (aaaa) de naissance de mon père?" },
        { question: "What is the name of the city/municipality of the school I attended in my fifth year of high school?", translation: "Quel est le nom de la ville/municipalité de l'école que je fréquentais à ma cinquième année du secondaire?" },
        { question: "What is the city/municipality of my first apartment?", translation: "Quelle est la ville/municipalité de mon premier appartement/logement?" },
        { question: "What is the natural hair color of my second child?", translation: "Quelle est la couleur naturelle des cheveux de mon deuxième enfant?" },
        { question: "What is the name of the street of the school I attended in my fourth year of high school?", translation: "Quel est le nom de la rue de l'école que je fréquentais à ma quatrième année du secondaire?" },
        { question: "What is the middle name (other than the usual first name) on the birth certificate of my first daughter?", translation: "Quel est le deuxième prénom (autre que son prénom usuel) figurant sur l'acte de naissance de ma première fille?" },
        { question: "What is the city where I landed the first time I took a plane?", translation: "Quelle est la ville où j'ai atterri la première fois que j'ai pris l'avion?" },
        { question: "What is the name of the elementary school where I completed kindergarten?", translation: "Quel est le nom de l'école primaire où j'ai complété ma maternelle?" },
        { question: "What is the first name of my maternal grandmother?", translation: "Quel est le prénom de ma grand-mère maternelle?" },
        { question: "What is the date (dd-mmm) of my first marriage?", translation: "Quelle est la date (jj-mmm) de mon premier mariage?" },
        { question: "What are the day and month (dd-mmm) of my eldest brother's birth?", translation: "Quels sont le jour et le mois (jj-mmm) de naissance de mon frère le plus âgé?" },
        { question: "What is my mother's natural hair color?", translation: "Quelle est la couleur naturelle des cheveux de ma mère?" },
        { question: "What is the first name of my second child?", translation: "Quel est le prénom de mon deuxième enfant?" },
        { question: "What is the country where I landed the first time I took a plane?", translation: "Quel est le pays où j'ai atterri la première fois que j'ai pris l'avion?" },
        { question: "What are the day and month (dd-mmm) of my mother's birth?", translation: "Quels sont le jour et le mois (jj-mmm) de naissance de ma mère?" },
        { question: "What is the name of the school I attended in my first year of high school?", translation: "Quel est le nom de l'école que je fréquentais à ma première année du secondaire?" },
        { question: "What was my father's occupation when I was in elementary school?", translation: "Quel métier exerçait mon père lorsque j'allais à l'école primaire?" },
        { question: "What is my mother's maiden name?", translation: "Quel est le nom de jeune fille de ma mère?" },
        { question: "What is the street number where I lived at 18?", translation: "Quel est le numéro de la rue où j'habitais à 18 ans?" },
        { question: "What is the street number where I lived at 30?", translation: "Quel est le numéro de la rue où j'habitais à 30 ans?" },
        { question: "What is the name of the city/municipality where I was born?", translation: "Quel est le nom de la ville/municipalité où je suis né?" },
        { question: "What are the day and month (dd-mm) of my eldest son's birth?", translation: "Quels sont le jour et le mois (jj-mm) de naissance de mon fils le plus âgé?" },
        { question: "What is the birth year (yyyy) of my first spouse?", translation: "Quelle est l'année (aaaa) de naissance de mon premier époux/ma première épouse?" },
        { question: "What is the name of the elementary school where I completed sixth grade?", translation: "Quel est le nom de l'école primaire où j'ai complété ma sixième année?" },
        { question: "What is the natural hair color of my eldest brother?", translation: "Quelle est la couleur naturelle des cheveux de mon frère le plus âgé?" },
        { question: "What is the city/municipality where the first house I owned is located?", translation: "Quelle est la ville/municipalité où est située la première maison dont j'ai été propriétaire?" },
        { question: "What is my natural hair color?", translation: "Quelle est la couleur naturelle de mes cheveux?" },
        { question: "What is the name of the city/municipality of the elementary school where I completed kindergarten?", translation: "Quel est le nom de la ville/municipalité de l'école primaire où j'ai complété ma maternelle?" },
        { question: "What is the name of the city/municipality of the university where I obtained my bachelor's degree?", translation: "Quel est le nom de la ville/municipalité de l'université où j'ai obtenu mon baccalauréat?" },
        { question: "What is the street number where I lived at 25?", translation: "Quel est le numéro de la rue où j'habitais à 25 ans?" },
        { question: "What is the name of the street of the elementary school where I completed sixth grade?", translation: "Quel est le nom de la rue de l'école primaire où j'ai complété ma sixième année?" },
        { question: "What is the name of the hospital/birthing center where I was born?", translation: "Quel est le nom de l'hôpital/de la maison de naissance où je suis né?" },
        { question: "What is the name of the city/municipality where I lived at 30?", translation: "Quel est le nom de la ville/municipalité où j'habitais à 30 ans?" },
        { question: "What is my paternal grandmother's maiden name?", translation: "Quel est le nom de jeune fille de ma grand-mère paternelle?" },
        { question: "What is the name of the first employer where I received my first regular paycheck?", translation: "Quel est le nom du premier employeur où j'ai reçu mon premier salaire régulier?" },
        { question: "What was the city/municipality where my first serious love lived?", translation: "Quelle était la ville/municipalité où habitait mon premier amour sérieux?" },
        { question: "In total, how many siblings does/did my father have?", translation: "Au total, combien de frères et soeurs a/avait mon père?" },
        { question: "What is the name of the street of the school I attended in my fifth year of high school?", translation: "Quel est le nom de la rue de l'école que je fréquentais à ma cinquième année du secondaire?" },
        { question: "What is my mother's birth year (yyyy)?", translation: "Quelle est l'année (aaaa) de naissance de ma mère?" },
        { question: "What are the day and month (dd-mm) of my father's birth?", translation: "Quels sont le jour et le mois (jj-mm) de naissance de mon père?" },
        { question: "What is the name of the school I attended in my third year of high school?", translation: "Quel est le nom de l'école que je fréquentais à ma troisième année du secondaire?" },
        { question: "What is the name of the city/municipality where my father was born?", translation: "Quel est le nom de la ville/municipalité où est né mon père?" },
      ],
      manulife: [
        { question: "What was your first pet's name?", translation: "Quel était le nom de votre premier animal de compagnie?" },
        { question: "What was your favourite subject in school?", translation: "Quel était votre sujet préféré à l'école?" },
        { question: "In what city did you meet your spouse or significant other?", translation: "Dans quelle ville avez-vous rencontré votre conjoint ou partenaire?" },
        { question: "What is the name of your best childhood friend?", translation: "Quel est le nom de votre meilleur ami d'enfance?" },
        { question: "What was the last name of your favourite teacher?", translation: "Quel était le nom de famille de votre enseignant préféré?" },
        { question: "What is your favourite time of the day?", translation: "Quel est votre moment préféré de la journée?" },
        { question: "Who is your favourite musician?", translation: "Qui est votre musicien préféré?" },
        { question: "What was your first job?", translation: "Quel a été votre premier emploi?" },
        { question: "What was the name of your elementary or primary school?", translation: "Quel était le nom de votre école primaire?" },
        { question: "What was the name of your first stuffed animal?", translation: "Quel était le nom de votre premier animal en peluche?" },
        { question: "What is your favourite board game?", translation: "Quel est votre jeu de société préféré?" },
        { question: "Where do you want to retire?", translation: "Où voulez-vous prendre votre retraite?" },
        { question: "What is your favourite wild animal?", translation: "Quel est votre animal sauvage préféré? Pas de chiens, de chats ou de perruches, s'il vous plaît." },
        { question: "Who is your favourite author?", translation: "Qui est votre auteur préféré?" },
        { question: "What was your grandfather's occupation?", translation: "Quelle était la profession de votre grand-père?" },
        { question: "Where was your favourite family holiday vacation?", translation: "Où se trouvaient vos vacances familiales préférées?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
        { question: "What was your childhood nickname?", translation: "Quel était votre surnom d'enfance?" },
        { question: "What town did you live in as a child?", translation: "Dans quelle ville viviez-vous enfant?" },
        { question: "What was the last name of your first grade teacher?", translation: "Quel était le nom de famille de votre enseignant de première année?" },
        { question: "What is your favourite dessert?", translation: "Quel est votre dessert préféré?" },
        { question: "Who is your favourite actor or actress or celebrity?", translation: "Qui est votre acteur, actrice ou célébrité préféré(e)?" },
        { question: "In what town was your first job?", translation: "Dans quelle ville était votre premier emploi?" },
        { question: "What was the color of your first car?", translation: "Quelle était la couleur de votre première voiture?" },
        { question: "How many TVs are in your home?", translation: "Combien de téléviseurs y a-t-il chez vous?" },
      ],
      meridian: [
        { question: "Oldest nephew's first name", translation: "Prénom du plus vieux neveu" },
        { question: "City you were married in", translation: "Ville où vous vous êtes marié" },
        { question: "Colour of your first car", translation: "Couleur de votre première voiture" },
        { question: "Best friend's first name", translation: "Prénom de votre meilleur ami" },
        { question: "Name of your first pet", translation: "Nom de votre premier animal de compagnie" },
        { question: "Mother's maiden name", translation: "Nom de jeune fille de votre mère" },
        { question: "Maternal grandmother's first name", translation: "Prénom de votre grand-mère maternelle" },
        { question: "Maid of Honour's first name", translation: "Prénom de la demoiselle d'honneur" },
        { question: "City of your High School", translation: "Ville de votre lycée" },
        { question: "Father's middle name", translation: "Deuxième prénom de votre père" },
      ],
      motusbank: [
        { question: "What is the first name of your oldest nephew?", translation: "Quel est le prénom de votre plus vieux neveu?" },
        { question: "What is the name of the city you were married in?", translation: "Quel est le nom de la ville où vous vous êtes marié?" },
        { question: "What was the colour of your first car?", translation: "Quelle était la couleur de votre première voiture?" },
        { question: "What was your best friend's first name?", translation: "Quel était le prénom de votre meilleur ami?" },
        { question: "What is the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was the first name of your Maid of Honour?", translation: "Quel était le prénom de votre demoiselle d'honneur?" },
        { question: "What is the city of your high school?", translation: "Quel est le nom de la ville de votre lycée?" },
        { question: "What is your father's middle name?", translation: "Quel est le deuxième prénom de votre père?" },
      ],
      rbc: [
        { question: "What was the first book I ever read?", translation: "Quel était le premier livre que j'ai lu?" },
        { question: "What was the first company I ever worked for?", translation: "Quelle était la première entreprise pour laquelle j'ai travaillé?" },
        { question: "What High School did my mother attend?", translation: "Quel lycée ma mère a-t-elle fréquenté?" },
        { question: "In which city was my mother born?", translation: "Dans quelle ville est née ma mère?" },
        { question: "What is my spouse's/partner's middle name?", translation: "Quel est le deuxième prénom de mon conjoint/partenaire?" },
        { question: "In which city did I get married?", translation: "Dans quelle ville me suis-je marié?" },
        { question: "What is my best friend's first name?", translation: "Quel est le prénom de mon meilleur ami?" },
        { question: "What is the name of the first school I attended?", translation: "Quel est le nom de la première école que j'ai fréquentée?" },
        { question: "What was my kindergarten teacher's last name?", translation: "Quel était le nom de famille de mon professeur de maternelle?" },
        { question: "What is the first name of my oldest cousin?", translation: "Quel est le prénom de mon plus vieux cousin?" },
        { question: "What was the first movie I ever saw?", translation: "Quel a été le premier film que j'ai vu?" },
        { question: "What is the middle name of my oldest child?", translation: "Quel est le deuxième prénom de mon enfant aîné?" },
        { question: "In which city was my father born?", translation: "Dans quelle ville est né mon père?" },
        { question: "Who was my favourite cartoon character as a child?", translation: "Quel était mon personnage de dessin animé préféré quand j'étais enfant?" },
        { question: "What is my mother's middle name?", translation: "Quel est le deuxième prénom de ma mère?" },
        { question: "In what year did I meet my significant other?", translation: "En quelle année ai-je rencontré mon partenaire?" },
        { question: "What was my first pet's name?", translation: "Quel était le nom de mon premier animal de compagnie?" },
        { question: "First name of the maid of honour at my wedding?", translation: "Prénom de la demoiselle d'honneur à mon mariage?" },
        { question: "First name of my best friend in elementary school?", translation: "Prénom de mon meilleur ami à l'école primaire?" },
        { question: "Name of my all-time favourite movie character?", translation: "Nom de mon personnage de film préféré de tous les temps?" },
        { question: "What was the make of my first car?", translation: "Quelle était la marque de ma première voiture?" },
        { question: "What High School did my father attend?", translation: "Quel lycée mon père a-t-il fréquenté?" },
        { question: "Which city did I meet my significant other?", translation: "Dans quelle ville ai-je rencontré mon partenaire?" },
        { question: "Last name of my favourite High School teacher?", translation: "Nom de famille de mon professeur préféré au lycée?" },
        { question: "Which country did I go to on my honeymoon?", translation: "Dans quel pays ai-je passé ma lune de miel?" },
        { question: "First name of my best man at my wedding?", translation: "Prénom de mon témoin de mariage?" },
        { question: "What was the name of my first manager?", translation: "Quel était le nom de mon premier manager?" },
        { question: "In what town or city was my significant other born?", translation: "Dans quelle ville ou quel village est né mon partenaire?" },
        { question: "Last name of my childhood best friend?", translation: "Nom de famille de mon meilleur ami d'enfance?" },
        { question: "What is the first name of my oldest nephew?", translation: "Quel est le prénom de mon plus vieux neveu?" },
      ],
      tangerine: [
        { question: "What was your dream job as a child?", translation: "Quel était votre métier de rêve quand vous étiez enfant?" },
        { question: "What is the name of your childhood super hero?", translation: "Quel est le nom de votre super-héros d'enfance?" },
        { question: "What was your favourite childhood story?", translation: "Quelle était votre histoire d'enfance préférée?" },
        { question: "What was your high school mascot?", translation: "Quelle était la mascotte de votre lycée?" },
        { question: "What is the name of your childhood best friend?", translation: "Quel est le nom de votre meilleur ami d'enfance?" },
        { question: "What was your childhood nickname?", translation: "Quel était votre surnom d'enfance?" },
        { question: "Which sports team did you like most when you were a child?", translation: "Quelle équipe sportive aimiez-vous le plus quand vous étiez enfant?" },
        { question: "What was your favourite place to visit as a child?", translation: "Quel était votre endroit préféré à visiter quand vous étiez enfant?" },
        { question: "In what town or city did your parents meet?", translation: "Dans quelle ville ou quel village vos parents se sont-ils rencontrés?" },
        { question: "On what street did you grow up?", translation: "Dans quelle rue avez-vous grandi?" },
        { question: "What was the name of your first pet?", translation: "Quel était le nom de votre premier animal de compagnie?" },
        { question: "What was the name of your favourite teacher?", translation: "Quel était le nom de votre enseignant préféré?" },
        { question: "What was your favourite childhood toy?", translation: "Quel était votre jouet préféré quand vous étiez enfant?" },
        { question: "What was the name of your first girlfriend or boyfriend?", translation: "Quel était le nom de votre première petite amie ou petit ami?" },
        { question: "What was the first concert you attended?", translation: "Quel a été le premier concert auquel vous avez assisté?" },
      ],
      coastcapital: [
        { question: "What is your favourite colour?", translation: "Quelle est votre couleur préférée?" },
        { question: "What was the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is the name of the street you grew up on?", translation: "Quel est le nom de la rue où vous avez grandi?" },
        { question: "What was the model of your first car?", translation: "Quel était le modèle de votre première voiture?" },
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What city were you married in?", translation: "Dans quelle ville vous êtes-vous marié?" },
        { question: "What was the name of your high school?", translation: "Quel était le nom de votre lycée?" },
        { question: "What is the name of your childhood best friend?", translation: "Quel est le nom de votre meilleur ami d'enfance?" },
        { question: "What is your favourite movie?", translation: "Quel est votre film préféré?" },
        { question: "What is your father's middle name?", translation: "Quel est le deuxième prénom de votre père?" },
      ],
      laurentian: [
        { question: "What is the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was your first job?", translation: "Quel a été votre premier emploi?" },
        { question: "What is the name of the city where you were born?", translation: "Quel est le nom de la ville où vous êtes né?" },
        { question: "What was the name of your elementary school?", translation: "Quel était le nom de votre école primaire?" },
        { question: "What is your favourite sport?", translation: "Quel est votre sport préféré?" },
        { question: "What was your childhood nickname?", translation: "Quel était votre surnom d'enfance?" },
        { question: "What is the name of your favourite teacher?", translation: "Quel est le nom de votre enseignant préféré?" },
        { question: "What is your favourite music band?", translation: "Quel est votre groupe de musique préféré?" },
        { question: "What was the name of your first boss?", translation: "Quel était le nom de votre premier patron?" },
      ],
      bnc: [
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was your high school mascot?", translation: "Quelle était la mascotte de votre lycée?" },
        { question: "What is the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What was the name of the street you grew up on?", translation: "Quel était le nom de la rue où vous avez grandi?" },
        { question: "What is your favourite book?", translation: "Quel est votre livre préféré?" },
        { question: "What was the make of your first car?", translation: "Quelle était la marque de votre première voiture?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
        { question: "What is your favourite food?", translation: "Quel est votre plat préféré?" },
        { question: "What was the name of your first boss?", translation: "Quel était le nom de votre premier patron?" },
        { question: "What is your father's middle name?", translation: "Quel est le deuxième prénom de votre père?" },
      ],
      pcfinancial: [
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is the name of the street you grew up on?", translation: "Quel est le nom de la rue où vous avez grandi?" },
        { question: "What was your favourite subject in school?", translation: "Quel était votre sujet préféré à l'école?" },
        { question: "What is the name of your first school?", translation: "Quel était le nom de votre première école?" },
        { question: "What is your favourite colour?", translation: "Quelle est votre couleur préférée?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
        { question: "What is your favourite food?", translation: "Quel est votre plat préféré?" },
        { question: "What is the name of your best friend?", translation: "Quel est le nom de votre meilleur ami?" },
        { question: "What was the make of your first car?", translation: "Quelle était la marque de votre première voiture?" },
      ],
      peoplestrust: [
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is the name of the street you grew up on?", translation: "Quel est le nom de la rue où vous avez grandi?" },
        { question: "What was your favourite subject in school?", translation: "Quel était votre sujet préféré à l'école?" },
        { question: "What is the name of your first school?", translation: "Quel était le nom de votre première école?" },
        { question: "What is your favourite colour?", translation: "Quelle est votre couleur préférée?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
        { question: "What is your favourite food?", translation: "Quel est votre plat préféré?" },
        { question: "What is the name of your best friend?", translation: "Quel est le nom de votre meilleur ami?" },
        { question: "What was the make of your first car?", translation: "Quelle était la marque de votre première voiture?" },
      ],
      simplii: [
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is the name of the street you grew up on?", translation: "Quel est le nom de la rue où vous avez grandi?" },
        { question: "What was your favourite subject in school?", translation: "Quel était votre sujet préféré à l'école?" },
        { question: "What is the name of your first school?", translation: "Quel était le nom de votre première école?" },
        { question: "What is your favourite colour?", translation: "Quelle est votre couleur préférée?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
        { question: "What is your favourite food?", translation: "Quel est votre plat préféré?" },
        { question: "What is the name of your best friend?", translation: "Quel est le nom de votre meilleur ami?" },
        { question: "What was the make of your first car?", translation: "Quelle était la marque de votre première voiture?" },
      ],
      td: [
        { question: "What is your mother's maiden name?", translation: "Quel est le nom de jeune fille de votre mère?" },
        { question: "What was the name of your first pet?", translation: "Quel est le nom de votre premier animal de compagnie?" },
        { question: "What is the name of the street you grew up on?", translation: "Quel est le nom de la rue où vous avez grandi?" },
        { question: "What was your favourite subject in school?", translation: "Quel était votre sujet préféré à l'école?" },
        { question: "What is the name of your first school?", translation: "Quel était le nom de votre première école?" },
        { question: "What is your favourite colour?", translation: "Quelle est votre couleur préférée?" },
        { question: "What city were you born in?", translation: "Dans quelle ville êtes-vous né?" },
        { question: "What is your favourite food?", translation: "Quel est votre plat préféré?" },
        { question: "What is the name of your best friend?", translation: "Quel est le nom de votre meilleur ami?" },
        { question: "What was the make of your first car?", translation: "Quelle était la marque de votre première voiture?" },
      ],
    // Add default questions for other banks here...
  };
  