import React from 'react';
import styles from './StatisticCard.module.css';
import { FaArrowUp } from 'react-icons/fa';

const StatisticCard = ({ title, value, icon, percentChange ,showPercentage }) => {
  // You can remove the hardcoded percentage change if it's now passed as a prop or dynamically determined elsewhere
  const percentageChange = 5; // This is hardcoded for demonstration, you can make it a prop

  // Function to format percentage change
  const formatPercentageChange = (change) => {
    if (showPercentage && Number.isFinite(change)) { // Only format if showPercentage is true
      return (
        <div className={styles.percentageChange}>
          <FaArrowUp className={styles.positive} />
          {`+${change}%`}
        </div>
      );
    }
    return null; // If showPercentage is false, don't display percentage change
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={styles.iconSize}>{icon}</div> {/* Apply the class here */}
        <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.cardBody}>
        <p className={styles.value}>{value}</p>
        {/* Only display the percentage change if showPercentage is true */}
        {formatPercentageChange(percentChange)}
      </div>
    </div>
  );
};

// Set default props in case showPercentage isn't provided
StatisticCard.defaultProps = {
  showPercentage: true
};

export default StatisticCard;
