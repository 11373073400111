// LiveActionV2.js

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  memo,
} from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {
  formatDistanceToNow,
  parseISO,
  isValid,
  format,
} from 'date-fns';
import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
} from '@mui/material';
import SidebarContext from '../../contexts/SidebarContext';
import Sidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify';
import styles from './LiveActionV2.module.css';
import isEqual from 'lodash/isEqual';
import VisitorManagerV2 from './VisitorManagerV2';

// Utility function for safe date formatting
const safeFormatDate = (dateString, relative = false) => {
  try {
    if (!dateString) {
      return 'N/A';
    }
    const date = parseISO(dateString);
    if (!isValid(date)) {
      return 'N/A';
    }
    return relative
      ? `${formatDistanceToNow(date, { addSuffix: true })}`
      : format(date, 'PPpp');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'N/A';
  }
};

const LiveActionV2 = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [liveLogs, setLiveLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingLogDetails, setIsLoadingLogDetails] = useState(false);
  const [error, setError] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [activeLogsCount, setActiveLogsCount] = useState(0);
  const [viewingLogId, setViewingLogId] = useState(null);

  // Determines if a log is active based on lastUpdatedAt within 30 minutes
  const isActiveLog = (log) => {
    return (
      log.updatedAt &&
      new Date() - new Date(log.updatedAt) < 30 * 1000
    );
  };

  // Updates the count of active logs
  const updateActiveLogsCount = (logs) => {
    const activeCount = logs.filter((log) => isActiveLog(log)).length;
    setActiveLogsCount(activeCount);
  };

  // Sort logs: active logs first, then by createdAt descending
  const sortLogs = (logs) => {
    return logs.sort((a, b) => {
      const aActive = isActiveLog(a);
      const bActive = isActiveLog(b);
      if (aActive === bActive) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return aActive ? -1 : 1;
    });
  };

  // Polling for Recent Logs with dynamic interval based on modal state
  useEffect(() => {
    const token = localStorage.getItem('token');

    let pollingInterval;
    let intervalId;

    const fetchRecentLogs = async () => {
      try {
        const response = await axios.get('/api/v2/recent-logs', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
          },
        });

        const data = response.data;
        const logsWithId = data.logSummaries.map((log) => ({
          ...log,
          id: log.logId, // Ensure id is set to logId
          lastActiveAgo: isValid(parseISO(log.lastActiveAt))
            ? `${formatDistanceToNow(parseISO(log.lastActiveAt))} ago`
            : 'N/A',
          location: `${log.city || 'N/A'}, ${log.regionCode || 'N/A'}`,
        }));

        setLiveLogs(sortLogs(logsWithId));
        updateActiveLogsCount(logsWithId);
        setTotalRowCount(data.totalCount);
      } catch (error) {
        console.error('Error fetching recent logs:', error);
      }
    };

    // Initial fetch
    fetchRecentLogs();

    // Set polling interval based on modal state
    if (isModalOpen) {
      pollingInterval = 5000; // 5 seconds when modal is open
    } else {
      pollingInterval = 3000; // 3 seconds when modal is closed
    }

    intervalId = setInterval(fetchRecentLogs, pollingInterval);

    return () => clearInterval(intervalId);
  }, [paginationModel, isModalOpen]);

  // Polling for Specific Log Details when modal is open
  useEffect(() => {
    if (!isModalOpen || !viewingLogId) {
      return;
    }

    const token = localStorage.getItem('token');
    let intervalId;

    const fetchLogDetails = async () => {
      try {
        const response = await axios.get(`/api/v2/logs/${viewingLogId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = response.data;

        // Map logId to id for consistency
        data.id = data.logId;

        setSelectedLog((prevLog) => {
          if (!prevLog) {
            // Modal just opened, set selectedLog to fetched data
            return data;
          } else {
            // Merge fetched data with previous state
            return {
              ...data,
            };
          }
        });
      } catch (err) {
        if (
          err.response &&
          err.response.status === 403 &&
          err.response.data.message ===
            'This log is locked due to expired membership. Please renew your membership to access the log.'
        ) {
          toast.error(
            'Your membership has expired. Please renew to access this log.'
          );
          setIsModalOpen(false);
        } else {
          console.error('Failed to fetch log details:', err);
          setError('Failed to load details. Please try again.');
          setIsModalOpen(false);
        }
      }
    };

    // Initial fetch
    fetchLogDetails();

    // Poll every 3 seconds
    intervalId = setInterval(fetchLogDetails, 3000);

    return () => clearInterval(intervalId);
  }, [isModalOpen, viewingLogId]);

  const handlePaginationModelChange = useCallback((model) => {
    setPaginationModel(model);
  }, []);

  const handleViewDetails = async (event, logId) => {
    event.stopPropagation();
    const token = localStorage.getItem('token');
    setIsLoadingLogDetails(true);
    setError('');
    try {
      setViewingLogId(logId);
      setIsModalOpen(true);

      const response = await axios.get(`/api/v2/logs/${logId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = response.data;

      // Map logId to id for consistency
      data.id = data.logId;

      setSelectedLog(data);
    } catch (err) {
      if (
        err.response &&
        err.response.status === 403 &&
        err.response.data.message ===
          'This log is locked due to expired membership. Please renew your membership to access the log.'
      ) {
        toast.error(
          'Your membership has expired. Please renew to access this log.'
        );
      } else {
        console.error('Failed to fetch log details:', err);
        setError('Failed to load details. Please try again.');
      }
      setIsModalOpen(false);
    } finally {
      setIsLoadingLogDetails(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedLog(null);
    setViewingLogId(null);
  };

  const renderActiveStatusCell = (params) => {
    const isActive = isActiveLog(params.row);
    return (
      <Button
        variant="contained"
        style={{
          backgroundColor: isActive ? '#4CAF50' : '#F44336',
          color: 'white',
          cursor: 'default',
        }}
        disableRipple
        disabled
      >
        {isActive ? 'Online' : 'Offline'}
      </Button>
    );
  };

  const columns = [
    {
      field: 'activeStatus',
      headerName: 'Status',
      width: 110,
      renderCell: renderActiveStatusCell,
    },
    {
      field: 'ipAddress',
      headerName: 'IP Address',
      width: 130,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 180,
      valueGetter: (params) => {
        return params.row.location;
      },
    },
    {
      field: 'lastActiveAgo',
      headerName: 'Last Active',
      width: 150,
    },
    {
      field: 'latestEventData',
      headerName: 'Latest Event Data',
      width: 250,
      valueGetter: (params) => params.row.latestEventData || '',
    },
    {
      field: 'templateName',
      headerName: 'Template Name',
      width: 140,
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 150,
      renderCell: (params) => (
        <Button
          color="info"
          onClick={(event) =>
            handleViewDetails(event, params.row.id || params.row.logId)
          }
          disabled={isLoadingLogDetails}
        >
          Manage
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <main
        className={`${styles.mainContent} ${
          !isSidebarOpen ? styles.mainContentExpanded : ''
        }`}
      >
        <div className={styles.contentHeader}>
          <h1>Active Users: {activeLogsCount}</h1>
        </div>
        <DataGrid
          rows={liveLogs}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25]}
          rowCount={totalRowCount}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          sx={{
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            '& .MuiDataGrid-root': {
              backgroundColor: '#242F3E',
              color: '#E3E3E3',
              border: 'none',
              '&:after': {
                content: 'none',
              },
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#1B2430',
              color: '#eceff1cf',
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
              border: 'none',
            },
            '& .MuiDataGrid-row': {
              borderBottom: 'none',
              '&:nth-of-type(odd)': {
                backgroundColor: '#192534',
              },
              '&:nth-of-type(even)': {
                backgroundColor: '#192534',
              },
              '&.Mui-selected': {
                backgroundColor: '#192738',
              },
              '&:hover': {
                backgroundColor: '#20303d',
              },
            },
            '& .MuiDataGrid-cell': {
              borderRight: 'none',
              color: '#eef9ff',
            },
            '& .MuiDataGrid-overlay': {
              backgroundColor: '#242F3E',
            },
            '& .MuiDataGrid-pagination': {
              color: '#E3E3E3',
            },
            '& .MuiDataGrid-sortIcon': {
              color: '#E3E3E3',
            },
            '& .MuiTablePagination-root': {
              color: '#E3E3E3',
              borderTop: 'none',
            },
            '& .MuiInputBase-root': {
              color: '#E3E3E3',
              '& .MuiSvgIcon-root': {
                color: '#E3E3E3',
              },
            },
            '& .MuiDataGrid-menuIcon': {
              color: '#E3E3E3',
            },
            '& .MuiButtonBase-root': {
              color: '#E3E3E3',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              borderBottom: 'none',
              color: '#FFFFFF',
            },
            '&, [class^=MuiDataGrid]': { border: 'none' },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: '#FFFFFF',
            },
          }}
        />
      </main>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="log-details-modal"
        aria-describedby="log-details-description"
        className={styles.modal}
      >
        {isLoadingLogDetails ? (
          <Box
            className={styles.modalContent}
            style={{ backgroundColor: '#2d3748', color: '#ffffff' }}
          >
            <Typography>Loading...</Typography>
          </Box>
        ) : error ? (
          <Box className={styles.modalContent}>
            <Typography color="error">{error}</Typography>
          </Box>
        ) : selectedLog ? (
          <DetailView log={selectedLog} />
        ) : (
          <Box className={styles.modalContent}>
            <Typography>Details not available.</Typography>
          </Box>
        )}
      </Modal>
    </div>
  );
};

// Memoized DetailView component
const DetailView = memo(({ log }) => {
  const textFieldSx = {
    backgroundColor: '#2d3748',
    '& .MuiInputBase-input': {
      color: '#ffffff',
    },
    '& .MuiInputLabel-root': {
      color: '#ffffff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#4a5568',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#718096',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#90cdf4',
    },
  };

  return (
    <Box className={styles.modalContent}>
      <Typography
        variant="h6"
        component="h2"
        gutterBottom
        className={styles.sectionHeader}
      >
        Log Details
      </Typography>

      <Divider className={styles.sectionDivider} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="IP Address"
            value={log.ipAddress || 'N/A'}
            fullWidth
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={textFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="User Agent"
            value={log.userAgent || 'N/A'}
            fullWidth
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={textFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Created At"
            value={safeFormatDate(log.createdAt)}
            fullWidth
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={textFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Last Updated"
            value={safeFormatDate(log.updatedAt, true)} // Relative time
            fullWidth
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={textFieldSx}
          />
        </Grid>
        {/* New Fields */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Current Page"
            value={log.currentPage || 'N/A'}
            fullWidth
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={textFieldSx}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Location"
            value={
              log.city && log.regionCode
                ? `${log.city}, ${log.regionCode}`
                : 'N/A'
            }
            fullWidth
            margin="dense"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={textFieldSx}
          />
        </Grid>
      </Grid>

      {/* Events Section */}
      <Typography variant="h6" style={{ marginTop: '24px' }}>
        Events:
      </Typography>
      {log.events && log.events.length > 0 ? (
        <Box className={styles.eventsContainer}>
          <Table className={styles.eventsTable}>
            <TableHead>
              <TableRow>
                <TableCell>Event Type</TableCell>
                <TableCell>Current Page</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {log.events.map((event, index) => (
                <TableRow key={index}>
                  <TableCell>{event.eventType}</TableCell>
                  <TableCell>{event.currentPage || 'N/A'}</TableCell>
                  <TableCell
                    style={{ maxWidth: '200px', wordWrap: 'break-word' }}
                  >
                    {JSON.stringify(event.data)}
                  </TableCell>
                  <TableCell>
                    {safeFormatDate(event.timestamp, true)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Typography variant="body2">No events available.</Typography>
      )}

      {/* Visitor Manager Section */}
      <VisitorManagerV2 logId={log.id || log.logId} /> {/* Pass logId as a prop */}
    </Box>
  );
});

export default LiveActionV2;