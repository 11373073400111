import React from 'react';
import './NotificationPopup.css';
import MetaMaskLogo from './MetaMaskLogo.svg';
import TelegramLogo from './TelegramLogo.svg';

function NotificationPopup({ message, onClose, onRetry, isLoading, errorType }) {
    const logo = errorType === 'metamask' ? MetaMaskLogo : errorType === 'telegram' ? TelegramLogo : null;
    const altText = errorType === 'metamask' ? 'MetaMask' : 'Telegram';

    return (
        <div className='notification-popup'>
            <div className='notification-content'>
                {logo && <img src={logo} altText={altText} className='notification-icon'/>}
                {/* <img src={MetaMaskLogo} alt='MetaMask' className='notification-icon' /> */}
                <p className='notification-message'>{message}</p>
                <button onClick={onRetry} className='notification-button'>Retry</button>
            </div>
            <button onClick={onClose} className='notification-close-btn' aria-label='Close notification'>×</button>
            {isLoading && <div className='notification-loading-bar'></div>}
        </div>
    );
}


export default NotificationPopup;

