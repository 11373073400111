import { useEffect, useState } from 'react';

const useMetaMaskAvailability = () => {
  const [isMetaMaskAvailable, setIsMetaMaskAvailable] = useState(false);

  useEffect(() => {
    setIsMetaMaskAvailable(typeof window.ethereum !== 'undefined');
  }, []);

  return isMetaMaskAvailable;
};

export default useMetaMaskAvailability;
