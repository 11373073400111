import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './AuthContext/AuthContext';
import { SidebarProvider } from './contexts/SidebarContext'; // Import the SidebarProvider
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  //<React.StrictMode>
    <Router>
      <AuthProvider>
        <SidebarProvider> {/* Add the SidebarProvider here */}
          <App/>
        </SidebarProvider>
      </AuthProvider>
    </Router>
  //</React.StrictMode>
);
