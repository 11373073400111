import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Typography, FormControl, InputLabel, Select, MenuItem, Button, Switch, FormGroup, FormControlLabel, Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const LiveRatManager = () => {
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState('');
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [loadingDuration, setLoadingDuration] = useState('unlimited');

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await fetch('/api/active-domains', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch domains');
        const data = await response.json();
        setDomains(data);
        if (data.length > 0) {
          setSelectedDomain(data[0]._id);
          setIsFeatureEnabled(data[0].liveRatEnabled);
          setLoadingDuration(data[0].loadingTimeInSeconds || 'unlimited');
        } else {
          setSelectedDomain('');
          setIsFeatureEnabled(false);
          setLoadingDuration('unlimited');
        }
      } catch (error) {
        console.error('Failed to fetch domains:', error);
      }
    };

    fetchDomains();
  }, []);

  const handleDomainChange = (event) => {
    const domain = domains.find(d => d._id === event.target.value);
    if (domain) {
      setSelectedDomain(domain._id);
      setIsFeatureEnabled(domain.liveRatEnabled);
      setLoadingDuration(domain.loadingTimeInSeconds || 'unlimited');
    }
  };

  const handleFeatureToggle = () => {
    setIsFeatureEnabled(prevState => !prevState);
  };

  const handleDurationChange = (event) => {
    setLoadingDuration(event.target.value);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await fetch(`/api/domains/${selectedDomain}/live-rat-settings`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          liveRatEnabled: isFeatureEnabled,
          loadingTimeInSeconds: loadingDuration === 'unlimited' ? null : loadingDuration,
        }),
      });
      if (!response.ok) throw new Error('Failed to save changes');
      toast.success('Live Rat settings updated successfully');
      console.log('Saved Changes successful');
    } catch (error) {
      console.error('Failed to save changes:', error);
      toast.error('Failed to update Live Rat settings');
    }
  };

  return (
    <Box sx={{ p: 2, color: '#fff', bgcolor: '#131a22' }}>
      <Typography variant="h6" sx={{ mb: 2, color: 'grey.300' }}>
        Manage Live Rat Feature
      </Typography>
      <Box display="flex" alignItems="center" gap={2}>
        <FormControl variant="outlined" sx={{
          minWidth: 120,
          color: 'white',
          '.MuiInputLabel-root': {
            color: 'grey.400' // Lighter color for better visibility
          },
          '.MuiOutlinedInput-root': {
            color: 'white',
            borderColor: 'grey.700',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.light' // Highlight color when focused
            }
          },
          '.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
          }
        }}>
          <InputLabel id="domain-select-label">Domain</InputLabel>
          <Select
            labelId="domain-select-label"
            value={selectedDomain}
            onChange={handleDomainChange}
            label="Domain"
            sx={{
              '.MuiSelect-select': {
                color: 'white', // Ensures the dropdown text is also white
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'grey.700' // Consistent border color
              },
              '& .MuiSvgIcon-root': {
                color: 'grey.300' // Dropdown arrow color
              }
            }}
          >
            {domains.length > 0 ? (
              domains.map(domain => (
                <MenuItem key={domain._id} value={domain._id} sx={{ color: '#000' }}>{domain.domainName}</MenuItem>
              ))
            ) : (
              <MenuItem disabled sx={{ color: 'grey.700' }}>No Active Domains</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isFeatureEnabled} onChange={handleFeatureToggle} sx={{ '.MuiSwitch-switchBase': { color: 'grey.700' } }} />}
            label={isFeatureEnabled ? 'Enabled' : 'Disabled'}
            sx={{ color: 'grey.300', '.MuiTypography-root': { color: 'grey.300' } }}
          />
        </FormGroup>
        <FormControl variant="outlined" sx={{ minWidth: 120, '.MuiInputLabel-outlined.MuiInputLabel-shrink': { transform: 'translate(14px, -6px) scale(0.75)', color: 'grey.500' }, '.MuiOutlinedInput-root': { color: 'white', borderColor: 'grey.700' } }}>
          <InputLabel htmlFor="loading-duration-select" sx={{ color: 'black.500' }}>Loading Duration</InputLabel>
          <Select
            labelId="loading-duration-select"
            id="loadingDuration"
            value={loadingDuration}
            onChange={handleDurationChange}
            label="Loading Duration"
            sx={{
              color: 'white',
              '.MuiOutlinedInput-notchedOutline': { borderColor: 'grey.700' },
              '& .MuiSvgIcon-root': { color: 'grey.300' } // Style for the dropdown arrow
            }}
            IconComponent={ArrowDropDownIcon} // Ensures custom icon is used
          >
            <MenuItem value="unlimited" sx={{ color: '#000' }}>Unlimited</MenuItem>
            {[5, 10, 15, 20, 25, 30, 40, 45, 50].map(duration => (
              <MenuItem key={duration} value={duration} sx={{ color: '#000' }}>
                {`${duration} seconds`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          disabled={!selectedDomain}
          sx={{ bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' }, '&.Mui-disabled': { bgcolor: 'grey.800' } }}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};

export default LiveRatManager;
