import React from 'react';
import styles from './TabsStylesheet.module.css';
import shipping from './data/shippingData';

const Shipping = ({ onTemplateSelect, selectedTemplate }) => {

  const handleSelectTemplate = (templateName) => {
    onTemplateSelect(templateName); 
    console.log('Selected Template:', templateName);
  };

  const cardClassName = (templateName) => {
    return `${styles.optionCard} ${selectedTemplate === templateName ? styles.activeCard : styles.inactiveCard}`;
  };

  return (
    <div className={styles.BankContent}>
      <div className={styles.optionsGrid}>
        {shipping.map(service => (
          <div key={service.name} className={cardClassName(service.name)} onClick={() => handleSelectTemplate(service.name)}>
            <img src={service.logo} alt={service.name} className={styles.optionIcon} />
            <div className={styles.optionText}>{service.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Shipping;
