import React, { useState, useEffect, memo, useCallback } from 'react';
import axios from 'axios';
import { format, parseISO, isValid } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Modal,
  Typography,
  Divider,
  Grid,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  LinearProgress,
  Dialog, // Import Dialog components
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import styles from './UserActivityLogV2.module.css';
import * as XLSX from 'xlsx';

/* Utility function for safe date formatting */
const safeFormatDate = (dateString) => {
  try {
    if (!dateString) {
      return 'N/A';
    }
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'N/A';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'N/A';
  }
};

/* Memoized DetailView component */
const DetailView = memo(({ log, onSave }) => {
  const [localNotes, setLocalNotes] = useState(log.notes || '');

  const handleLocalSaveNotes = () => {
    onSave(localNotes);
  };

  return (
    <Box className={styles.modalContent}>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        className={styles.sectionHeader}
      >
        Log Details
      </Typography>

      <Divider className={styles.sectionDivider} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" gutterBottom>
            <strong>IP Address:</strong> {log.ipAddress || 'N/A'}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>User Agent:</strong> {log.userAgent || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Created At:</strong> {safeFormatDate(log.createdAt)}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            <strong>Last Updated:</strong> {safeFormatDate(log.updatedAt)}
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Events
      </Typography>
      <Divider className={styles.sectionDivider} />

      {log.events && log.events.length > 0 ? (
        <Box className={styles.eventsContainer}>
          <table className={styles.eventsTable}>
            <thead>
              <tr>
                <th>Event Type</th>
                <th>Current Page</th>
                <th>Data</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              {log.events.map((event, index) => (
                <tr key={index}>
                  <td>{event.eventType}</td>
                  <td>{event.currentPage || 'N/A'}</td>
                  <td>
                    <pre className={styles.preformattedText}>
                      {JSON.stringify(event.data, null, 2)}
                    </pre>
                  </td>
                  <td>{safeFormatDate(event.timestamp)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      ) : (
        <Typography variant="body2">No events available.</Typography>
      )}

      <Divider className={styles.sectionDivider} />

      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Notes
        </Typography>
        <TextField
          label="Notes"
          fullWidth
          multiline
          value={localNotes}
          onChange={(e) => setLocalNotes(e.target.value)}
          margin="normal"
          variant="outlined"
          rows={4}
          className={styles.notesSection}
          InputProps={{
            style: { backgroundColor: '#2D3748', color: '#CBD5E0' },
          }}
          InputLabelProps={{
            style: { color: '#CBD5E0' },
          }}
        />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleLocalSaveNotes}
            className={styles.saveButton}
            sx={{
              backgroundColor: '#3182CE',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#2B6CB0',
              },
            }}
          >
            Save
          </Button>
          <Button
            variant="text"
            color="inherit"
            onClick={onSave}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
});

const UserActivityLogV2 = () => {
  const [logs, setLogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isLoadingLogDetails, setIsLoadingLogDetails] = useState(false);
  const [currentLog, setCurrentLog] = useState({});
  const [viewingLogId, setViewingLogId] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isDownloading, setIsDownloading] = useState(false);

  // New state variables for search functionality
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  // New state variables for confirmation modal
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [logsToDelete, setLogsToDelete] = useState([]);

  /* Handle opening of the download menu */
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /* Handle closing of the download menu */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /* Handle downloading logs in specified format and period */
  const handleDownloadLogs = async (format, period) => {
    setIsDownloading(true);
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error('No auth token found');
      setIsDownloading(false);
      return;
    }

    try {
      toast.info(`Starting download of logs...`);

      const response = await axios.get('/api/v2/download-logs', {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { period },
      });

      if (!response.data.logs || response.data.logs.length === 0) {
        toast.warn('No logs found for the selected period.');
        setIsDownloading(false);
        return;
      }

      const logsData = response.data.logs;

      if (format === 'txt') {
        // Generate .txt file
        const logsText = generateLogsText(logsData, period);
        const blob = new Blob([logsText], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `user_logs_${period}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (format === 'excel') {
        // Generate .xlsx file
        generateAndDownloadExcel(logsData, period);
      }

      setProgress(0);
      setIsDownloading(false);
      toast.success(`Logs downloaded successfully.`);
    } catch (error) {
      console.error('Error downloading logs:', error);
      toast.error('An error occurred while downloading logs.');
      setProgress(0);
      setIsDownloading(false);
    }
  };

  /* Generate plain text from logs data */
  const generateLogsText = (logsData, period) => {
    return logsData
      .map((log) => {
        try {
          return `
Log ID: ${log._id}
IP Address: ${log.ipAddress || 'N/A'}
User Agent: ${log.userAgent || 'N/A'}
Notes: ${log.notes || ''}
Template Name: ${log.templateName || 'N/A'}
City: ${log.city || 'N/A'}
Region Code: ${log.regionCode || 'N/A'}
Current Page: ${log.currentPage || 'N/A'}
Last Active At: ${safeFormatDate(log.lastActiveAt)}
Created At: ${safeFormatDate(log.createdAt)}
Updated At: ${safeFormatDate(log.updatedAt)}
Events:
${log.events
  .map(
    (event, index) => `
  Event ${index + 1}:
    Event Type: ${event.eventType}
    Current Page: ${event.currentPage || 'N/A'}
    Data: ${JSON.stringify(event.data, null, 2)}
    Timestamp: ${safeFormatDate(event.timestamp)}
`
  )
  .join('\n')}
          `.trim();
        } catch (formatError) {
          console.error('Error formatting log:', formatError);
          return `Error formatting log with ID: ${log._id}`;
        }
      })
      .join('\n\n---------------------------\n\n');
  };

  /* Generate and download Excel file from logs data */
  const generateAndDownloadExcel = (logs, period) => {
    // Prepare data for Excel
    const data = logs.map((log) => {
      return {
        'Log ID': log._id,
        'IP Address': log.ipAddress || 'N/A',
        'User Agent': log.userAgent || 'N/A',
        Notes: log.notes || '',
        'Template Name': log.templateName || 'N/A',
        City: log.city || 'N/A',
        'Region Code': log.regionCode || 'N/A',
        'Current Page': log.currentPage || 'N/A',
        'Last Active At': safeFormatDate(log.lastActiveAt),
        'Created At': safeFormatDate(log.createdAt),
        'Updated At': safeFormatDate(log.updatedAt),
        Events: log.events
          .map(
            (event, index) => `
Event ${index + 1}:
  Event Type: ${event.eventType}
  Current Page: ${event.currentPage || 'N/A'}
  Data: ${JSON.stringify(event.data, null, 2)}
  Timestamp: ${safeFormatDate(event.timestamp)}
`
          )
          .join('\n'),
      };
    });

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Auto-adjust column widths
    const columnWidths = Object.keys(data[0]).map((key) => ({
      wch: Math.max(
        ...data.map((d) => (d[key] ? d[key].toString().length : 10)),
        key.length
      ),
    }));
    worksheet['!cols'] = columnWidths;

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a blob and trigger download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `user_logs_${period}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  /* Fetch logs from the API */
  const fetchLogs = useCallback(async () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error('No auth token found');
      return;
    }
    try {
      const { page, pageSize } = paginationModel;
      const response = await axios.get('/api/v2/logs', {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          page: page + 1,
          limit: pageSize,
        },
      });
      const { logSummaries, totalCount } = response.data;

      const formattedLogs = logSummaries.map((log) => ({
        ...log,
        id: log.logId,
        createdAt: safeFormatDate(log.createdAt),
      }));

      setLogs(formattedLogs);
      setTotalRowCount(totalCount);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  }, [paginationModel]);

  /* Initial fetch and refetch on pagination change */
  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  /* Handle pagination changes */
  const handlePaginationModelChange = useCallback((model) => {
    setPaginationModel(model);
  }, []);

  /* Polling for logs every 60 seconds when modal is closed */
  useEffect(() => {
    const triggerFetchLogs = () => {
      fetchLogs();
    };

    let intervalId;
    if (!isModalOpen) {
      triggerFetchLogs();
      intervalId = setInterval(() => {
        triggerFetchLogs();
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [paginationModel, fetchLogs, isModalOpen]);

  /* Handle opening the modal and fetching log details */
  const handleOpen = async (event, logId) => {
    event.stopPropagation();
    try {
      setIsLoadingLogDetails(true);
      setViewingLogId(logId);
      setIsModalOpen(true);

      const authToken = localStorage.getItem('token');
      const response = await axios.get(`/api/v2/logs/${logId}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });

      setCurrentLog(response.data);
      setOpen(true);
    } catch (error) {
      console.error('Error fetching log details:', error);
      setIsLoadingLogDetails(false);
      setIsModalOpen(false);
    } finally {
      setIsLoadingLogDetails(false);
    }
  };

  /* Handle closing the modal */
  const handleClose = () => {
    setOpen(false);
    setViewingLogId(null);
    setIsModalOpen(false);
  };

  /* Handle saving notes */
  const handleSaveNotes = useCallback(
    async (notes) => {
      if (viewingLogId === null) {
        console.error('No log ID available for saving notes');
        return;
      }

      try {
        const authToken = localStorage.getItem('token');
        await axios.patch(
          `/api/v2/logs/${viewingLogId}/notes`,
          { notes },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        setCurrentLog((prevLog) => ({
          ...prevLog,
          notes,
        }));
        setOpen(false);
        setIsModalOpen(false);
        // Refresh logs to show updated notes
        fetchLogs();
      } catch (error) {
        console.error('Error saving notes:', error);
      }
    },
    [viewingLogId, fetchLogs]
  );

  /* Define columns for the DataGrid */
  const columns = [
    { field: 'ipAddress', headerName: 'IP Address', width: 150 },
    {
      field: 'templateName',
      headerName: 'Template Name',
      width: 200,
      renderCell: (params) => params.value || 'N/A', // Optional: Handle empty values
    },
    { field: 'latestEventType', headerName: 'Latest Event Type', width: 200 },
    { field: 'latestEventData', headerName: 'Latest Event Data', width: 250 },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 200,
      renderCell: (params) => (params.row.notes ? params.row.notes : ''),
    },
    { field: 'updatedAt', headerName: 'Last Updated', width: 220 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => handleOpen(event, params.row.id)}
          disabled={isLoadingLogDetails}
          className={styles.button}
        >
          View
        </Button>
      ),
      sortable: false,
    },
  ];

  /* Handle deleting selected logs */
  const handleDelete = () => {
    // Set the logs to delete and open the confirmation modal
    setLogsToDelete(rowSelectionModel);
    setIsConfirmDeleteOpen(true);
  };

  /* Confirm deletion */
  const confirmDelete = async () => {
    setIsConfirmDeleteOpen(false);
    try {
      const authToken = localStorage.getItem('token');
      await axios.post(
        '/api/v2/delete',
        {
          logIds: logsToDelete,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      toast.success('Selected logs have been deleted successfully.');
      setRowSelectionModel([]); // Clear selection
      fetchLogs();
    } catch (error) {
      console.error('Error deleting logs:', error);
      toast.error('Error deleting logs.');
    }
  };

  /* Cancel deletion */
  const cancelDelete = () => {
    setIsConfirmDeleteOpen(false);
    setLogsToDelete([]);
  };

  /* Handle search functionality */
  const handleSearch = async () => {
    if (!searchText.trim()) {
      // If search text is empty, reset to fetch all logs
      setSearchResults(null);
      fetchLogs(); // Fetch all logs
      return;
    }

    setIsSearching(true);
    setProgress(0);
    try {
      const authToken = localStorage.getItem('token');
      if (!authToken) {
        console.error('No auth token found');
        setIsSearching(false);
        return;
      }

      const { page, pageSize } = paginationModel;

      // Call the new search endpoint
      const response = await axios.get('/api/v2/logs/search', {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          search: searchText,
          page: page + 1,
          limit: pageSize,
        },
      });

      const { logSummaries, totalCount } = response.data;

      const formattedLogs = logSummaries.map((log) => ({
        ...log,
        id: log.logId,
        createdAt: safeFormatDate(log.createdAt),
      }));

      setLogs(formattedLogs);
      setTotalRowCount(totalCount);
      setSearchResults(formattedLogs);
    } catch (error) {
      console.error('Error during search:', error);
      toast.error('Error during search.');
    } finally {
      setIsSearching(false);
      setProgress(0);
    }
  };

  /* Clear search results */
  const clearSearch = () => {
    setSearchText('');
    setSearchResults(null);
    setProgress(0);
  };

  return (
    <div
      className={styles.UserActivityLog}
      style={{
        height: 600,
        width: '100%',
        backgroundColor: '#1B2430',
        color: '#E3E3E3',
        padding: '20px',
        borderRadius: '8px',
        position: 'relative',
      }}
    >
      {/* Search and Action Buttons */}
      <div
        className={styles.mainButtons}
        style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}
      >
        {/* Search Input */}
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className={styles.searchInput}
          style={{ marginRight: '10px' }}
          InputLabelProps={{
            style: { color: '#E3E3E3' },
          }}
          InputProps={{
            style: { color: '#E3E3E3' },
          }}
        />
        {/* Search Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={isSearching}
          sx={{
            backgroundColor: '#2B6CB0',
            '&:hover': {
              backgroundColor: '#2C5282',
            },
          }}
          className={styles.button}
        >
          Search
        </Button>
        {/* Clear Search Button */}
        {searchResults && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={clearSearch}
            style={{ marginLeft: '10px' }}
            sx={{
              borderColor: '#E53E3E',
              color: '#E53E3E',
              '&:hover': {
                borderColor: '#C53030',
                backgroundColor: 'rgba(229, 62, 62, 0.1)',
              },
            }}
            className={styles.button}
          >
            Clear Search
          </Button>
        )}

        {/* Spacer */}
        <div style={{ flexGrow: 1 }} />

        {/* Download Logs Button */}
        <Tooltip title="Download logs">
          <IconButton
            aria-label="download"
            aria-controls="download-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            className={styles.button}
            style={{
              color: '#ffffff',
              backgroundColor: '#2d3748',
              border: '1px solid #4A5568',
              borderRadius: '5px',
              padding: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            }}
            disabled={isDownloading || isSearching}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>

        {/* Download Menu */}
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '25ch',
              backgroundColor: '#2d3748',
              color: '#ffffff',
            },
          }}
        >
          {/* 24 Hours */}
          <MenuItem
            onClick={() => {
              handleDownloadLogs('txt', '24h');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 24 Hours - .txt
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('excel', '24h');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 24 Hours - .xlsx
          </MenuItem>
          {/* 7 Days */}
          <MenuItem
            onClick={() => {
              handleDownloadLogs('txt', '7d');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 7 Days - .txt
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('excel', '7d');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 7 Days - .xlsx
          </MenuItem>
          {/* 1 Month */}
          <MenuItem
            onClick={() => {
              handleDownloadLogs('txt', '1m');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 1 Month - .txt
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('excel', '1m');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 1 Month - .xlsx
          </MenuItem>
        </Menu>

        {/* Delete Logs Button */}
        <Tooltip title="Delete selected rows">
          <span>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
              disabled={
                rowSelectionModel.length === 0 || isDownloading || isSearching
              }
              style={{
                color:
                  rowSelectionModel.length === 0 ||
                  isDownloading ||
                  isSearching
                    ? 'rgba(255, 255, 255, 0.3)'
                    : 'rgba(255, 255, 255, 0.7)',
                borderColor:
                  rowSelectionModel.length === 0 ||
                  isDownloading ||
                  isSearching
                    ? 'rgba(255, 255, 255, 0.12)'
                    : 'rgba(255, 255, 255, 0.3)',
              }}
              sx={{
                '&:hover': {
                  borderColor:
                    rowSelectionModel.length === 0 ||
                    isDownloading ||
                    isSearching
                      ? 'rgba(255, 255, 255, 0.12)'
                      : 'rgba(255, 255, 255, 0.5)',
                },
              }}
              className={styles.button}
            >
              DELETE
            </Button>
          </span>
        </Tooltip>
      </div>

      {/* Loading Indicators */}
      {isSearching && (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
          <LinearProgress variant="indeterminate" />
        </Box>
      )}

      {isDownloading && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1000,
            textAlign: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: '10px',
            borderRadius: '4px',
          }}
        >
          <CircularProgress color="secondary" />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: '#ffffff', marginTop: '10px' }}
          >
            Downloading logs, please wait...
          </Typography>
        </Box>
      )}

      {/* DataGrid for Logs */}
      <DataGrid
        rows={searchResults || logs}
        columns={columns}
        paginationModel={searchResults ? undefined : paginationModel}
        onPaginationModelChange={
          searchResults ? undefined : handlePaginationModelChange
        }
        pageSizeOptions={[5, 10, 25]}
        rowCount={searchResults ? searchResults.length : totalRowCount}
        pagination={!searchResults}
        paginationMode={searchResults ? undefined : 'server'}
        checkboxSelection
        onRowSelectionModelChange={setRowSelectionModel}
        selectionModel={rowSelectionModel}
        disableSelectionOnClick
        sx={{
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0,
          '& .MuiDataGrid-root': {
            backgroundColor: '#1B2430',
            color: '#E3E3E3',
            border: 'none',
            '&:after': {
              content: 'none',
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#242F3E',
            color: '#ffffff',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            border: 'none',
          },
          '& .MuiDataGrid-row': {
            borderBottom: 'none',
            '&:nth-of-type(odd)': {
              backgroundColor: '#192534',
            },
            '&:nth-of-type(even)': {
              backgroundColor: '#192738',
            },
            '&.Mui-selected': {
              backgroundColor: '#4A5568',
            },
            '&:hover': {
              backgroundColor: '#20303d',
            },
          },
          '& .MuiDataGrid-cell': {
            borderRight: 'none',
            color: '#ffffff',
          },
          '& .MuiDataGrid-overlay': {
            backgroundColor: '#242F3E',
          },
          '& .MuiDataGrid-pagination': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-sortIcon': {
            color: '#ffffff',
          },
          '& .MuiTablePagination-root': {
            color: '#ffffff',
            borderTop: 'none',
          },
          '& .MuiInputBase-root': {
            color: '#ffffff',
            '& .MuiSvgIcon-root': {
              color: '#ffffff',
            },
          },
          '& .MuiDataGrid-menuIcon': {
            color: '#ffffff',
          },
          '& .MuiButtonBase-root': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            borderBottom: 'none',
            color: '#ffffff',
          },
          '& .MuiDataGrid-sortIcon': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#ffffff',
          },
        }}
      />

      {/* Modal for Log Details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={styles.modal}
      >
        {isLoadingLogDetails ? (
          <Box className={styles.modalContent}>
            <Typography variant="h6">Loading...</Typography>
          </Box>
        ) : (
          currentLog && <DetailView log={currentLog} onSave={handleSaveNotes} />
        )}
      </Modal>

      {/* Confirmation Modal for Deletion */}
      <Dialog
        open={isConfirmDeleteOpen}
        onClose={cancelDelete}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
        PaperProps={{
          sx: {
            backgroundColor: '#1A202C', // Dark background
            color: '#CBD5E0', // Light text
          },
        }}
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-delete-dialog-description"
            sx={{ color: '#ffffff' }} // Set text color to white
          >
            {logsToDelete.length === 1
              ? 'Are you sure you want to delete the selected log? This action cannot be undone.'
              : `Are you sure you want to delete the ${logsToDelete.length} selected logs? This action cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cancelDelete}
            sx={{
              color: '#3182CE',
              '&:hover': {
                backgroundColor: 'rgba(49, 130, 206, 0.1)',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            sx={{
              color: '#E53E3E',
              '&:hover': {
                backgroundColor: 'rgba(229, 62, 62, 0.1)',
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserActivityLogV2;

/* UserActivityLogV2.module.css */

/* ... (existing CSS remains unchanged) ... */

/* Optional: Additional styles for the confirmation dialog can be added here if needed */
