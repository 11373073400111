import React, { useContext, useEffect, useState } from 'react';
import styles from './Dashboard.module.css';
import SidebarContext from '../../contexts/SidebarContext';
import Sidebar from '../Sidebar/Sidebar';
import StatisticsCards from './StatisticsCards/StatisticsCards';
import UserActivityLog from './UserActivityLog/UserActivityLog';
import Changelog from './Changelog/Changelog';

const Dashboard = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [dashboardData, setDashboardData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const worker = new Worker('dashboardStatsFetcherWorker.js');
    const token = localStorage.getItem('token');

    worker.onmessage = (event) => {
      if (event.data.error) {
        setError(event.data.error);
      } else {
        setDashboardData(event.data);
      }
      setLoading(false);
    };

    worker.onerror = (event) => {
      setError(new Error(`Worker error: ${event.message}`));
      setLoading(false);
    };

    // Fetch immediately and then set an interval
    worker.postMessage({ action: 'FETCH_DASHBOARD_STATS', token });
    const intervalId = setInterval(() => {
      worker.postMessage({ action: 'FETCH_DASHBOARD_STATS', token });
    }, 300000); // Fetch every 10 seconds

    // Cleanup function
    return () => {
      clearInterval(intervalId);
      worker.terminate();
    };
  }, []);

  if (loading) {
  }

  if (error) {
  }

  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <main className={`${styles.mainContent} ${!isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <StatisticsCards stats={dashboardData} />
        <Changelog />
      </main>
    </div>
  );
};

export default Dashboard;
