import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './PaymentModal.module.css';

const PaymentModal = ({ isOpen, onClose, selectedPlan }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('bitcoin');
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes in seconds

  useEffect(() => {
    if (paymentDetails) {
      const interval = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (!isOpen) {
      setPaymentDetails(null);
      setPaymentStatus('');
      setIsLoading(false);
      setSelectedCurrency('bitcoin');
      setTimeLeft(1800);
    }
  }, [isOpen]);

  useEffect(() => {
    if (timeLeft === 0) {
      setPaymentStatus('Time expired. Please create a new payment request.');
    }
  }, [timeLeft]);

  useEffect(() => {
    if (paymentDetails) {
      const interval = setInterval(() => {
        checkPaymentStatus();
      }, 60000); // Check payment status every minute

      return () => clearInterval(interval);
    }
  }, [paymentDetails]);

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
    setPaymentDetails(null);
    setPaymentStatus('');
    setTimeLeft(1800);
  };

  const handlePayment = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/create-charge', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          currency: selectedCurrency, 
          amount: selectedPlan.price, 
          email: 'customer@example.com',
          orderId: 'ORDER_ID',
          description: `Payment for ${selectedPlan.duration} plan`
        }),
      });
      const data = await response.json();
      setPaymentDetails(data);
      setPaymentStatus('Awaiting payment...');
    } catch (error) {
      console.error('Error creating charge:', error);
      setPaymentStatus('Error creating payment. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const checkPaymentStatus = async () => {
    if (!paymentDetails) return;

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/payment-status?trackId=${paymentDetails.trackId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const statusData = await response.json();
      if (statusData.status === 'Confirming') {
        setPaymentStatus('Payment confirming...');
      } else if (statusData.status === 'Paid') {
        setPaymentStatus('Payment confirmed!');
        toast.success('Payment confirmed! Your membership is now active.');
      } else {
        setPaymentStatus('Payment not yet confirmed. Please try again.');
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      setPaymentStatus('Error checking payment status. Please try again.');
    }
  };

  const copyToClipboard = async (text) => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
        console.error('Error copying to clipboard:', error);
      }
    } else {
      console.warn('Clipboard API not available');
    }
  };
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    isOpen && (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <ToastContainer />
          <button className={styles.closeButton} onClick={onClose}>X</button>
          <h2 className={styles.title}>Select Cryptocurrency</h2>
          <select 
            value={selectedCurrency} 
            onChange={handleCurrencyChange} 
            className={styles.select}
          >
            <option value="bitcoin">Bitcoin</option>
            <option value="ethereum">Ethereum</option>
            <option value="litecoin">Litecoin</option>
            <option value="monero">Monero</option>
          </select>
          <button 
            onClick={handlePayment} 
            disabled={isLoading} 
            className={styles.proceedButton}
          >
            {isLoading ? <div className={styles.spinner}></div> : 'Proceed to Payment'}
          </button>
          {paymentDetails && (
            <div className={styles.paymentDetails}>
              <div className={styles.detailItem}>
                <p><strong>Send {paymentDetails.payAmount} {selectedCurrency} to:</strong></p>
                <button 
                  className={styles.copyButton} 
                  onClick={() => copyToClipboard(paymentDetails.payAmount)}
                  title="Copy Amount"
                >
                  <FaCopy />
                </button>
              </div>
              <div className={styles.detailItem}>
              <p className={styles.address}>{paymentDetails.address}</p>
              <button 
                className={styles.copyButton} 
                onClick={() => copyToClipboard(paymentDetails.address)}
                title="Copy Address"
              >
                <FaCopy />
              </button>
            </div>

              <p className={styles.usdEquivalent}>USD equivalent: ${selectedPlan.price}</p>
              <p className={styles.timer}>Time left: {formatTime(timeLeft)}</p>
            </div>
          )}
          {paymentStatus && <p className={styles.paymentStatus}>{paymentStatus}</p>}
        </div>
      </div>
    )
  );
};

export default PaymentModal;
