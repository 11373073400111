// VisitorManagerV2.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import styles from './VisitorManagerV2.module.css';

const VisitorManagerV2 = ({ logId }) => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isSendingAction, setIsSendingAction] = useState(false);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`/api/v2/logs/${logId}/pages`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setPages(response.data.pages);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching pages:', err);
        setError('Failed to load pages. Please try again.');
        setLoading(false);
      }
    };

    fetchPages();
  }, [logId]);

  const handlePageClick = async (pageName) => {
    setIsSendingAction(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `/api/v2/logs/${logId}/control-action`,
        { pageName },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        toast.success(`Visitor will be redirected to ${pageName}`);
      } else {
        toast.error('Failed to send control action.');
      }
    } catch (error) {
      console.error('Error sending control action:', error);
      toast.error('Error sending control action.');
    } finally {
      setIsSendingAction(false);
    }
  };

  if (loading) {
    return (
      <Box className={styles.visitorManagerContainer}>
        <CircularProgress size={24} />
        <Typography variant="body2" style={{ marginLeft: '8px' }}>
          Loading pages...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={styles.visitorManagerContainer}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className={styles.visitorManagerContainer}>
      <Typography variant="h6" gutterBottom>
        Visitor Manager
      </Typography>
      <Box className={styles.pagesButtonContainer}>
        {pages.map((page) => (
          <Button
            key={page.pageId}
            variant="contained"
            color="primary"
            className={styles.pageButton}
            style={{ margin: '8px' }}
            onClick={() => handlePageClick(page.name)}
            disabled={isSendingAction}
          >
            {page.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default VisitorManagerV2;