import React, { useState, useEffect, useRef } from 'react';
import styles from './StepSixContent.module.css';
import { toast } from 'react-toastify';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa';

const StepSixContent = ({ formData, onNext, onBack, onCancel, closeModal, isStandaloneMode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [installationStatus, setInstallationStatus] = useState('Not started');
  const [statusLogs, setStatusLogs] = useState(''); // State for storing logs
  const logBoxRef = useRef(null); // Ref for log box to scroll down automatically

  const FeatureStatus = ({ isEnabled }) => (
    isEnabled ? <FaCheckCircle className={styles.featureEnabled} /> : <FaTimesCircle className={styles.featureDisabled} />
  );

  const handleInstall = async () => {
    setIsLoading(true);
    setInstallationStatus('Installation in progress...');
    setStatusLogs(''); // Reset logs when starting a new installation

    try {
      const response = await fetch(`/api/setup-vps`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          ipAddress: formData.ipAddress,
          sshUser: formData.username,
          sshPassword: formData.password,
          domainName: formData.domainName,
          installSSL: formData.sslCertificate,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        pollInstallationStatus(data.jobId);
      } else {
        setInstallationStatus('Installation failed!');
        setStatusLogs(data.message); // Set the failure message in logs
        toast.error(`Installation failed: ${data.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Installation error:', error);
      setInstallationStatus('Installation error!');
      setStatusLogs('Installation error. Please try again later.');
      toast.error('Installation error. Please try again later.');
      setIsLoading(false);
    }
  };

  const pollInstallationStatus = (jobId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(`/api/setup-vps/status/${jobId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const data = await response.json();

        // Append non-empty log data
        if (data.log && data.log.trim() !== '') {
          setStatusLogs((prevLogs) => `${prevLogs}\n${data.log}`);
        }

        // Auto-scroll to the bottom of the log box when logs are updated
        if (logBoxRef.current) {
          logBoxRef.current.scrollTop = logBoxRef.current.scrollHeight;
        }

        // Check if the installation is completed
        if (data.status === 'Completed') {
          // Extract all occurrences of 'failed=x' and take the last one
          const failedMatches = data.log.match(/failed=(\d+)/g);
          const lastFailedMatch = failedMatches ? failedMatches[failedMatches.length - 1] : null;
          const failedCount = lastFailedMatch ? parseInt(lastFailedMatch.split('=')[1], 10) : null;

          if (failedCount === 0) {
            clearInterval(intervalId);
            setInstallationStatus('Installation complete!');
            toast.success('Installation complete!');
            setIsLoading(false);
          } else if (failedCount > 0) {
            clearInterval(intervalId);
            setInstallationStatus('Installation failed!');
            
            // Extract specific error messages
            const errorLines = data.log.split('\n').filter(line => line.includes('FAILED!') || line.includes('Errno'));
            const detailedError = errorLines.join('\n') || 'Some tasks failed.';
            
            setStatusLogs((prevLogs) => `${prevLogs}\nError: ${detailedError}`);
            toast.error(`Installation failed: ${detailedError}`);
            setIsLoading(false);
          } else {
            // Handle cases where 'failed=' is not found or invalid
            clearInterval(intervalId);
            setInstallationStatus('Installation status unclear.');
            setStatusLogs((prevLogs) => `${prevLogs}\nError: Unable to determine installation status.`);
            toast.error('Installation status unclear. Please check the logs.');
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.error('Polling error:', error);
        clearInterval(intervalId);
        setInstallationStatus('Installation error!');
        setStatusLogs('Installation error. Please try again later.');
        toast.error('Installation error. Please try again later.');
        setIsLoading(false);
      }
    }, 5000); // Poll every 5 seconds
  };

  const handleBack = () => onBack && onBack();
  const handleCancel = () => onCancel && onCancel();

  return (
    <>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
      <div className={styles.stepSixContent}>
        <h1 className={styles.header}>Installation Summary</h1>
        <div className={styles.summaryCard}>
          <div className={styles.summaryList}>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>Domain:</span>
              <span className={styles.summaryValue}>{formData.domainName}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>IP Address: </span>
              <span className={styles.summaryValue}>{formData.ipAddress}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>Template: </span>
              <span className={styles.summaryValue}>{formData.templateName}</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>Captcha:</span>
              <FeatureStatus isEnabled={formData.captcha} />
            </div>
          </div>
          
          <div className={styles.installSection}>
            <button 
              className={styles.installButton} 
              onClick={handleInstall} 
              disabled={isLoading}
            >
              {isLoading ? <FaSpinner className={styles.spinner} /> : 'Install'}
            </button>
          </div>
          
          <div className={styles.statusSection}>
            <span className={styles.statusLabel}>Status:</span>
            <span className={styles.status}>{installationStatus}</span>
          </div>
          
          <div className={styles.outputBox}>
            <h3>Installation Logs</h3>
            <pre className={styles.logs} ref={logBoxRef}>{statusLogs || 'No logs available yet.'}</pre>
          </div>
        </div>

        <div className={styles.navigationButtons}>
          {installationStatus === 'Installation complete!' ? (
            <button className={styles.backButton} onClick={closeModal} disabled={isLoading}>Close</button>
          ) : (
            <button className={styles.backButton} onClick={handleBack} disabled={isLoading}>Back</button>
          )}
        </div>
      </div>
    </>
  );
};

export default StepSixContent;
