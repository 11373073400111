import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar/Sidebar';
import SidebarContext from '../../contexts/SidebarContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faClock } from '@fortawesome/free-solid-svg-icons';
import styles from './Settings.module.css';
import TelegramSettingsCard from './TelegramSettingsCard';

const SettingsUser = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [membershipStatus, setMembershipStatus] = useState({ type: "Standard", daysLeft: 0 });
  const [latestPayment, setLatestPayment] = useState({ date: "", currency: "", amount: 0 });
  const [loginActivity, setLoginActivity] = useState([]);
  const [publicAddress, setPublicAddress] = useState("");
  const [isRechecking, setIsRechecking] = useState(false); // State for re-checking status

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUserSettings = async () => {
    try {
      const response = await fetch('/api/user-settings', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setMembershipStatus(data.membershipStatus);
        setLatestPayment(data.latestPayment);
        setLoginActivity(data.loginActivity);
        setPublicAddress(data.publicAddress); // Set the public address
      } else {
        console.error('Failed to fetch user settings');
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
  };

  const recheckPayments = async () => {
    setIsRechecking(true);
    try {
      const response = await fetch('/api/recheck-payments', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Re-check completed:', data);

        // Display appropriate toast message based on the response
        if (data.message === 'Membership successfully granted!') {
          toast.success('Membership successfully granted!');
        } else if (data.message === 'No new payments found. All payments were already processed.') {
          toast.info('No new payments found. All payments were already processed.');
        } else if (data.message === 'No payments found.') {
          toast.warning('No payments found.');
        }

        fetchUserSettings(); // Refresh user settings after re-check
      } else {
        toast.error('Failed to re-check payments.');
        console.error('Failed to re-check payments');
      }
    } catch (error) {
      toast.error('Error occurred during payment re-check.');
      console.error('Error re-checking payments:', error);
    } finally {
      setIsRechecking(false);
    }
  };

  return (
    <div className={styles.settingsPage}>
      <Sidebar />
      <div className={`${styles.settingsContent} ${!isSidebarOpen ? styles.settingsContentExpanded : ''}`}>
        <h1 className={styles.title}>Account Settings</h1>
        <div className={styles.cardContainer}>
          {/* Membership Card */}
          <div className={styles.card}>
            <FontAwesomeIcon icon={faUserShield} className={styles.cardIcon} />
            <div className={styles.cardDetails}>
              <h2>Membership Status</h2>
              <p>Type: <strong className={styles.membershipType}>{membershipStatus.type}</strong></p>
              <p>Days Left: <strong>{membershipStatus.daysLeft}</strong></p>
              <p>Public Address: <strong>{publicAddress}</strong></p> {/* Display the public address */}
            </div>
          </div>

          {/* Payment History Card */}
          <div className={styles.card}>
            <div className={styles.cardContent}>
              <h2>Latest Payments</h2>
              <table className={styles.cryptoPaymentsTable}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Currency</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{latestPayment.date}</td>
                    <td>{latestPayment.currency}</td>
                    <td>{latestPayment.amount}</td>
                  </tr>
                </tbody>
              </table>
              {/* Re-check payments button */}
              <button 
                className={styles.recheckButton} 
                onClick={recheckPayments} 
                disabled={isRechecking}
              >
                {isRechecking ? 'Re-checking...' : 'Re-check Payments'}
              </button>
            </div>
          </div>

          {/* Login Activity Card */}
          <div className={styles.card}>
            <FontAwesomeIcon icon={faClock} className={styles.cardIcon} />
            <div className={styles.cardContent}>
              <h2>Login Activity</h2>
              <div className={styles.loginActivityTable}>
                <div className={styles.tableHeader}>
                  <span>Date</span>
                </div>
                {loginActivity.map((login, index) => (
                  <div className={styles.tableRow} key={index}>
                    <span>{new Date(login.date).toLocaleString()}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Telegram Settings Card */}
          <TelegramSettingsCard />
        </div>
      </div>
    </div>
  );
};

export default SettingsUser;
