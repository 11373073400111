// frontend/src/components/PageBuilder/TemplateSelection.js

import React from 'react';
import { Button, Typography } from '@mui/material';
import styles from './TemplateSelection.module.css';

const TemplateSelection = ({
  userTemplates,
  selectUserTemplate,
  createBlankTemplate,
  userSettings,
}) => {
  return (
    <div className={styles.templateSelection}>
      <h2>Select a Template</h2>
      <div className={styles.selectionOptions}>
        {/* User's Saved Templates Section */}
        <div className={styles.savedTemplatesSection}>
          <h3>Your Saved Templates</h3>
          {userTemplates.length > 0 ? (
            <div className={styles.templatesContainer}>
              {userTemplates.map((template) => (
                <div key={template._id || template.id} className={styles.templateItem}>
                  <h4>{template.name}</h4>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => selectUserTemplate(template)}
                  >
                    Select Template
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            <p>You have no saved templates.</p>
          )}
          {/* Create Blank Template Button */}
          <div className={styles.createBlankContainer}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={createBlankTemplate}
              className={styles.createBlankButton}
              disabled={!userSettings} // Disable button until userSettings are loaded
            >
              Create Blank Template
            </Button>
          </div>
        </div>

        {/* Community Templates Section */}
        <div className={styles.communityTemplatesSection}>
          <h3>Community Templates</h3>
          <div className={styles.comingSoonContainer}>
            <Typography variant="h6" className={styles.comingSoonText}>
              Coming Soon!
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateSelection;
