// frontend/src/components/PageBuilder/modals/DeploymentModal.js

import React from 'react';
import {
  Modal,
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from '@mui/material';
import styles from './DeploymentModal.module.css';

const DeploymentModal = ({
  isOpen,
  onClose,
  deployData,
  onDeployInputChange,
  onDeployTemplate,
  isDeploying,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        className={styles.modalContent}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: '#1E242A',
          color: '#ffffff',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <h2 style={{ color: '#ffffff' }}>Deploy to Your Server</h2>
        <TextField
          label="IP Address"
          name="ipAddress"
          value={deployData.ipAddress}
          onChange={onDeployInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{
            style: { color: '#ffffff' },
            sx: { borderColor: '#ffffff' },
          }}
        />
        <TextField
          label="Username"
          name="username"
          value={deployData.username}
          onChange={onDeployInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{
            style: { color: '#ffffff' },
            sx: { borderColor: '#ffffff' },
          }}
        />
        <TextField
          label="Password"
          name="password"
          type="password"
          value={deployData.password}
          onChange={onDeployInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{
            style: { color: '#ffffff' },
            sx: { borderColor: '#ffffff' },
          }}
        />
        <TextField
          label="Template Name"
          name="templateName"
          value={deployData.templateName}
          onChange={onDeployInputChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ style: { color: '#ffffff' } }}
          InputProps={{
            style: { color: '#ffffff' },
            sx: { borderColor: '#ffffff' },
          }}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend" style={{ color: '#ffffff' }}>
            Operating System
          </FormLabel>
          <RadioGroup
            aria-label="osVersion"
            name="osVersion"
            value={deployData.osVersion}
            onChange={onDeployInputChange}
          >
            <FormControlLabel
              value="ubuntu20.04"
              control={<Radio style={{ color: '#ffffff' }} />}
              label="Ubuntu 20.04"
            />
            <FormControlLabel
              value="ubuntu22.04"
              control={<Radio style={{ color: '#ffffff' }} />}
              label="Ubuntu 22.04"
            />
          </RadioGroup>
        </FormControl>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px',
            marginTop: '20px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onDeployTemplate}
            disabled={isDeploying}
            startIcon={
              isDeploying ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            {isDeploying ? 'Deploying...' : 'Deploy'}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={onClose}
            disabled={isDeploying}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeploymentModal;