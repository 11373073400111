import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './TelegramSettingsCard.module.css';

const TelegramSettingsCard = () => {
  const [inputLinkingCode, setInputLinkingCode] = useState('');
  const [notifyOnNewLog, setNotifyOnNewLog] = useState(false);
  const [notifyOnVisit, setNotifyOnVisit] = useState(false);
  const [telegramVerified, setTelegramVerified] = useState(false);

  useEffect(() => {
    const checkVerificationStatus = async () => {
      try {
        const response = await fetch('/api/check-telegram-verification', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setTelegramVerified(data.telegramVerified);
          setNotifyOnNewLog(data.notifyOnNewLog);
          setNotifyOnVisit(data.notifyOnVisit);
        } else {
          toast.error('Failed to check Telegram verification status', { autoClose: 3000 });
        }
      } catch (error) {
        toast.error('Error checking Telegram verification status', { autoClose: 3000 });
      }
    };

    checkVerificationStatus();
  }, []);

  const verifyLinkingCode = async () => {
    try {
      const response = await fetch('/api/link-telegram', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ telegramKey: inputLinkingCode })
      });

      if (response.ok) {
        setTelegramVerified(true);
        toast.success('Telegram account linked successfully', { autoClose: 3000 });
      } else {
        toast.error('Invalid linking code', { autoClose: 3000 });
      }
    } catch (error) {
      toast.error('Error verifying linking code', { autoClose: 3000 });
    }
  };

  const saveSettings = async () => {
    try {
      const response = await fetch('/api/update-notifications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ notifyOnNewLog, notifyOnVisit })
      });

      if (response.ok) {
        toast.success('Settings saved successfully', { autoClose: 3000 });
      } else {
        toast.error('Failed to save settings', { autoClose: 3000 });
      }
    } catch (error) {
      toast.error('Error saving settings', { autoClose: 3000 });
    }
  };

  const unlinkTelegram = async () => {
    try {
      const response = await fetch('/api/unlink-telegram', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        setTelegramVerified(false);
        setNotifyOnNewLog(false);
        setNotifyOnVisit(false);
        toast.success('Telegram account unlinked successfully', { autoClose: 3000 });
      } else {
        toast.error('Failed to unlink Telegram account', { autoClose: 3000 });
      }
    } catch (error) {
      toast.error('Error unlinking Telegram account', { autoClose: 3000 });
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon={faTelegramPlane} className={styles.telegramIcon} />
      </div>
      <div className={styles.cardContent}>
        <h2 className={styles.title}>Telegram Settings</h2>
        <p className={styles.description}>Link your Telegram account to receive notifications:</p>
        {!telegramVerified ? (
          <>
           <p className={styles.instructions}>
            To get started, add our bot 
            <a 
              href="https://t.me/SheBytes_bot" 
              style={{ 
                color: '#00BFFF', 
                textDecoration: 'none', 
                fontWeight: 'bold' 
              }}
              onMouseEnter={e => e.target.style.color = '#1E90FF'}
              onMouseLeave={e => e.target.style.color = '#00BFFF'}
              target="_blank" 
              rel="noopener noreferrer"
            >
              <strong> @SheBytes_bot </strong>
            </a> 
            and message <strong>/start</strong>. Then, enter the code sent by the bot below.
          </p>
            <div className={styles.inputContainer}>
              <input
                type="text"
                value={inputLinkingCode}
                onChange={(e) => setInputLinkingCode(e.target.value)}
                placeholder="Enter the code sent by the bot"
                className={styles.input}
              />
              <button onClick={verifyLinkingCode} className={styles.verifyButton}>Verify Code</button>
            </div>
          </>
        ) : (
          <>
            <div className={styles.checkboxContainer}>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={notifyOnNewLog}
                  onChange={(e) => setNotifyOnNewLog(e.target.checked)}
                />
                when a new log is coming
              </label>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={notifyOnVisit}
                  onChange={(e) => setNotifyOnVisit(e.target.checked)}
                />
               when a user browses one of my websites
              </label>
            </div>
            <div className={styles.buttonContainer}>
              <button onClick={saveSettings} className={styles.saveButton}>Save Settings</button>
              <button onClick={unlinkTelegram} className={styles.unlinkButton}>Unlink Telegram Account</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TelegramSettingsCard;
