// src/components/Sidebar/Sidebar.jsx

import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  FaHome, 
  FaServer, 
  FaEye, 
  FaCog, 
  FaSignOutAlt, 
  FaBars, 
  FaBook, 
  FaIdCard, 
  FaFileAlt 
} from 'react-icons/fa';
import { BsBank2 } from "react-icons/bs";
import styles from './Sidebar.module.css';
import SidebarContext from '../../contexts/SidebarContext';
import { useAuth } from '../../AuthContext/AuthContext';

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen } = useContext(SidebarContext);
  const { logoutUser } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    const isMobileWidth = () => window.innerWidth < 780;

    const handleRouteChange = () => {
      if (isMobileWidth()) {
        setSidebarOpen(false);
      }
    };

    handleRouteChange();

    return () => {
      // Cleanup if necessary
    };
  }, [location, setSidebarOpen]);

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <div className={`${styles.sidebar} ${!isSidebarOpen ? styles.sidebarClosed : styles.sidebarOpen}`}>
      <button className={styles.toggleButton} onClick={toggleSidebar}>
        <FaBars />
      </button>
      <div className={styles.menuItems}>
        <Link to="/dashboard" className={`${styles.link} ${location.pathname === '/dashboard' ? styles.active : ''}`}>
          <FaHome className={styles.icon} />
          <span className={styles.linkText}>Home</span>
        </Link>
        <Link to="/membership" className={`${styles.link} ${location.pathname === '/membership' ? styles.active : ''}`}>
          <FaIdCard className={styles.icon} />
          <span className={styles.linkText}>Membership</span>
        </Link>
        <Link to="/settings" className={`${styles.link} ${location.pathname === '/settings' ? styles.active : ''}`}>
          <FaCog className={styles.icon} />
          <span className={styles.linkText}>Settings</span>
        </Link>
        <Link to="/faq-tutorial" className={`${styles.link} ${location.pathname === '/faq-tutorial' ? styles.active : ''}`}>
          <FaBook className={styles.icon} />
          <span className={styles.linkText}>FAQ/Tutorial</span>
        </Link>
      </div>
      
      {/* Separator handled by CSS */}
      
      <div className={styles.menuItems}>
        <Link to="/servers" className={`${styles.link} ${location.pathname === '/servers' ? styles.active : ''}`}>
          <FaServer className={styles.icon} />
          <span className={styles.linkText}>Servers</span>
        </Link>
        <Link to="/results" className={`${styles.link} ${location.pathname === '/results' ? styles.active : ''}`}>
          <BsBank2 className={styles.icon} />
          <span className={styles.linkText}>Results</span>
        </Link>
        <Link to="/liveAction" className={`${styles.link} ${location.pathname === '/liveAction' ? styles.active : ''}`}>
          <FaEye className={styles.icon} />
          <span className={styles.linkText}>OTP-BYTE</span>
        </Link>
      </div>
      
      {/* New Section for Page Builder */}
      <div className={styles.menuItems}>
        <Link to="/page-builder" className={`${styles.link} ${location.pathname === '/page-builder' ? styles.active : ''}`}>
          <FaFileAlt className={styles.icon} />
          <span className={styles.linkText}>Page Builder</span>
        </Link>
        {/* New Link for Guide */}
        <Link to="/guide" className={`${styles.link} ${location.pathname === '/guide' ? styles.active : ''}`}>
          <FaBook className={styles.icon} />
          <span className={styles.linkText}>Guide</span>
        </Link>
        {/* New Link for Servers V2 */}
        <Link to="/servers-v2" className={`${styles.link} ${location.pathname === '/servers-v2' ? styles.active : ''}`}>
          <FaServer className={styles.icon} />
          <span className={styles.linkText}>Servers V2</span>
        </Link>

        <Link to="/results-v2" className={`${styles.link} ${location.pathname === '/results-v2' ? styles.active : ''}`}>
          <BsBank2 className={styles.icon} />
          <span className={styles.linkText}>Results V2</span>
        </Link>

        <Link to="/liveAction-v2" className={`${styles.link} ${location.pathname === '/liveAction-v2' ? styles.active : ''}`}>
          <FaEye className={styles.icon} />
          <span className={styles.linkText}>OTP-BYTE V2</span>
        </Link>
      </div>
      
      <div className={styles.logoutSection}>
        <button className={styles.logoutButton} onClick={handleLogout}>
          <FaSignOutAlt className={styles.icon} />
          <span className={styles.linkText}>Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
