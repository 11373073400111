// StepOneContent.js
import React, {forwardRef, useState, useEffect} from 'react';
import { toast } from 'react-toastify';
import styles from './StepOneContent.module.css';
import { useNavigate } from 'react-router-dom';

const StepOneContent = ({ onInputChange, formData, onNext, selectedTemplate, handleTemplateSelection, onClose }) => {

  const [inputErrors, setInputErrors] = useState({
    ipAddress: '', port: '', username: '', password: '', domainName: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Clear the local storage key "localFormData" when the component mounts
    localStorage.removeItem('localFormData');
  }, []);

  const createServer = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token'); 
    if (!token) {
      setIsLoading(false);
      return;
    }
  
    const serverData = {
      ipAddress: formData.ipAddress,
      port: formData.port,
      username: formData.username,
      password: formData.password,
      domainName: formData.domainName,
      templateName: selectedTemplate,
      installSSL: formData.sslCertificate,
      captcha: formData.captcha,
      pageAnalytics: formData.analytics,
    };
  
    try {
      const response = await fetch("/api/servers", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(serverData)
      });
  
      const data = await response.json(); 
  
      if (response.ok) {
        toast.success('Server created successfully');
        handleTemplateSelection(selectedTemplate, data.availableSteps, data.category); 
        onNext(); // Proceed to the next step
      } else if (response.status === 403) {
        toast.error(`${data.message}`);
        navigate("/membership"); // Redirect to membership page
      } else {
        // Handle other server responses with a non-ok status
        toast.error(`${data.message}`);
      }
    } catch (error) {
      // Handle network errors
      toast.error(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  

  // Validation for each input field
  const validateInput = (name, value) => {
    const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const domainRegex = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/;
    
    const validations = {
      ipAddress: { isValid: ipRegex.test(value), message: 'Invalid IP address.' },
      port: { isValid: /^(?:\d{1,5})$/.test(value) && parseInt(value, 10) <= 65535, message: 'Port number must be between 0 and 65535' },
      username: { isValid: /^[a-zA-Z0-9_][a-zA-Z0-9_-]*$/.test(value), message: 'Invalid username.' },
      password: { isValid: value.length > 0, message: 'Password cannot be empty.' },
      domainName: { isValid: domainRegex.test(value), message: 'Invalid domain name.' },
    };

    if (!value && ['ipAddress', 'port', 'username', 'password', 'domainName'].includes(name)) {
      return { isValid: false, errorMessage: 'This field is required.' };
    }
  
    const { isValid, message } = validations[name];
    return { isValid, errorMessage: isValid ? '' : message };
  };

  const handleInputChangeWrapper = (event) => {
    const { name, value } = event.target;
    const { isValid, errorMessage } = validateInput(name, value);
    setInputErrors({ ...inputErrors, [name]: errorMessage });
    onInputChange(event);
  };

  const allInputsValid = () => {
    // Check if any required field is empty
    const areAllFieldsFilled = formData.ipAddress && formData.port && formData.username && formData.password && formData.domainName;

    // Check if any input has an error message
    const areAllInputsErrorFree = Object.values(inputErrors).every(error => error === '');

    // Check if a template is selected
    const isTemplateSelected = selectedTemplate != null;

    return areAllFieldsFilled && areAllInputsErrorFree && isTemplateSelected;
  };
  // Simulate SSH connection attempt
  const attemptSSHConnection = () => {
    return new Promise(resolve => {
      setTimeout(() => resolve(true), 2000); // Simulate network delay
    });
  };

  const handleNextClick = async () => {
    if (!allInputsValid()) {
      return;
    }
    // Instead of the SSH simulation, call the createServer function
    await createServer();
  };

  return (
    <>
      {/* Loader Overlay */}
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
  
      <div className={styles.serverDetails}>
        <h2>Server Details - Ubuntu 20.04 / 22.04 ONLY</h2>
        <div className={styles.inputRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="ipAddress">IP Address:</label>
            <input
              type="text"
              id="ipAddress"
              name="ipAddress"
              value={formData.ipAddress}
              onChange={handleInputChangeWrapper}
              className={inputErrors.ipAddress ? styles.inputErrorBorder : ''}
            />
            {inputErrors.ipAddress && (
              <small className={styles.inputError}>{inputErrors.ipAddress}</small>
            )}
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="port">Port:</label>
            <input
            type="text"
            id="port"
            name="port"
            value={formData.port}
            onChange={handleInputChangeWrapper}
            className={inputErrors.port ? styles.inputErrorBorder : ''}
            placeholder="22 (default for SSH)" 
          />
          {inputErrors.port && (
            <small className={styles.inputError}>{inputErrors.port}</small>
          )}
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputGroup}>
            <label htmlFor="username">Username (root required):</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleInputChangeWrapper}
              className={inputErrors.username ? styles.inputErrorBorder : ''}
            />
            {inputErrors.username && (
              <small className={styles.inputError}>{inputErrors.username}</small>
            )}
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleInputChangeWrapper}
              className={inputErrors.password ? styles.inputErrorBorder : ''}
            />
            {inputErrors.password && (
              <small className={styles.inputError}>{inputErrors.password}</small>
            )}
          </div>
        </div>
        <div className={styles.optionsContainer}>
          {/* <div className={styles.toggleContainer}>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                className={styles.toggleInput}
                checked={formData.sslCertificate || false}
                onChange={onInputChange}
                id="sslCertificate"
                name="sslCertificate"
              />
              <span className={styles.slider}></span>
            </label>
            <div className={styles.optionDescription}>
              <b>Install SSL Certificate:</b> Allows your site to be displayed with the HTTPS protocol, the secure icon.
            </div>
          </div> */}
  
          {/* CAPTCHA Toggle */}
          <div className={styles.toggleContainer}>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                className={styles.toggleInput}
                checked={formData.captcha || false}
                onChange={onInputChange}
                id="captcha"
                name="captcha"
              />
              <span className={styles.slider}></span>
            </label>
            <div className={styles.optionDescription}>
              <b>CAPTCHA:</b> Helps to prevent bots from submitting forms & lower detections.
            </div>
          </div>
  
          {/* Page Analytics Toggle */}
          <div className={styles.toggleContainer}>
            <label className={styles.toggleLabel}>
              <input
                type="checkbox"
                className={styles.toggleInput}
                checked={formData.analytics || false}
                onChange={onInputChange}
                id="analytics"
                name="analytics"
              />
              <span className={styles.slider}></span>
            </label>
            <div className={styles.optionDescription}>
              <b>Live Rat:</b> Allows you to track page visits and user engagement.
            </div>
          </div>
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="domainName">Domain Name:</label>
          <input
            type="text"
            id="domainName"
            name="domainName"
            value={formData.domainName || ''}
            onChange={handleInputChangeWrapper}
            className={inputErrors.domainName ? styles.inputErrorBorder : ''}
          />
          <small className={styles.smallNote}>The domain name must be pointed to the VPS.</small>
          {inputErrors.domainName && (
            <small className={styles.inputError}>{inputErrors.domainName}</small>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.closeButton} onClick={onClose}>Close</button>
          <button className={styles.nextButton} onClick={handleNextClick} disabled={!allInputsValid()}>Next</button>
        </div>
      </div>
    </>
  );
};

export default StepOneContent;
