import React, {useState} from 'react';
import styles from './StepThreeContent.module.css';
import { toast } from 'react-toastify';

const StepThreeContent = ({ formData, onInputChange, onNext, onBack, isStandaloneMode, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const token = localStorage.getItem('token'); 
    if (!token) {
      toast.error("You are not logged in.");
      setIsLoading(false);
      return;
    }
  
    const payload = {
      captchaEnabled: formData.captcha,
    };

    // Only add siteKey and secretKey to the payload if captcha is enabled
    if (formData.captcha) {
      payload.siteKey = formData.siteKey;
      payload.secretKey = formData.secretKey;
    }

    try {
      const serverIp = formData.ipAddress; 
      const response = await fetch(`/api/servers/${serverIp}/hcaptcha`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      });
  
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.message || `HTTP error! status: ${response.status}`);
      }
  
      toast.success(result.message || "Settings updated successfully.");
      if(!isStandaloneMode){
        onNext(); 
      }
    } catch (error) {
      toast.error(`Failed to update settings: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
};


  const handleCaptchaToggle = (e) => {
    onInputChange({ target: { name: 'captcha', value: e.target.checked } });
  };

  const handleCancel = () => {
    onCancel();
  };
  
  return (
    <>
    {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
    <div className={styles.captchaSettings}>
      <div className={styles.captchaSettingsHeader}>
          <h2>Captcha Page Settings</h2>
      </div>
      <div className={styles.captchaStatusContainer}>
        <div className={styles.statusTitle}>Status</div>
        <div className={styles.captchaStatus}>
          <span className={styles.captchaStatusLabel}>
            {formData.captcha ? 'Captcha is currently enabled.' : 'Captcha is currently disabled.'}
          </span>
          <div className={styles.switchContainer}> {/* New container for the switch */}
            <label className={styles.switch}>
              <input type="checkbox" checked={formData.captcha} onChange={handleCaptchaToggle} />
              <span className={styles.slider}></span>
            </label>
          </div>
        </div>
      </div>
      <div className={styles.formContent}>
        <div className={styles.dropdownGroup}>
          <label htmlFor="captchaSystem">Captcha System:</label>
          <select
            id="captchaSystem"
            name="captchaSystem"
            onChange={onInputChange}
            value={formData.captchaSystem}
          >
            <option value="hcaptcha">Hcaptcha</option>
          </select>
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="siteKey">Site Key:</label>
          <input
            type="text"
            id="siteKey"
            name="siteKey"
            onChange={onInputChange}
            value={formData.siteKey}
            placeholder="Enter your site key"
          />
        </div>
        <div className={styles.inputGroup}>
          <label htmlFor="secretKey">Secret Key:</label>
          <input
            type="text"
            id="secretKey"
            name="secretKey"
            onChange={onInputChange}
            value={formData.secretKey}
            placeholder="Enter your secret key"
          />
        </div>
      </div>
      <div className={styles.modalNavButtonContainer}>
          {isStandaloneMode ? (
            <>
              <button className={styles.backButton} onClick={handleCancel}>Cancel</button>
              <button className={styles.nextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </>
          ) : (
            <>
              <button className={styles.backButton} onClick={onBack}>Back</button>
              <button className={styles.nextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Next'}
              </button>
            </>
          )}
        </div>
    </div>
    </>
  );
};

export default StepThreeContent;
