import React from 'react';
import styles from './TabsStylesheet.module.css';
import canadianBanks from './data/canadianBanksData';

const CanadaContent = ({ onTemplateSelect, selectedTemplate }) => {

  const handleSelectTemplate = (templateName) => {
    onTemplateSelect(templateName); 
    localStorage.setItem('selectedTemplate', templateName); // Save selected template to local storage
    console.log('Selected Template:', templateName);
  };

  const cardClassName = (templateName) => {
    return `${styles.optionCard} ${selectedTemplate === templateName ? styles.activeCard : styles.inactiveCard}`;
  };

  return (
    <div className={styles.BankContent}>
      <div className={styles.optionsGrid}>
        {canadianBanks.map(bank => (
          <div key={bank.name} className={cardClassName(bank.name)} onClick={() => handleSelectTemplate(bank.name)}>
            <img src={bank.logo} alt={bank.name} className={styles.optionIcon} />
            <div className={styles.optionText}>{bank.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CanadaContent;
