import React from 'react';
import styles from './VisitorManager.module.css'; // Assuming the CSS module is named LiveAction.module.css

const StatusIndicator = ({ isActive }) => {
  return (
    <div className={isActive ? styles.statusActive : styles.statusOffline}>
      {isActive ? 'Active' : 'Offline'}
    </div>
  );
};

export default StatusIndicator;
