// src/AuthContext/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        async function checkAuth() {
            const token = localStorage.getItem('token');
            const privateKey = sessionStorage.getItem('privateKey');
            
            if (!token || !privateKey) {
                logoutUser(); // Call logoutUser if either token or privateKey is missing
                return;
            }

            try {
                const response = await axios.get('/api/check_auth', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setIsAuthenticated(response.data.isAuthenticated);
                setLoading(false);
            } catch (error) {
                console.error("Authentication check failed:", error);
                logoutUser(); // Call logoutUser on error
            }
        }

        checkAuth();
    }, []);

    const authenticateUser = (logged) => {
        setIsAuthenticated(logged);
        setLoading(false);
    };

    const logoutUser = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('privateKey');
        setIsAuthenticated(false);
        setLoading(false);
        navigate('/'); // Ensure we navigate to home page
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, loading, authenticateUser, logoutUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
