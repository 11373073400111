import React from 'react';
import styles from './Tab.module.css';
import { FaUniversity, FaCanadianMapleLeaf, FaEnvelope, FaFlagUsa, FaServer, FaRoad, FaConciergeBell, FaShippingFast, FaPhone } from 'react-icons/fa';
import CanadaBanks from '../tabs/CanadaBanks';
import CanadaMulti from '../tabs/CanadaMulti';
import TollRoads from '../tabs/TollRoads';
import Services from '../tabs/Services';
import Shipping from '../tabs/Shipping';
import Telecom from '../tabs/Telecom';

const Tabs = ({ activeTab, setActiveTab, templates, onTemplateSelect, selectedTemplate }) => {
  const tabsData = [
    {
      name: "CA Banks",
      icon: <FaCanadianMapleLeaf />,
      content: <CanadaBanks templates={templates ? templates['Canada'] : []} onTemplateSelect={onTemplateSelect} selectedTemplate={selectedTemplate} />,
    },
    {
      name: "CA Interac",
      icon: <FaCanadianMapleLeaf />,
      content: <CanadaMulti templates={templates ? templates['CanadaMulti'] : []} onTemplateSelect={onTemplateSelect} selectedTemplate={selectedTemplate} />,
    },
    {
      name: "CVV",
      icon: <FaCanadianMapleLeaf />,
      content: <Shipping templates={templates ? templates['CanadaMulti'] : []} onTemplateSelect={onTemplateSelect} selectedTemplate={selectedTemplate} />,
    }, 
    
   
  ];

  return (
    <>
      <div className={styles.tabsContainer}>
        {tabsData.map((tab, index) => (
          <div key={tab.name} className={styles.tabWrapper}>
            <button
              className={activeTab === index ? `${styles.tab} ${styles.activeTab}` : styles.tab}
              onClick={() => setActiveTab(index)}
            >
              <div className={styles.iconWrapper}>{tab.icon}</div>
              <span className={styles.tabName}>{tab.name}</span>
            </button>
          </div>
        ))}
      </div>
      <div className={styles.tabContent}>
        {tabsData[activeTab].content}
      </div>
    </>
  );
};

export default Tabs;
