import React, { useState, useContext, useEffect } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import Sidebar from '../Sidebar/Sidebar';
import ServerCard from './ServerCard/ServerCard';
import CreateServerForm from './CreateServerForm/CreateServerForm';
import Modal from './Modal/Modal'; 
import ModalManager from './ModalManager';
import styles from './Servers.module.css';
import StatisticsCard from '../Dashboard/StatisticCard/StatisticCard';
import { FaServer, FaPlus } from 'react-icons/fa'; 
import { toast } from 'react-toastify';

const Servers = ({ }) => {

  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isSidebarOpen } = useContext(SidebarContext);
  const [isCreatingServer, setIsCreatingServer] = useState(false);
  const [isAntiBotModalOpen, setIsAntiBotModalOpen] = useState(false);
  const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
  const [currentServerSettings, setCurrentServerSettings] = useState({});
  const [isFetchingSettings, setIsFetchingSettings] = useState(false);
  const [isCaptchaModalOpen, setIsCaptchaModalOpen] = useState(false);
  const [currentCustomizationSettings, setCurrentCustomizationSettings] = useState({});




  const transformServerSettings = (response) => {
    const transformedSettings = {
      ipAddress: response.ipAddress || '',
      port: '', 
      username: '', 
      password: '', 
      domain: '', 
      sslCertificate: false, 
      captcha: response.captcha || false, 
      blockCountries: response.blockCountries || [],
      allowCountries: response.allowCountries || [],
      blacklistIPs: response.blacklistIPs || [],
      whitelistIPs: response.whitelistIPs || [],
      blockMobileUsers: response.blockMobileUsers || false,
      blockComputerUsers: response.blockComputerUsers || false,
      blockProxyUsers: response.blockProxyUsers || false,
      blockTORUsers: response.blockTORUsers || false,
      blockBotsByUserAgent: response.blockBotsByUserAgent || false,
      blockBotsByISP: response.blockBotsByISP || false,
      redirectURL: response.redirectURL,
      allowedPages: [],
      disallowedPages: [], 
      siteKey: response.hCaptchaConfig?.siteKey || '',
      secretKey: response.hCaptchaConfig?.secretKey || '',
    };
  
    return transformedSettings;
  };
  
  

  const fetchServerSettings = async (serverId) => {
    try {
        const response = await fetch(`/api/servers/${serverId}/antibot`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching server settings:', error);
        toast.error('Failed to fetch server settings');
        return null;
    }
};

const fetchCustomizationSettings = async (serverId) => {
  try {
    const response = await fetch(`/api/servers/${serverId}/steps-and-page-config`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const settings = await response.json();
    console.log("Customization Settings Fetched:", settings);
    return settings;
  } catch (error) {
    console.error('Error fetching customization settings:', error);
    toast.error('Failed to fetch customization settings');
    return null;
  }
};







const openCreateServerModal = () => {
  console.log("Opening Create Server Modal");
  setIsCreatingServer(true);
  setIsAntiBotModalOpen(false); // Close the other modal if open
};
const onOpenAntiBotSettings = async (serverId) => {
  console.log("Opening Anti-Bot Settings for server", serverId);
  setIsFetchingSettings(true);
  setIsCreatingServer(false); // Close the other modal if open

  try {
    const response = await fetchServerSettings(serverId);
    setIsFetchingSettings(false);

    if (response) {
      const transformedSettings = transformServerSettings(response);
      setCurrentServerSettings(transformedSettings);
      setIsAntiBotModalOpen(true);
    }
  } catch (error) {
    console.error('Error fetching server settings:', error);
    toast.error('Failed to fetch server settings');
  }
};

const onOpenCustomizeSettings = async (serverId) => {
  console.log(`Customizing server with ID: ${serverId}`);
  setIsFetchingSettings(true);

  try {
    const settings = await fetchCustomizationSettings(serverId);
    setIsFetchingSettings(false);
    if (settings) {
      console.log("Customization Settings Fetched:", settings);
      if (settings.globalSettings && settings.globalSettings.interacConfig) {
        settings.templateName = 'Interac';
      } else {
        settings.templateName = 'SingleBank';
      }
      console.log("Customization Settings to be passed:", settings);
      setCurrentCustomizationSettings(settings);
      setIsCustomizeModalOpen(true);
    } else {
      toast.error('Failed to fetch customization settings');
    }
  } catch (error) {
    console.error("Failed to fetch customization settings", error);
    setIsFetchingSettings(false);
    toast.error('Failed to fetch customization settings');
  }
};









const onOpenCaptchaSettings = async (serverId) => {
  console.log("Opening Captcha Settings for server", serverId);
  setIsFetchingSettings(true);
  setIsCreatingServer(false); 
  setIsAntiBotModalOpen(false);

  try {
    const response = await fetchServerSettings(serverId);
    setIsFetchingSettings(false);

    if (response) {
      const transformedSettings = transformServerSettings(response);
      setCurrentServerSettings(transformedSettings);
      setIsCaptchaModalOpen(true);
    }
  } catch (error) {
    console.error('Error fetching server settings:', error);
    toast.error('Failed to fetch server settings');
  }
};




  



 
  const handleCloseModal = () => {
    setIsCreatingServer(false);
    setIsAntiBotModalOpen(false);
    setIsCaptchaModalOpen(false);
    setIsCustomizeModalOpen(false);
  };


  


  useEffect(() => {
      const fetchServers = async () => {
        try {
          setLoading(true);
          const response = await fetch('/api/servers', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
          });
          if (!response.ok) throw new Error('Network response was not ok');
          let data = await response.json();
          data = data.filter(server => server.status !== "deactivated" && server.status !== "archived");
          setServers(data);
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchServers();
  }, []);


  useEffect(() => {
    // Create a new Web Worker instance
    const worker = new Worker('serverFetcherWorker.js');
    const token = localStorage.getItem('token');
  
    // Setup an event listener to receive messages from the worker
    worker.onmessage = (event) => {
      if (event.data.error) {
        console.error('Error from worker:', event.data.error);
        setError(event.data.error);
        return;
      }
      setServers(event.data);
    };
  
    // Post a message to the worker to initiate servers fetching
    worker.postMessage({ action: 'FETCH_SERVERS', token });
  
    // Periodically fetch servers every 10 seconds
    const intervalId = setInterval(() => {
      worker.postMessage({ action: 'FETCH_SERVERS', token });
    }, 10000);
  
    // Cleanup function to terminate the worker and clear the interval
    return () => {
      clearInterval(intervalId);
      worker.terminate();
    };
  }, []);
  

  if (loading) {
  };

  if (error){
  }

  const serversOnline = servers.reduce((acc, server) => {
    return server.status === "running" ? acc + 1 : acc;
  }, 0);

  return (
    <div className={styles.serversPage}>
      <Sidebar />
      <main className={`${styles.mainContent} ${isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <StatisticsCard 
          title="Servers Online" 
          icon={<FaServer/>}
          value={serversOnline}
          showPercentage={false}
        />
        <ModalManager
          isCreatingServer={isCreatingServer}
          isAntiBotModalOpen={isAntiBotModalOpen && !isFetchingSettings}
          isCaptchaModalOpen={isCaptchaModalOpen && !isFetchingSettings}
          isCustomizeModalOpen={isCustomizeModalOpen && !isFetchingSettings} // Make sure this line is correct
          currentCustomizationSettings={currentCustomizationSettings}
          serverSettings={currentServerSettings}
          handleClose={handleCloseModal}
        />
        <div className={styles.serversListContainer}>
          {servers.length > 0 ? (
            <section className={styles.serversList}>
              {/* Inside the Servers component render method */}
            {servers.map(server => (
              <ServerCard
                key={server._id}
                server={server}
                onOpenAntiBotSettings={() => onOpenAntiBotSettings(server._id)}
                onOpenCaptchaSettings={() => onOpenCaptchaSettings(server._id)}
                onOpenCustomizeSettings={() => onOpenCustomizeSettings(server._id)} // Make sure this line exists and is correctly set
                onServerDeleted={deletedServerId => {
                  setServers(prevServers => prevServers.filter(srv => srv._id !== deletedServerId));
                }}
              />
            ))}
            </section>
          ) : (
            <div className={styles.noServers}>
              <p>No servers to display. Create one to get started.</p>
              <button onClick={openCreateServerModal} className={styles.createButton}>
                <FaPlus /> Create Server
              </button>
            </div>
          )}
          <button onClick={openCreateServerModal} className={styles.createButton}>
            <FaPlus /> Create Server
          </button>
        </div>
      </main>
    </div>
  );
};


export default Servers;