import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../AuthContext/AuthContext';

const useAuthRedirect = () => {
    const { isAuthenticated, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (window.location.pathname === '/' && isAuthenticated) {
                navigate('/dashboard');
            } else if (window.location.pathname !== '/' && !isAuthenticated) {
                navigate('/');
            }
        }
    }, [isAuthenticated, loading, navigate]);
};

export default useAuthRedirect;
