import React, { useState } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext';
import styles from './Membership.module.css';
import PaymentModal from './PaymentModal';

const Membership = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const planOptions = {
    monthly: {
      price: 199, 
      savings: null,
      duration: 'Monthly'
    },
    quarterly: {
      price: 537,
      savings: 'Save 10%',
      duration: 'Quarterly'
    },
    annual: {
      price: 1908,
      savings: 'Save 20%',
      duration: 'Annual'
    },
  };
  
  
  

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleModalOpen = () => {
    setShowPaymentModal(true);
  };

  const handleModalClose = () => {
    setShowPaymentModal(false);
  };

  return (
    <div className={styles.membership}>
      <Sidebar />
      <main className={`${styles.mainContent} ${!isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <div className={styles.membershipContainer}>
          <div className={styles.limitedTimeOffer}>
            <p>Limited Time Offer: Get an extra month free when you sign up for the annual plan!</p>
          </div>
          
          <div className={styles.headerContainer}>
            <h1 className={styles.headerTitle}>Choose Your Plan</h1>
            <p className={styles.headerSubtitle}>Get unlimited access to over 40 pages in North America with the best UI and top anti-bot technology.</p>
          </div>
          
          <div className={styles.planSelection}>
            <div className={styles.planOptions}>
              {Object.entries(planOptions).map(([plan, details]) => (
                <div
                  key={plan}
                  className={`${styles.planOption} ${selectedPlan === plan ? styles.planOptionSelected : ''}`}
                  onClick={() => selectPlan(plan)}
                >
                  <h3 className={styles.planDuration}>{details.duration} Plan</h3>
                  <div className={styles.planPricing}>
                    <p className={styles.planPrice}>${details.price}</p>
                    {details.savings && <p className={styles.planSavings}>{details.savings}</p>}
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <button className={styles.joinNowButton} onClick={handleModalOpen}>Join Now</button>
  
          <div className={styles.membershipDetails}>
            <h2 className={styles.membershipType}>Explore Premium Membership</h2>
            <p className={styles.membershipValueProp}>Gain access to exclusive features and elevate your experience.</p>
            <ul className={styles.membershipFeatures}>
              <li><span className={styles.featureIcon}>📱</span> Quality pages (branding, desktop & mobile responsive)</li>
              <li><span className={styles.featureIcon}>🗺️</span> Unlock over 40 pages in CANADA/USA</li>
              <li><span className={styles.featureIcon}>🛡️</span> Anti-Bot System - Undetectable Pages</li>
              <li><span className={styles.featureIcon}>🔥</span> Access to LiveRAT features</li>
              <li><span className={styles.featureIcon}>🔒</span> End-To-End Encryption PGP</li>
              <li><span className={styles.featureIcon}>🚀</span> Unlimited Pages Running</li>
              <li><span className={styles.featureIcon}>⏱️</span> Continuously updating the quality of our platform</li>
              <li><span className={styles.featureIcon}>✏️</span> Be able to request pages / features directly to us</li>
            </ul>
          </div>
        </div>
      </main>
      <PaymentModal 
        isOpen={showPaymentModal} 
        onClose={handleModalClose} 
        selectedPlan={planOptions[selectedPlan]} 
        key={selectedPlan}  // Adding key to force remount when selectedPlan changes
      />
    </div>
  );
};

export default Membership;
