import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MetaMaskIcon from './MetaMaskLogo.svg';
import Header from '../Header/Header';
import Footer from "../Footer/Footer";
import NotificationPopup from '../NotificationPopup/NotificationPopup';
import useMetaMask from '../../../hooks/useMetaMask';
import useMetaMaskAvailability from '../../../hooks/useMetaMaskAvailability';
import useAuthCheck from '../../../hooks/useAuthCheck'; 
import { useAuth } from '../../../AuthContext/AuthContext'; 

const MainContent = () => {
  const navigate = useNavigate();
  const [showNotification, setShowNotification] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorType, setErrorType] = useState('metamask');
  const [isLoading, setIsLoading] = useState(false);

  const { authenticateUser } = useAuth(); 
  useAuthCheck(); 

  const isMetaMaskAvailable = useMetaMaskAvailability();
  const handleMetaMaskLogin = useMetaMask(setIsLoading, setErrorMessage, setShowNotification, authenticateUser);

  return (
    <div className='app-container'>
      {showNotification && (
        <NotificationPopup
          message={errorMessage}
          onClose={() => setShowNotification(false)}
          isLoading={isLoading}
          errorType={errorType}
        />
      )}
      <Header />
      <div className="main-content">
        <h1 className="main-title">SheByte.io</h1>
        <p className="tagline">Security and Privacy in Your Hands</p>
        <div className="cta-buttons">
          <button className='cta-main-btn' onClick={() => handleMetaMaskLogin()}>
            <img src={MetaMaskIcon} alt="MetaMask" className="cta-button-icon" />
            {isMetaMaskAvailable ? 'Sign in with MetaMask' : 'Set up MetaMask'}
          </button>
        </div>
        <div className="features">
          <h3>Why SheByte.io?</h3>
          <ul className='features-list'>
            <li>🔒 Exclusive PHASS toolkit with top-notch security practices.</li>
            <li>🌟 Continuous enhancement driven by your feedback.</li>
            <li>🛡️ Strict no-log policy for uncompromised privacy.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainContent;
