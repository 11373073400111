import React from 'react';
import styles from './Changelog.module.css';

const Changelog = () => {
  // Features list
  const features = [
    "🎮 Live RAT Feature: Grab as many OTP.",
    "🔒 Full Encryption: End-to-end security, no-log policy.",
    "🔑 MetaMask Login: Secure and straightforward.",
    "📄 40+ Pages: High-quality, fully customizable.",
    "🛡 Anti-Bot Detection: Industry-leading protection.",
    "⚙️ Automated Setup: Quick installation across multiple OS.",
    "🌍 Auto-Translate: Instantly switch between English and French.",
  ];

  return (
    <div className={styles.changelog}>
      <h3 className={styles.title}>Dashboard Features</h3>
      <ul className={styles.featureList}>
        {features.map((feature, index) => (
          <li key={index} className={styles.feature}>
            <p className={styles.featureText}>{feature}</p>
          </li>
        ))}
      </ul>
      <div className={styles.footer}>
        <p>Keep up with the latest by joining our <a href="https://t.me/SheBytesios" className={styles.telegramLink}>Telegram channel</a>.</p>
      </div>
    </div>
  );
};

export default Changelog;
