import React, { useState, useEffect } from 'react';
import styles from './SecurityQuestionsConfig.module.css';
import { defaultQuestionsList } from './defaultQuestions';

const initialQuestionConfig = {
  question: '',
  translation: '',
  useTranslation: false,
};

const SecurityQuestionsConfig = ({ formData, setFormData, selectedTemplate }) => {
  const [newQuestionConfig, setNewQuestionConfig] = useState({ ...initialQuestionConfig });
  const [applyToAll, setApplyToAll] = useState(false);

  useEffect(() => {
    if (!formData.templates[selectedTemplate]?.securityQuestions) {
      setFormData((prevFormData) => {
        const updatedTemplates = { ...prevFormData.templates };
        updatedTemplates[selectedTemplate].securityQuestions = defaultQuestionsList[selectedTemplate] || [];
        return { ...prevFormData, templates: updatedTemplates };
      });
    }
  }, [formData.templates, selectedTemplate, setFormData]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setNewQuestionConfig((prevConfig) => ({
      ...prevConfig,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddQuestion = () => {
    if (newQuestionConfig.question) {
      setFormData((prevFormData) => {
        const updatedTemplates = { ...prevFormData.templates };
        if (applyToAll) {
          Object.keys(updatedTemplates).forEach((template) => {
            updatedTemplates[template].securityQuestions = updatedTemplates[template].securityQuestions || [];
            updatedTemplates[template].securityQuestions.push({ ...newQuestionConfig });
          });
        } else {
          updatedTemplates[selectedTemplate].securityQuestions = updatedTemplates[selectedTemplate].securityQuestions || [];
          updatedTemplates[selectedTemplate].securityQuestions.push({ ...newQuestionConfig });
        }
        return { ...prevFormData, templates: updatedTemplates };
      });
      setNewQuestionConfig({ ...initialQuestionConfig });
    }
  };

  const handleRemoveQuestion = (index) => {
    setFormData((prevFormData) => {
      const updatedTemplates = { ...prevFormData.templates };
      if (applyToAll) {
        Object.keys(updatedTemplates).forEach((template) => {
          updatedTemplates[template].securityQuestions = updatedTemplates[template].securityQuestions.filter((_, i) => i !== index);
        });
      } else {
        updatedTemplates[selectedTemplate].securityQuestions = updatedTemplates[selectedTemplate].securityQuestions.filter((_, i) => i !== index);
      }
      return { ...prevFormData, templates: updatedTemplates };
    });
  };

  const handleClearQuestions = () => {
    setFormData((prevFormData) => {
      const updatedTemplates = { ...prevFormData.templates };
      if (applyToAll) {
        Object.keys(updatedTemplates).forEach((template) => {
          updatedTemplates[template].securityQuestions = [];
        });
      } else {
        updatedTemplates[selectedTemplate].securityQuestions = [];
      }
      return { ...prevFormData, templates: updatedTemplates };
    });
  };

  const handleNumberOfQuestionsChange = (event) => {
    const { value } = event.target;
    setFormData((prevFormData) => {
      const updatedTemplates = { ...prevFormData.templates };
      updatedTemplates[selectedTemplate].numberOfSecurityQuestions = parseInt(value, 10);
      return { ...prevFormData, templates: updatedTemplates };
    });
  };

  const securityQuestions = formData.templates[selectedTemplate]?.securityQuestions || [];
  const numberOfQuestions = formData.templates[selectedTemplate]?.numberOfSecurityQuestions || 3;
  const showWarning = securityQuestions.length < numberOfQuestions;

  return (
    <div className={styles.securityQuestionsConfig}>
      <div className={styles.header}>
        <div className={styles.configRow}>
          <label className={styles.fieldLabel}>
            Number of Questions:
            <input
              type="number"
              name="numberOfSecurityQuestions"
              value={numberOfQuestions}
              onChange={handleNumberOfQuestionsChange}
              className={styles.numberInput}
            />
          </label>
          <button onClick={handleClearQuestions} className={styles.clearButton}>Clear All</button>
        </div>
        <div className={styles.newQuestionConfig}>
          <input
            type="text"
            name="question"
            placeholder="Security Question"
            value={newQuestionConfig.question}
            onChange={handleInputChange}
            className={styles.input}
          />
          <input
            type="text"
            name="translation"
            placeholder="Translation (French)"
            value={newQuestionConfig.translation}
            onChange={handleInputChange}
            className={styles.input}
            disabled={!newQuestionConfig.useTranslation}
          />
          <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              name="useTranslation"
              checked={newQuestionConfig.useTranslation}
              onChange={handleInputChange}
            />
            Use Translation
          </label>
          <button onClick={handleAddQuestion} className={styles.addButton}>Add Question</button>
        </div>
      </div>
      <div className={styles.scrollableList}>
        {securityQuestions.map((question, index) => (
          <div key={index} className={styles.questionItem}>
            <span>{question.question}</span>
            {question.useTranslation && <span> - {question.translation}</span>}
            <button onClick={() => handleRemoveQuestion(index)} className={styles.removeButton}>Remove</button>
          </div>
        ))}
      </div>
      {showWarning && (
        <div className={styles.warningMessage}>
          You need to add at least {numberOfQuestions} custom questions.
        </div>
      )}
    </div>
  );
};

export default SecurityQuestionsConfig;
