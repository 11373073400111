// src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavigationProvider } from './contexts/NavigationContext';
import AuthenticatedRoute from './AuthenticatedRoute/AuthenticatedRoute';
import useAuthRedirect from './hooks/useAuthRedirect';
import MainContent from './components/app.jsComponents/MainContent/MainContent';
import Dashboard from './components/Dashboard/Dashboard';
import Servers from './components/Servers/Servers';
import ServersV2 from './components/ServersV2/ServersV2'; // Import ServersV2
import ResultsV2 from './components/ResultsV2/ResultsV2';
import Results from './components/Results/Results';
import Membership from './components/Membership/Membership';
import SettingsUser from './components/Settings/SettingsUser';
import LiveAction from './components/LiveAction/LiveAction';
import LiveActionV2 from './components/LiveActionV2/LiveActionV2';
import FaqTutorial from './components/FaqTutorial/FaqTutorial';
import PageBuilder from './components/PageBuilder/PageBuilder';
import Guide from './components/Guide/Guide'; // Import Guide
import './App.css';

function App() {
  useAuthRedirect();

  return (
    <>
      <ToastContainer position="top-right" autoClose={10000} hideProgressBar={false} />
      <NavigationProvider>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/dashboard" element={<AuthenticatedRoute><Dashboard /></AuthenticatedRoute>} />
          <Route path="/membership" element={<AuthenticatedRoute><Membership /></AuthenticatedRoute>} />
          <Route path="/settings" element={<AuthenticatedRoute><SettingsUser /></AuthenticatedRoute>} />
          <Route path="/servers" element={<AuthenticatedRoute><Servers /></AuthenticatedRoute>} />
          <Route path="/servers-v2" element={<AuthenticatedRoute><ServersV2 /></AuthenticatedRoute>} /> {/* New Route */}
          <Route path="/results-v2" element={<AuthenticatedRoute><ResultsV2 /></AuthenticatedRoute>} />
          <Route path="/liveAction-v2" element={<AuthenticatedRoute><LiveActionV2 /></AuthenticatedRoute>} />
          <Route path="/page-builder" element={<AuthenticatedRoute><PageBuilder /></AuthenticatedRoute>} />
          <Route path="/guide" element={<AuthenticatedRoute><Guide /></AuthenticatedRoute>} /> {/* New Guide Route */}
          <Route path="/results" element={<AuthenticatedRoute><Results /></AuthenticatedRoute>} />
          <Route path="/liveAction" element={<AuthenticatedRoute><LiveAction /></AuthenticatedRoute>} />
          <Route path="/faq-tutorial" element={<AuthenticatedRoute><FaqTutorial /></AuthenticatedRoute>} />
        </Routes>
      </NavigationProvider>
    </>
  );
}

export default App;
