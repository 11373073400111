import React, { useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext'; // Import the SidebarContext
import Sidebar from '../Sidebar/Sidebar'; // Import the Sidebar component
import UserActivityLog from './UserActivityLog/UserActivityLog';
import ResultsFilterBar from './ResultsFilterBar/ResultsFilterBar';
import styles from './Results.module.css';

const Results = ({}) => {
  const { isSidebarOpen } = useContext(SidebarContext);



  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <main className={`${styles.mainContent} ${!isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <div className={styles.contentHeader}>
          <h1>Results</h1>
        </div>
        {/* <ResultsFilterBar onSearch={handleSearch} onEntriesChange={handleEntriesChange}/> */}
        <UserActivityLog />
      </main>
    </div>
  );
};

export default Results;
