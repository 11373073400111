// canadianBanksData.js
const canadianBanks = [
    { name: 'atb', logo: '/logos/atb-min.png' },
    { name: 'bmo', logo: '/logos/bmo-min.png' },
    { name: 'cibc', logo: '/logos/cibc-min.png' },
    { name: 'coastcapital', logo: '/logos/coastcapital-min.png' },
    { name: 'desjardins', logo: '/logos/desjardins-min.png' },
    { name: 'laurentian', logo: '/logos/laurentian-min.png' },
    { name: 'manulife', logo: '/logos/MFC.png' },
    { name: 'meridian', logo: '/logos/meridian-min.png' },
    { name: 'motusbank', logo: '/logos/Motus.jpg' },
    { name: 'bnc', logo: '/logos/national-min.png' },
    { name: 'pc_financial', logo: '/logos/financial.jpg' },
    { name: 'peoplestrust', logo: '/logos/peoplestrust-min.png' },
    { name: 'rbc', logo: '/logos/rbc-min.png' },
    { name: 'scotiabank', logo: '/logos/scotia-min.png' },
    { name: 'simplii', logo: '/logos/simplii.svg' },
    { name: 'tangerine', logo: '/logos/tangerine-min.png' },
    { name: 'td', logo: '/logos/td-min.png' },
  ];
  
  export default canadianBanks;
  