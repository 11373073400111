// src/components/ResultsV2/ResultsV2.js

import React, { useContext } from 'react';
import SidebarContext from '../../contexts/SidebarContext'; // Import the SidebarContext
import Sidebar from '../Sidebar/Sidebar'; // Import the Sidebar component
import UserActivityLogV2 from './UserActivityLogV2/UserActivityLogV2';
import styles from './ResultsV2.module.css';

const ResultsV2 = () => {
  const { isSidebarOpen } = useContext(SidebarContext);

  return (
    <div className={styles.dashboard}>
      <Sidebar />
      <main className={`${styles.mainContent} ${!isSidebarOpen ? styles.mainContentExpanded : ''}`}>
        <div className={styles.contentHeader}>
          <h1>Results V2</h1>
        </div>
        <UserActivityLogV2 />
      </main>
    </div>
  );
};

export default ResultsV2;
