// frontend/src/components/PageBuilder/EditorHeader.js

import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import styles from './EditorHeader.module.css';

const EditorHeader = ({
  goBackToTemplates,
  handleSaveTemplate,
  isSavingTemplate,
  openDeployModal,
  selectedTemplate,
  handleDeleteTemplate,
}) => {
  return (
    <div className={styles.editorHeader}>
      <Button variant="contained" onClick={goBackToTemplates}>
        Back to Templates
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSaveTemplate}
        disabled={isSavingTemplate}
      >
        {isSavingTemplate ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Save Template'
        )}
      </Button>
      <Button variant="contained" color="secondary" onClick={openDeployModal}>
        Deploy
      </Button>
      {selectedTemplate && selectedTemplate.type === 'user' && (
        <Button variant="contained" color="error" onClick={handleDeleteTemplate}>
          Delete Template
        </Button>
      )}
    </div>
  );
};

export default EditorHeader;
