import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Web3 from 'web3';
import axios from 'axios';
import * as openpgp from 'openpgp';
import CryptoJS from 'crypto-js';

const deriveKey = (passphrase) => {
  const derivedKey = CryptoJS.SHA256(passphrase).toString(CryptoJS.enc.Hex);
  return derivedKey;
};

const decryptPrivateKey = async (encryptedPrivateKey, derivedKey) => {
  try {
    const privateKeyObj = await openpgp.readPrivateKey({ armoredKey: encryptedPrivateKey });
    const decryptedPrivateKey = await openpgp.decryptKey({
      privateKey: privateKeyObj,
      passphrase: derivedKey,
    });
    return decryptedPrivateKey;
  } catch (error) {
    console.error('Error during private key decryption:', error);
    throw error;
  }
};

const useMetaMask = (setIsLoading, setErrorMessage, setShowNotification, setIsAuthenticated) => {
  const navigate = useNavigate();

  const getNonce = useCallback(async (account) => {
    try {
      const response = await axios.post('/api/get_nonce', { publicAddress: account });
      return response.data.nonce;
    } catch (error) {
      console.error('Error fetching nonce:', error);
      return null;
    }
  }, []);

  const handleLogin = useCallback(async () => {
    setIsLoading(true);
    try {
      if (!window.ethereum) {
        throw new Error("You must have the MetaMask extension installed");
      }

      const web3 = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];

      const nonce = await getNonce(account);
      const signature = await web3.eth.personal.sign(nonce, account, '');

      const derivedKey = deriveKey(account);

      const response = await axios.post('/api/get_encrypted_private_key', { publicAddress: account });

      const { encryptedPrivateKey, salt, publicKey } = response.data;

      if (!encryptedPrivateKey || !publicKey || !salt) {
        throw new Error('Incomplete keys from backend');
      }

      const privateKey = await decryptPrivateKey(encryptedPrivateKey, derivedKey);
      sessionStorage.setItem('privateKey', privateKey.armor());

      const authResponse = await axios.post('/api/authenticate', {
        publicAddress: account,
        signature,
        publicKey,
        encryptedPrivateKey
      }, { withCredentials: true });

      if (authResponse.data.success) {
        localStorage.setItem('token', authResponse.data.token); // Store the token in localStorage
        setIsAuthenticated(true);
        if (authResponse.data.hasActiveMembership) {
          navigate('/dashboard');
        } else {
          navigate('/membership');
        }
      } else {
        throw new Error("Failed to authenticate user");
      }
    } catch (error) {
      setErrorMessage(error.message);
      setShowNotification(true);
      console.error('Error during login process:', error);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setErrorMessage, setShowNotification, setIsAuthenticated, getNonce]);

  return handleLogin;
};

export default useMetaMask;
