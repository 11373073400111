import React, { useState, createContext } from 'react';

const SidebarContext = createContext({
  isSidebarOpen: true,
  setSidebarOpen: () => {},
});

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, setSidebarOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
